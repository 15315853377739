/**
 * Returns the path to the target item in the form of the index of the children from the root.
 *
 * @param {Array<Object>} items The current code tree.
 * @param {Number} id the id of the target item.
 * @returns {Array<Number>}
 */
export const findPath = (items, id) => {
  var path = [];

  for (let i = 0; i < items.length; i++) {
    if (items[i].id === id) {
      path.push(i);
      return path;
    }
    if (
      items[i].hasOwnProperty('children') &&
      items[i].children !== undefined &&
      items[i].children.length > 0
    ) {
      var result = findPath(items[i].children, id);
      if (result.length > 0) {
        path.push(i);
        path = path.concat(result);
        return path;
      }
    }
  }
  return path;
};
