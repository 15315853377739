export const RENDER_ITEM_STYLE = { display: 'inline-block' };
export const BORDER_ACTIVE_STYLE = {
  borderStyle: 'solid',
  borderRadius: '4px',
  borderWidth: '3px',
};
export const BORDER_INACTIVE_STYLE = {
  padding: '3px',
};
export const INPUT_STYLE = { display: 'inline-block', width: '1vw !important' };

/**
 * Returns the style for the input box based on length.
 * @param {Number} length Length of the input box
 * @returns The style with the width set to the length.
 */
export const INPUT_LENGTH_STYLE_GENERATOR = (length) => {
  return {
    width: length === null ? '' : `${length}ch`,
    fontFamily: 'monospace',
    fontSize: '14px',
  };
};
export const COLLAPSED_ICON_STYLE = { display: 'inline', float: 'right' };

export const BLOCK_STYLE = {
  display: 'inline-block',
  padding: '0.5vh 0.5vw 0.5vh 0vw',
};

export const COMMENT_STYLE = {
  fontFamily: 'monospace',
  fontSize: '14px',
  color: 'rgb(153, 153, 153)',
};
