import BlockAPI from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown, FOUR_SPACES } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const IF_STATEMENT = 27;

// define block regex for parsing
const IF_STATEMENT_REGEX = /if *\((.*)\) *:()()()/;
const IF_STATEMENT_NO_BRACKET_REGEX = /if +(.*) *:()()()/;
const regexArr = [ IF_STATEMENT_REGEX, IF_STATEMENT_NO_BRACKET_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'condition',
}

// define functional component for helpBox
function IfStatementHelpbox() {
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `You use the **if** statement to execute a block of code based on a specified condition.`,
      )}
      {markdown(`The syntax of the **if** statement is as follows:`)}
      {code([`if condition:`, `    if-block`])}
      {markdown(
        `The *if* statement checks the condition first.
      \n If the condition evaluates to *True*, it executes the statements in the *if-block*. Otherwise, it ignores the statements.`,
      )}
    </div>
  );
}

// define selection block
const IF_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code style={{ textAlign: 'left' }}>
      if&nbsp;({THREE_VW_SIZE}):<br></br>
      {FOUR_SPACES + '[Code here]'}
      <br></br>
    </code>
  </Button>
);

// define input block
const ifInputBlock = ({ input1_div }) => {
  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        {getTextInBox('if')}&nbsp;{getString('(')}
        {input1_div}
        {getString(')')}
        {getString(':')}
      </div>
    </div>
  );
};

function parsingFunction(input1) {
  return `if (${input1}):`;
}


export const IfBlock = new BlockAPI(IF_STATEMENT,
  regexArr,
  PLACEHOLDERS,
  <IfStatementHelpbox />,
  IF_SELECTION_BLOCK,
  ifInputBlock,
  parsingFunction);