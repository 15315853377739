import { BLACK } from './ColorPalette';

const targetElem = '[data-tut="code_item_box"] > div > ol';
const targetClass = 'nestable-item';

/**
 * Stores the indent of the current item based on the previous item.
 * @param {Object} item The current item to be processed.
 * @param {Object} prev The previous item.
 */
const storeIndent = (item, prev) => {
  item.indent = (prev?.indent ?? 0) + 1;
  if (
    item.children !== undefined &&
    item.children !== null &&
    item.children.length > 0
  ) {
    [...item.children].forEach((c) => storeIndent(c, item));
  }
};

/**
 * Shows indentation for the code blocks.
 * @param {Boolean} showIndent Whether to show indentation or not.
 * @returns {void}
 */
export const ShowIndentation = (showIndent) => {
  const topLevelList = document.querySelector(targetElem);
  if (topLevelList === null || !showIndent) return;

  const topLevelBlocks = [...topLevelList.children];
  if (topLevelBlocks.length === 0) return;
  topLevelBlocks.forEach(storeIndent);

  const blocks = document.getElementsByClassName(targetClass);
  for (let i = 0; i < blocks.length; ++i) {
    const currBlock = blocks[i];
    if (currBlock.indent === 1) continue;

    currBlock.style.borderLeft = `1px solid ${BLACK}`;
    currBlock.style.marginTop = '0px';
    currBlock.style.marginBottom = '0px';
  }
};
