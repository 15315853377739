import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const RETURN_STATEMENT = 24;

// define block regex for parsing
const RETURN_STATEMENT_REGEX = /return +(.*)()()()/;
const regexArr = [ RETURN_STATEMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'return_value',
}

// define functional component for helpBox
function ReturnStatementHelpbox() {
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The Python **return** statement is a special statement that you can use inside a function or method to send the function’s result back to the caller.`,
      )}
    </div>
  );
}

// define selection block
const RETURN_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      return&nbsp;
      {THREE_VW_SIZE}
    </code>
  </Button>
);

// define input block
const returnInputBlock = ({ input1_div }) => {
  return (
    <div>
      {getTextInBox('return')}&nbsp;
      {input1_div}
    </div>
  );
};

function parsingFunction(input1) {
  return `return ${input1}`;
}

export const ReturnBlock = new BlockAPI(RETURN_STATEMENT,
  regexArr,
  PLACEHOLDERS,
  <ReturnStatementHelpbox />,
  RETURN_SELECTION_BLOCK,
  returnInputBlock,
  parsingFunction
);