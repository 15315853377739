// Backend
export const BACKEND_URL = 'https://visualpython.comp.nus.edu.sg/api';
export const ANALYTICS_URL = `/analytics/`;
export const FEEDBACK_URL = `/feedback/`;
export const TUTORIAL_RATING_URL = `/tutorial_ratings/`;
export const CODE_EXECUTION_URL = `/code_execution/run_code/`;
const AUTH_URL = `/auth/`;
export const LOGIN_URL = `${AUTH_URL}login/`;
export const REGISTER_URL = `${AUTH_URL}register/`;
export const LOGOUT_URL = `${AUTH_URL}logout/`;
export const GET_USER_URL = `${AUTH_URL}whoami/`;
export const EDIT_PROFILE_URL = `${AUTH_URL}edit_profile/`;
export const BATCH_SIZE = 10;

// Keys
export const MESSAGE_KEY = 'message';
export const ERROR_KEY = 'error'
