import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { onClearThunk } from '../../redux/thunks/onClear';
import { IfBlock } from '../../blocks/if_statements/If';
import { CommentBlock } from '../../blocks/others/Comment';
import { createTestCase } from '../utils/HandleCodeExecution';
import TutorialRating from '../components/TutorialRating';
import { blockModeThunk } from '../../redux/thunks/BlockMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 2: Python Syntax</h1>
      <h2>At the end of this chapter, you will learn more about:</h2>
      <ol>
        <li>Indentation in Python</li>
        <li>Comments in Python</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const IntroToIndentations = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Indentation Guide</h2>
      <p>Indentation refers to the spaces at the beginning of a code line.</p>
      <p>
        Where in other programming languages the indentation in code is for
        readability only, the indentation in Python is very important.
      </p>
      <p>
        Python uses indentation to indicate a block of code.
        <br />
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const AddingConditionalStatement = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Let's start writing a simple conditional statement</h1>
      {code(['if (1 < 2):'])}
      <p>Steps:</p>
      <ol>
        <li>Hover over the conditionals selection menu</li>
        <li>
          Click on the <code>if</code> statement
        </li>
        <li>It should appear in the code box</li>
        <li>
          Type in <i>1 &lt; 2</i> in the input field
        </li>
      </ol>
    </div>
  ),
  condition: ({ items }) =>
    items.some((item) => {
      const inputField = item.input1 ?? '';
      return (
        item.type === IfBlock.getType() &&
        inputField.replace(/ /g, '') === '1<2'
      );
    }),
  onStep: blockModeThunk,
  resetCode: '',
});

const AddingPrintStatement = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Now let's add a print statement below</h1>
      {code(['if (1 < 2):\n    print("One is smaller than two!")'])}
      <p>
        The <code>if</code> statement is a conditional statement and it will
        execute a block of code if the condition returns true. To indicate this
        block of code, we need to use indentation.
      </p>
      <p>
        The number of indentation is up to the programmer, however the most
        common use is four.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const AddingPrintStatementCode = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Now let's add a print statement below</h1>
      <p>Steps:</p>
      <ol>
        <li>Hover over the IO menu</li>
        <li>
          Drag the <code>print</code> statement under the <code>if</code> block
        </li>
        <li>It should appear in the code box</li>
        <li>
          Type in <i>"One is smaller than two!"</i> in the input field
        </li>
      </ol>
      <b>Note:</b> You can indent the code by dragging, right clicking on the
      block to use the indent function, or using the Tab shortcut.
    </div>
  ),
  onStep: blockModeThunk,
  testCases: [createTestCase('', 'One is smaller than two!\n')],
  resetCode: 'if (1 < 2):',
});

const AddComment = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Now let's add a comment block!</h1>
      <ol>
        <li>
          Python has commenting capability for the purpose of in-code
          documentation.
        </li>
        <li>
          Comments start with a <code>#</code>, and Python will render the rest
          of the line as a comment.
        </li>
        <li>
          Comments are not considered as code and will not affect execution
          output. Try running the code with a comment and it will give you the
          same output!
        </li>
      </ol>
      <p>Steps:</p>
      <ol>
        <li>Hover over the Others selection menu</li>
        <li>
          Click on the <code># comment</code> statement
        </li>
        <li>It should appear in the code box</li>
      </ol>
    </div>
  ),
  condition: ({ items }) => {
    const hasComment = items.map((item) => {
      if (item.children && item.children.length > 0) {
        return item.children.some(
          (item) => item.type === CommentBlock.getType(),
        );
      }
      return item.type === CommentBlock.getType();
    });
    return hasComment.includes(true);
  },
  onStep: blockModeThunk,
  testCases: [createTestCase('', 'One is smaller than two!\n')],
  resetCode: 'if (1 < 2):\n    print("One is smaller than two!")',
});
const CongratulationsMessage = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Congratulations!</h1>
      <p>Congratulations! You have completed this tutorial!</p>
      <p>
        Join us in the next tutorial to learn how to do more cool things in
        python!
      </p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  IntroToIndentations,
  AddingConditionalStatement,
  AddingPrintStatement,
  AddingPrintStatementCode,
  AddComment,
  CongratulationsMessage,
];

export const Chapter2Syntax = new Tutorial({
  name: 'Chapter 2: Syntax',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter2Syntax.addSteps(steps);
