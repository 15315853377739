import { sendAnalyticsToServer } from './ServerInterface';
import { BATCH_SIZE } from './constants/constants';

export class DataCollector {
  constructor() {
    this.data = [];
  }

  add(data) {
    this.data.push(data);
    if (this.data.length < BATCH_SIZE) return;

    this.sendAll();
    this.clear();
  }

  sendAll() {
    const data = this.pop();
    sendAnalyticsToServer(JSON.stringify(data));
    this.clear();
  }

  get() {
    return this.data;
  }

  clear() {
    this.data = [];
  }

  pop() {
    const data = this.data;
    this.clear();
    return data;
  }
}
