import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
export const FUNCTION_DECLARATION = 23; // ["def f(x):"]

export const FUNCTION_DECLARATION_REGEX = /def +(.*)\((.*)\) *:()()/;
const regexArr = [ FUNCTION_DECLARATION_REGEX ];


export const PLACEHOLDERS = {
    input1: 'function_name',
    input2: 'param1, param2',
};

// from helpBox, makes use of helper functions from BlockAPI
function FunctionDeclaration() {
    const lines = [
        `# Defines a function with the name example_function`,
        `def example_function():`,
        `    print("Hello from a function")`,
        ``,
        `# To call a function, use the function name followed by parenthesis`,
        `example_function()`,
        ``,
        `# Defines a function that takes in two arguments and returns its sum`,
        `def adds_two_numbers(arg1, arg2):`,
        `    return arg1 + arg2`,
        ``,
        `adds_two_numbers(1, 2) # returns the value 3`,
    ];
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(
                `A function is a block of code which only runs when it is called and is defined using the **def** keyword. You can pass data, known as parameters/arguments, into a function. A function can return data as a result.`,
            )}
            {code(lines)}
        </div>
    );
}

const FUNCTION_DECLARATION_SELECTION_BLOCK = (
    <div>
        <Button
            style={{ textTransform: 'lowercase' }}
            variant="contained"
            size="small"
        >
            <code>
                def&nbsp;
                {THREE_VW_SIZE}({THREE_VW_SIZE}
                ):
            </code>
        </Button>
    </div>
);

//from inputs.js, makes use of functions from elements.js
const getFunctionDeclaration = ({ input1_div, input2_div }) => {
    return (
        <div>
            {getTextInBox('def')}&nbsp;
            {input1_div}
            {getString('(')}
            {input2_div}
            {getString(')')}
            {getString(':')}
        </div>
    );
};

function parsingFunction(input1, input2) {
    return `def ${input1}(${input2}):`;
}

export const FunctionDeclarationBlock = new BlockAPI(
    FUNCTION_DECLARATION,
    regexArr, PLACEHOLDERS,
    <FunctionDeclaration />,
    FUNCTION_DECLARATION_SELECTION_BLOCK,
    getFunctionDeclaration,
    parsingFunction
);
