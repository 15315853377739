import ItemBlock from '../components/ItemBlock';
import { BLOCK_TYPE_REGEX } from '../constants/block-types';
import { MapBlock } from '../blocks/map_and_filter_operations/Map';
import { FilterBlock } from '../blocks/map_and_filter_operations/Filter';
import { DictPopBlock } from '../blocks/dictionary_operations/DictPop';
import {
  whitespaceCount,
  checkMatchingRegex,
  isLineComment,
  isNotEmpty,
} from './AstParserUtil';

/**
 * Processes the indentation for the first line.
 * @param {String} line The current line to be processed.
 * @returns {[Boolean, String]} [isError, error message]
 */
const processFirstLineIndentation = (line) => {
  if (isLineComment(line)) return [true, ''];

  //check that the first line is at indentation zero
  if (whitespaceCount(line) == 0) return [true, ''];

  return [false, 'The first line of code must not have any indentation!'];
};

/**
 * Processes the indentation for an array of lines.
 * @param {Array<String>} nonEmptyLines An array of non-empty lines.
 * @returns {[Boolean, Array<Number>, String]} [isError, indentations, error message]
 */
export const processIndentations = (nonEmptyLines) => {
  let indentations = [];
  let isPreviousLineComment = false;
  for (let i = 0; i < nonEmptyLines.length; i++) {
    const line = nonEmptyLines[i].replaceAll('\t', '    ');
    const isComment = isLineComment(line);

    //check that the first line is at indentation zero
    if (i == 0) {
      const [status, error] = processFirstLineIndentation(line);
      if (!status) return [status, null, error];
      indentations.push(0);
      isPreviousLineComment = isComment;
      continue;
    }

    let whitespaceAtStart = whitespaceCount(line);
    if (isComment) {
      whitespaceAtStart -= whitespaceAtStart % 4; // round down to nearest level for comments
      let indentation = whitespaceAtStart / 4;
      const previousIndentation = indentations[i - 1];
      indentation = Math.min(indentation, previousIndentation + 1); //if indentation too far off, make it child of previous node
      indentations.push(indentation);
      isPreviousLineComment = isComment;
      continue;
    }

    // check if indentations are of multiples of 4
    if (whitespaceAtStart % 4 != 0) {
      return [
        false,
        null,
        "Indentation error at line: '" +
          nonEmptyLines[i] +
          "'\nEnsure that your line indentations are at multiples of 4 spaces.",
      ];
    }
    indentations.push(whitespaceAtStart / 4);

    if (
      i > 1 &&
      isPreviousLineComment &&
      indentations[i] - indentations[i - 1] > 1
    ) {
      indentations[i - 1] = indentations[i - 2];
    }

    // check that each following line is only at most 1 indentation level deeper
    if (indentations[i] - indentations[i - 1] > 1) {
      return [
        false,
        null,
        "Indentation error at line: '" +
          nonEmptyLines[i] +
          "'\nEnsure that each line can only be at 0 or 1 indentation levels from its previous line.",
      ];
    }

    isPreviousLineComment = isComment;
  }
  return [true, indentations, null];
};

/**
 * Processes the current line and returns the corresponding ItemBlock.
 * @param {String} line The line that is currently being processed.
 * @param {Number} id The id of the line.
 * @returns {[Boolean, ItemBlock, String]} [successStatus, itemBlock, errorMsg]
 */
export const processLine = (line, id) => {
  let item;
  for (const x of BLOCK_TYPE_REGEX.entries()) {
    const found = checkMatchingRegex(line, x[0]);
    if (found == null) continue;
    found.replace(x[0], function (match, input1, input2, input3, input4) {
      input1 = input1.trim();
      input2 = input2.trim();
      input3 = input3.trim();
      input4 = input4.trim();

      switch (x[1]) {
        case MapBlock.getType():
        case FilterBlock.getType():
          item = new ItemBlock(id, x[1], input2, input3, input4, input1);
          break;
        case DictPopBlock.getType():
          item = new ItemBlock(id, x[1], input2, input3, input1, input4);
          break;
        default:
          item = new ItemBlock(id, x[1], input1, input2, input3, input4);
      }
    });
    return [true, item, ''];
  }
  return [false, null, "Line: '" + line + "'\ncannot be parsed!"];
};

/**
 * Processes the array of lines and returns an array of ItemBlocks.
 * @param {Array<String>} nonEmptyLines an array of non-empty lines
 * @param {Array<Number>} indentations an array of indentations based on lines
 * @param {Array<Number>} linesId the line id of each line
 * @returns {[Boolean, Array<ItemBlock>, String]} [isSuccess, items, errorMsg]
 */
export const processItems = (nonEmptyLines, indentations, linesId) => {
  let newItems = [];

  let startNode = 0;
  let isSuccess, newNode, childNodes, errorMsg;
  isSuccess = true;
  errorMsg = '';
  for (let j = 1; j < indentations.length; j++) {
    if (indentations[j] != 0) continue;

    //process the startnode
    [isSuccess, newNode, errorMsg] = processLine(
      nonEmptyLines[startNode],
      linesId[startNode],
    );
    if (!isSuccess) return [isSuccess, null, errorMsg];
    if (startNode + 1 == j) {
      //push startnode to newItems
      newItems.push(newNode);
      //update startnode
      startNode = j;
      continue;
    }

    //remove 1 indentation from children lines
    let childLines = nonEmptyLines.slice(startNode + 1, j);
    let childIndentations = indentations.slice(startNode + 1, j);
    childIndentations = childIndentations.map((x) => {
      return x - 1;
    });
    const childIds = linesId.slice(startNode + 1, j);

    // process its children using processItems
    [isSuccess, childNodes, errorMsg] = processItems(
      childLines,
      childIndentations,
      childIds,
    );
    if (!isSuccess) return [isSuccess, null, errorMsg];

    //add children to startnode
    newNode.setChildren(childNodes);

    //push startnode to newItems
    newItems.push(newNode);

    //update startnode
    startNode = j;
  }

  // last round process remaining node:
  // process startNode
  [isSuccess, newNode, errorMsg] = processLine(
    nonEmptyLines[startNode],
    linesId[startNode],
  );
  if (!isSuccess) return [isSuccess, null, errorMsg];

  //remove 1 indentation from children lines
  let childLines = nonEmptyLines.slice(startNode + 1);
  if (childLines.length == 0) {
    //push startnode to newItems
    newItems.push(newNode);
    newItems = newItems.map((x) => x.convertToPOJO());
    return [isSuccess, newItems, errorMsg];
  }
  let childIndentations = indentations.slice(startNode + 1);
  childIndentations = childIndentations.map((x) => {
    return x - 1;
  });

  const childIds = linesId.slice(startNode + 1);

  //process its children using processItems
  [isSuccess, childNodes, errorMsg] = processItems(
    childLines,
    childIndentations,
    childIds,
  );
  if (!isSuccess) return [isSuccess, null, errorMsg];

  //add children to startnode
  newNode.setChildren(childNodes);

  //push startnode to newItems
  newItems.push(newNode);

  newItems = newItems.map((x) => x.convertToPOJO());
  return [isSuccess, newItems, errorMsg];
};

/** Parses the input text into VisualPy blocks
 * @param {String} text - The input text to be parsed
 * @returns {[bool, Array, String]} [successStatus, itemBlocks, errorMsg] - The Error message is only non-empty when successStatus is false
 */
export const astParser = (text) => {
  const lines = text.split('\n');

  const nonEmptyLines = lines.filter(isNotEmpty); // remove empty lines
  const linesId = [...nonEmptyLines.keys()];
  const [status, indentations, error] = processIndentations(nonEmptyLines);
  if (!status) return [status, null, error];

  if (nonEmptyLines.length == 0) return [true, [], null];

  // returns true with a list of itemBlocks if parsed successfully
  // returns false with an error message string if parsed unsuccessfully
  return processItems(nonEmptyLines, indentations, linesId);
};

export default astParser;
