import { EDIT_PROFILE } from '../constants/modal-type';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../redux/modalState';
import {
  Box,
  Modal,
  Typography,
  IconButton,
  Button,
  FormGroup,
  TextField,
} from '@material-ui/core';
import { useState } from 'react';
import { showSnackBarMessage } from '../redux/messageState';
import { editProfile } from '../api/send';
import { ERROR_KEY } from '../api/constants/constants';

const style = {
  position: 'absolute',
  overflow: 'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

/**
 * The Edit Profile modal.
 * Currently it only supports changing passwords.
 * @returns {JSX.Element} The edit profile modal.
 */
export const EditModal = () => {
  const dispatch = useDispatch();

  const currDisplayName = useSelector((state) => state.authState.displayName);
  const openedModal = useSelector((state) => state.openedModal.openedModal);

  const [currPw, setCurrPw] = useState('');
  const [newPw, setNewPw] = useState('');
  const [cfmPw, setCfmPw] = useState('');

  const submitHandler = async () => {
    if (currPw === '' || newPw === '')
      return dispatch(
        showSnackBarMessage('Please fill in all required fields'),
      );
    if (newPw !== cfmPw)
      return dispatch(showSnackBarMessage('Passwords do not match'));

    // Change password to new password.
    try {
      const resp = await editProfile(currPw, newPw);
      if (resp.status !== 200) throw resp;

      dispatch(showSnackBarMessage('Password changed successfully'));
      return dispatch(closeModal());
    } catch (error) {
      try {
        const result = error.response.data[ERROR_KEY];
        setNewPw('');
        setCfmPw('');
        setCurrPw('');
        return dispatch(showSnackBarMessage(`${result}`));
      } catch (error) {
        return dispatch(
          showSnackBarMessage('Edit profile failed, please try again later'),
        );
      }
    }
  };
  return (
    <Modal
      open={openedModal === EDIT_PROFILE}
      onClose={() => dispatch(closeModal())}
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Profile
          </Typography>
          <IconButton
            size="small"
            onClick={() => dispatch(closeModal())}
            data-testid="close-btn"
          >
            <i className="fa fa-close" />
          </IconButton>
        </div>
        <br />
        <Typography
          component={'span'}
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
          <FormGroup className="mb-3">
            <TextField
              id="outlined-required-username"
              label="Username"
              variant="outlined"
              value={currDisplayName}
              disabled={true}
              data-testid="username"
            />
          </FormGroup>
          <FormGroup className="mb-3">
            <br />
            <TextField
              id="outlined-required-current-password"
              label="Current Password"
              variant="outlined"
              type="password"
              value={currPw}
              onChange={(event) => setCurrPw(event.target.value)}
              data-testid="current-password"
            />
            <br />
            <TextField
              id="outlined-required-new-password"
              label="New Password"
              type="password"
              variant="outlined"
              value={newPw}
              onChange={(event) => setNewPw(event.target.value)}
              data-testid="new-password"
            />
            <br />
            <TextField
              id="outlined-required-confirm-password"
              label="Confirm Password"
              type="password"
              variant="outlined"
              value={cfmPw}
              onChange={(event) => setCfmPw(event.target.value)}
              data-testid="confirm-password"
            />
            <br />
            <Button
              variant="contained"
              onClick={submitHandler}
              data-testid="update-btn"
            >
              Update
            </Button>
          </FormGroup>
        </Typography>
      </Box>
    </Modal>
  );
};
