import { EMPTY_TUTORIAL } from './Tutorial';
import { Tutorial } from './Tutorial';

export class TutorialManager {
  /**
   * Creates a new tutorial manager.
   */
  constructor() {
    this.tutorialArr = [];
  }

  /**
   * Registers a tutorial with the tutorial manager.
   *
   * @param {Tutorial} tutorialObj - The tutorial to register.
   * @returns {Number} The id of the tutorial.
   */
  registerTutorial(tutorialObj) {
    const size = this.tutorialArr.push(tutorialObj);
    return size - 1;
  }

  /**
   * Resets the tutorial manager.
   *
   * @returns {void}
   */
  reset() {
    this.tutorialArr = [];
  }

  /**
   * Gets all tutorials registered with the tutorial manager.
   *
   * @returns {Array<Tutorial>} The array of tutorials.
   */
  getAllTutorials() {
    return this.tutorialArr;
  }

  /**
   * Gets the number of tutorials registered with the tutorial manager.
   * @returns {Number} The number of tutorials registered with the tutorial manager.
   */
  getNoTutorials() {
    return this.tutorialArr.length;
  }

  /**
   * Gets the tutorial based on number
   * @param {Number} tutorialNo - The tutorial number to get.
   * @returns {Tutorial} The tutorial.
   */
  getTutorialByNo(tutorialNo) {
    const result = this.tutorialArr[tutorialNo];
    return result === undefined ? EMPTY_TUTORIAL : result;
  }
}

export const tutorialManager = new TutorialManager();
