import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const SET_ADD = 43;

// define block regex for parsing
const SET_ADD_REGEX = /(.*)\. *add *\((.*)\)()()/;;
const regexArr = [ SET_ADD_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'set_name',
  input2: 'item',
}

// define functional component for helpBox
function SetAddHelpbox() {
  const lines = [
    `fruits = {"apple", "banana", "cherry"} # create a new set`,
    ``,
    `fruits.add("orange") # add a specified element`,
    ``,
    `print(fruits) # {'apple', 'banana', 'cherry', 'orange'}`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`The **add()** method adds an element to the set. If the element already exists, the **add()** method does not add the element.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const SET_ADD_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE}.add({TWO_VW_SIZE})
    </code>
  </Button>
);

// define input block
const SetAddInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div}.add{getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1}.add(${input2})`;
}


export const SetAddBlock = new BlockAPI(SET_ADD,
  regexArr,
  PLACEHOLDERS,
  <SetAddHelpbox />,
  SET_ADD_SELECTION_BLOCK,
  SetAddInputBlock,
  parsingFunction);