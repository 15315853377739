import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const LIST_COPY = 29;

// define block regex for parsing
const LIST_COPY_REGEX = /(.*) *= *(.*) *\. *copy *\( *\)()()/;
const regexArr = [ LIST_COPY_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'copy_of_lst',
  input2: 'original_lst',
}

// define functional component for helpBox
function ListCopyHelpbox() {
  const lines = [
    `lst = [2, 4, 9]`,
    `clone = lst.copy() # deep copy`,
    `bad_list = lst # shallow copy`,
    ``,
    `# What is deep copy and shallow copy?`,
    `lst[1] = 0`,
    `print(lst) # [2, 0, 9]`,
    `print(clone) # [2, 4, 9] - clone is not affected`,
    `print(bad_list) # [2, 0, 9] - bad_list is affected`,
    `# always use deep copy to make a copy of a list!`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`This method returns a **deep** **copy** of the list.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const LIST_COPY_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE} = {TWO_VW_SIZE}.copy()
    </code>
  </Button>
);

// define input block
const listCopyInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {input2_div}
      {getString('.')}copy{getString('(')}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1} = ${input2}.copy()`;
}

export const ListCopyBlock = new BlockAPI(LIST_COPY,
  regexArr,
  PLACEHOLDERS,
  <ListCopyHelpbox />,
  LIST_COPY_SELECTION_BLOCK,
  listCopyInputBlock,
  parsingFunction);