import BlockAPI from "../BlockAPI";
import { THREE_VW_SIZE, code, getAssignmentStatements, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const BITWISE_OR = 3;

// define block regex for parsing
const BITWISE_OR_REGEX = /(.*)\|=(.*)()()/;
const regexArr = [ BITWISE_OR_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'i',
  input2: '1',
  operator: '|=',
}

// define functional component for helpBox
function BitwiseOrHelpbox() {
  const lines = [
    `a = 1 # initializes variable a to the value 1`,
    `a != 0 # bitwise OR 1 and 0 results in 1`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Performs Bitwise OR on operands and assign value to left operand`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const BITWISE_OR_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {THREE_VW_SIZE} |= {THREE_VW_SIZE}
    </code>
  </Button>
);

function parsingFunction(input1, input2) {
  return `${input1} |= ${input2}`;
}

export const BitwiseOrBlock = new BlockAPI(BITWISE_OR, regexArr, PLACEHOLDERS, <BitwiseOrHelpbox />, BITWISE_OR_SELECTION_BLOCK, getAssignmentStatements('|='), parsingFunction);