import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { onClearThunk } from '../../redux/thunks/onClear';
import { IfBlock } from '../../blocks/if_statements/If';
import { createTestCase } from '../utils/HandleCodeExecution';
import TutorialRating from '../components/TutorialRating';
import { blockModeThunk } from '../../redux/thunks/BlockMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 3: Python Keywords</h1>
      <h2>At the end of this chapter, you will learn more about:</h2>
      <ol>
        <li>What are Python keywords</li>
        <li>Syntax of Python keywords</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const IntroToKeywords = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Keywords</h2>
      <p>
        Keywords are predefined, reserved words used in Python programming that
        have special meanings to the compiler.
      </p>
      <p>
        We cannot use a keyword as a variable name, function name, or any other
        identifier. They are used to define the syntax and structure of the
        Python language.
      </p>
      <p>
        <b>
          All the keywords except <code>True</code>, <code>False</code> and{' '}
          <code>None</code> are in lowercase and they must be written as they
          are. If you want to have an overview, here is the complete{' '}
          <a
            href="https://www.programiz.com/python-programming/keyword-list"
            target="_blank"
            rel="noopener noreferrer"
          >
            list
          </a>{' '}
          of all the keywords with examples.
          <br />
        </b>
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const AddingConditionalStatement = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>
        Let's add an <code>if</code> conditional statement
      </h1>
      {code(['if (true):'])}
      <p>Steps:</p>
      <ol>
        <li>Hover over the conditionals selection menu</li>
        <li>
          Click on the <code>if</code> statement
        </li>
        <li>It should appear in the code box</li>
        <li>
          Type in <i>true</i> in the input field
        </li>
      </ol>
    </div>
  ),
  condition: ({ items }) =>
    items.some((item) => {
      const inputField = item.input1 ?? '';
      return item.type === IfBlock.getType() && inputField.trim() === 'true';
    }),
  onStep: blockModeThunk,
  resetCode: '',
});

const KeywordTest = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Now let's add a print statement and submit our code!</h1>
      {code(['if (true):\n    print("Success!")'])}
      <p>
        Notice that <code>true</code> is not a keyword and an error message will
        be thrown.
      </p>
      <h2>
        Now let us change <code>true</code> to <code>True</code>
      </h2>
      <p>
        <code>True</code> is a Python keyword and the code will run as expected.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
  testCases: [createTestCase('', 'Success!\n')],
  resetCode: 'if (true):',
});

const CongratulationsMessage = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Congratulations!</h1>
      <p>Congratulations! You have completed this tutorial!</p>
      <p>
        Join us in the next tutorial to learn how to do more cool things in
        python!
      </p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  IntroToKeywords,
  AddingConditionalStatement,
  KeywordTest,
  CongratulationsMessage,
];

export const Chapter3Keywords = new Tutorial({
  name: 'Chapter 3: Keywords',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter3Keywords.addSteps(steps);
