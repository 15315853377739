import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const LIST_LENGTH = 31;

// define block regex for parsing
const LIST_LENGTH_REGEX = /(.*) *= *len *\((.*)\) *()()/;
const regexArr = [LIST_LENGTH_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'length',
  input2: 'lst',
}

// define functional component for helpBox
function ListLengthHelpbox() {
  const lines = [
    `lst = [1, 7, 9, 3]`,
    `length = len(lst)`,
    `print(length) # output: 4`,
    ``,
    `# compute the sum of all elements in the list`,
    `sum = 0`,
    `for i in range(len(lst)):`,
    `    sum += lst[i]`,
    `print(sum) # output: 20`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `This method returns the **length** (total number of elements) in the list.`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const LIST_LENGTH_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE} = len({TWO_VW_SIZE})
    </code>
  </Button>
);

// define input block
const listLengthInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {getString('len')}
      {getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1} = len(${input2})`;
}

export const ListLengthBlock = new BlockAPI(LIST_LENGTH,
  regexArr,
  PLACEHOLDERS,
  <ListLengthHelpbox />,
  LIST_LENGTH_SELECTION_BLOCK,
  listLengthInputBlock,
  parsingFunction);