import { NO_TYPE } from "../../constants/block-types";
/**
 * Returns the block type of the current block.
 * @param {JSX.Element} block The block to be processed
 * @returns {Number}
 */
export const getStmtType = (block) => {
  if (block === null) return NO_TYPE;
  try {
    return parseInt(
      block.children[0].children[0].children[0].classList[1].split('-')[1],
    );
  } catch (err) {
    return NO_TYPE;
  }
};

/**
 * Returns the previous block of the current block.
 * @param {JSX.element} currentBlock The current block.
 * @returns {JSX.Element} The previous block.
 */
export const getPrevConsecutive = (currentBlock) => {
  const parent = currentBlock?.parentElement;
  if (null in [currentBlock, parent]) return null;
  const childBlock = parent?.children;
  if (childBlock == null || childBlock.length <= 1) return null;

  const p_arr = [...parent.children];
  const idx = p_arr.indexOf(currentBlock);
  if (idx === 0) return null;
  return p_arr[idx - 1];
};

/**
 * Updates the border style of a generic block.
 * @param {JSX.Element} currentBlock The currently processed block
 * @param {JSX.Element} prevBlock The previously processed block
 * @param {Object} borderStyle The style of the current block
 * @param {Object} prevBorderStyle The style of the previous block
 */
export const updateGenericBlock = (
  currentBlock,
  prevBlock,
  borderStyle,
  prevBorderStyle,
) => {
  if (prevBlock != null) {
    prevBlock.style.borderBottom = prevBorderStyle;
  }
  currentBlock.style.borderTop = borderStyle;
  currentBlock.style.borderBottom = borderStyle;
  currentBlock.style.borderLeft = borderStyle;
  currentBlock.style.borderRight = borderStyle;
};