/**
 * Creates a block based on id, type and children. Exported for testing
 * @param {int} id The id of the new item
 * @param {BlockType} type The type of the new item
 * @param {Array} children The children of the new item, default is empty array
 * @returns {object} The new block.
 */
export const createBlock = (id, type, children = []) => {
  return {
    id: id,
    type: type,
    children: children,
  };
};

/**
 * Creates an item based on type
 * @param {int} itemId The id of the new item
 * @param {BlockType} blockType The type of the new item
 * @returns {object} The new item.
 */
export const createOnDropItem = (itemId, blockType) => {
  return {
    itemId: itemId,
    blockType: blockType,
  };
};
