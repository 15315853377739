import { ON_UNLOAD_MESSAGE } from './constants/constants';
import Home_functional from './Home_functional';

const App = () => {
  window.onbeforeunload = () => ON_UNLOAD_MESSAGE;
  return (
    <div style={{ fontFamily: 'monospace', input: 'monospace' }}>
      <Home_functional />
    </div>
  );
};
export default App;
