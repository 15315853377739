import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Modal from '@material-ui/core/Modal';
import { APP_NAME } from '../constants/constants';
import { DISCLAIMER } from '../constants/disclaimer';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../redux/modalState';
import { ABOUT } from '../constants/modal-type';
import { IconButton } from '@material-ui/core';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '60vw',
  maxHeight: '90vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

/**
 * An About modal for the application.
 * @returns {JSX.Element} - The About modal.
 */
const AboutModal = () => {
  const openedModal = useSelector((state) => state.openedModal.openedModal);
  const dispatch = useDispatch();

  return (
    <Modal
      open={openedModal == ABOUT}
      onClose={() => dispatch(closeModal())}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            display="inline"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            About {APP_NAME}
          </Typography>
          <IconButton size="small" data-testid='close-btn' onClick={() => dispatch(closeModal())}>
            <i className="fa fa-close" />
          </IconButton>
        </div>
        <Typography
          component={'span'}
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
          <p>
            {APP_NAME} provides a simple visual interface that allows beginners
            learn the Python language and ease their transition to standard
            programming languages.
            <br />
            <br />
            {APP_NAME} provide a guide on how to use basic Python commands as
            well as data structures.
            <br />
            <br />
            {APP_NAME} is a work in progress and it is currently being developed
            and maintained by two final year students:{' '}
            <a href="https://jh123x.com">Junhua</a> &amp;{' '}
            <a href="https://www.linkedin.com/in/cody-chew-7243941a4">Cody</a>.
            We appreciate any feedback given to us!
            <br />
          </p>
          {DISCLAIMER}
        </Typography>
      </Box>
    </Modal>
  );
};
export default AboutModal;
