import { DEFAULT_I, DEFAULT_ITEMS } from '../constants/default-values';

/**
 * Saves the current state to local storage.
 *
 * @param {Array} items - The items to save to local storage.
 * @param {number} i - The max id of the current state.
 * @param {string} fileName - The name of the file.
 * @param {boolean} isFirstTime - Whether or not this is the first time the user has used the app.
 *
 * @returns {void}
 */
export const saveToLocalStorage = ({ items, i, fileName, isFirstTime }) => {
  localStorage.setItem('items', JSON.stringify(items));
  localStorage.setItem('i', i.toString());
  localStorage.setItem('fileName', fileName);
  localStorage.setItem('isFirstTime', isFirstTime === 'true');
};

/**
 * Loads the current state from local storage.
 * @returns {Object} The items, i, and isFirstTime from local storage.
 */
export const loadFromLocalStorage = () => {
  const items = JSON.parse(localStorage.getItem('items')) ?? DEFAULT_ITEMS;
  const i = parseInt(localStorage.getItem('i') ?? DEFAULT_I.toString(), 10);
  const isFirstTime =
    (localStorage.getItem('isFirstTime') ?? 'true') === 'true';
  return {
    items,
    i,
    isFirstTime,
  };
};

/**
 * Clears the local storage.
 * @returns {void}
 */
export const clearLocalStorage = () => {
  localStorage.clear();
};
