import BlockAPI from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown, FOUR_SPACES } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const LAMBDA = 46;

// define block regex for parsing
const LAMBDA_REGEX = /lambda +(.*) *: *(.*)()()/;
const regexArr = [LAMBDA_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
    input1: 'arguments',
    input2: 'expression',
}

// define functional component for helpBox
function LambdaHelpbox() {
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(
                `A **lambda** function is a small anonymous function.\nA lambda function can take any number of arguments, but can only have one expression.`,
            )}
            {markdown(`The syntax of the **lambda** function is as follows:`)}
            {code([`lambda arguments : expression`])}
            {markdown(`Example:`)}
            {markdown(`Add 10 to argument a, and return the result`)}
            {code([`x = lambda a : a + 10`,
                `print(x(5)) # 15`])}
        </div>
    );
}

// define selection block
const LAMBDA_BLOCK = (
    <Button
        style={{ textTransform: 'lowercase' }}
        variant="contained"
        size="small"
    >
        <code style={{ textAlign: 'left' }}>
            lambda&nbsp;{THREE_VW_SIZE} : {THREE_VW_SIZE}
        </code>
    </Button>
);

// define input block
const lambdaInputBlock = ({ input1_div, input2_div }) => {
    return (
        <div>
            <div style={{ display: 'inline-block' }}>
                {getTextInBox('lambda')}&nbsp;
                {input1_div}&nbsp;
                {getString(':')}&nbsp;
                {input2_div}
            </div>
        </div>
    );
};

function parsingFunction(input1, input2) {
    return `lambda ${input1} : ${input2}`;
}


export const LambdaBlock = new BlockAPI(LAMBDA,
    regexArr,
    PLACEHOLDERS,
    <LambdaHelpbox />,
    LAMBDA_BLOCK,
    lambdaInputBlock,
    parsingFunction);