import { APP_NAME } from './constants';

export const AGREE_MESSAGE = `*By using our website, you agree to let us collect and use your personal data.`;

/**
 * Disclaimer for the application.
 * @returns {JSX.Element} The disclaimer.
 */
export const DISCLAIMER = (
  <div
    style={{
      borderRadius: '25px',
      padding: '2%',
      backgroundColor: '#f44336' /* Red */,
      color: 'white',
      marginBottom: '15px',
    }}
  >
    {APP_NAME} is a beginner friendly platform and only facilitates the learning
    of simple Python commands and data structures. The operations in this
    application is non exhaustive.
    <br />
    <br />
    The operations in this application is non exhaustive and below shows a list
    of several limitations which are excluded for this beginner application.
    <ul>
      <li>Input & Output</li>
      <ul>
        <li>Files</li>
      </ul>
      <li>More than 10 indentation levels (Python allows ~100 indentation)</li>
    </ul>
    {AGREE_MESSAGE}
  </div>
);
