import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_TUTORIAL_NO } from '../constants/default-values';

export const tutorialInitialState = {
  currTutorialNo: DEFAULT_TUTORIAL_NO,
  currentStep: 0,
  isCodeExecutionSatisfied: false,
  onStepHasRun: false,
};

export const tutorialStateSlice = createSlice({
  name: 'Tutorial State',
  initialState: tutorialInitialState,
  reducers: {
    setCurrTutorialNo: (state, action) => {
      return {
        ...state,
        currTutorialNo: action.payload,
      };
    },
    setCurrentStep: (state, action) => {
      return {
        ...state,
        currentStep: action.payload,
      };
    },
    setCodeExecutionSatisfied: (state, action) => {
      return {
        ...state,
        isCodeExecutionSatisfied: action.payload,
      };
    },
    setRunOnStep: (state, action) => {
      return {
        ...state,
        onStepHasRun: action.payload,
      };
    },
  },
});

export const {
  setCurrentStep,
  setCurrTutorialNo,
  setCodeExecutionSatisfied,
  setRunOnStep,
} = tutorialStateSlice.actions;
export default tutorialStateSlice.reducer;
