import BlockAPI from "../BlockAPI";
import { THREE_VW_SIZE, code, getAssignmentStatements, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const BITWISE_RIGHTSHIFT = 4;

// define block regex for parsing
const BITWISE_RIGHTSHIFT_REGEX = /(.*)\>\>=(.*)()()/;
const regexArr = [ BITWISE_RIGHTSHIFT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'i',
  input2: '1',
  operator: '>>=',
}

// define functional component for helpBox
function BitwiseRightShiftHelpbox() {
  const lines = [
    `a = 4 # initializes variable a to the value 4`,
    `a >>= 2 # bitwise right shift of 2 bits from value of 4 results 1`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Performs Bitwise right shift on operands and assign value to left operand`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const BITWISE_RIGHTSHIFT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {THREE_VW_SIZE} &gt;&gt;= {THREE_VW_SIZE}
    </code>
  </Button>
);

function parsingFunction(input1, input2) {
  return `${input1} >>= ${input2}`;
}

export const BitwiseRightShiftBlock = new BlockAPI(BITWISE_RIGHTSHIFT, regexArr, PLACEHOLDERS, <BitwiseRightShiftHelpbox />, BITWISE_RIGHTSHIFT_SELECTION_BLOCK, getAssignmentStatements('>>='), parsingFunction);