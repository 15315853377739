import BlockAPI from "../BlockAPI";
import { THREE_VW_SIZE, code, getAssignmentStatements, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number

const DIVIDE_FLOOR = 8;

// define block regex for parsing
const DIVIDE_FLOOR_REGEX = /(.*)\/\/=(.*)()()/;
const regexArr = [ DIVIDE_FLOOR_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'i',
  input2: '10',
  operator: '//=',
}

// define functional component for helpBox
function DivideFloorHelpbox() {
  const lines = [
    `a = 10 # initializes variable a to the value 10`,
    `a //= 4 # assigns value of 2 (quotient of 10/4) to a.`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Divide left operand with right operand and then assign the value(floor) to left operand`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const DIVIDE_FLOOR_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {THREE_VW_SIZE} //= {THREE_VW_SIZE}
    </code>
  </Button>
);

function parsingFunction(input1, input2) {
  return `${input1} //= ${input2}`;
}

export const DivideFloorBlock = new BlockAPI(DIVIDE_FLOOR, regexArr, PLACEHOLDERS, <DivideFloorHelpbox />, DIVIDE_FLOOR_SELECTION_BLOCK, getAssignmentStatements('//='), parsingFunction);