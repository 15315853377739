import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { createTestCase } from '../utils/HandleCodeExecution';
import { onClearThunk } from '../../redux/thunks/onClear';
import TutorialRating from '../components/TutorialRating';
import { AssignmentBlock } from '../../blocks/assignment_statements/Assignment';
import { PrintBlock } from '../../blocks/io_types/Print';
import { blockModeThunk } from '../../redux/thunks/BlockMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 7: Python Operators</h1>
      <h2>At the end of this chapter, you will learn more about:</h2>
      <ol>
        <li>Different kinds of Operators in Python</li>
        <li>Examples on how and when to use them</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const IntroToOperators = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Operators</h2>
      <p>Operators are used to perform operations on variables and values.</p>
      <p>Python divides the operators in the following groups:</p>
      <ol>
        <li>Arithmetic operators</li>
        <li>Assignment operators</li>
        <li>Comparison operators</li>
        <li>Logical operators</li>
        <li>Identity operators</li>
        <li>Membership operators</li>
        <li>Bitwise operators</li>
      </ol>
    </div>
  ),
  onStep: blockModeThunk,
});

const ArithmeticOperators = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Arithmetic Operators</h2>
      <p>
        Arithmetic operators are used with numeric values to perform common
        mathematical operations:
      </p>
      <table>
        <tbody>
          <tr>
            <th>Operator</th>
            <th>Name</th>
            <th>Example</th>
          </tr>
          <tr>
            <td>+</td>
            <td>Addition</td>
            <td>x + y</td>
          </tr>
          <tr>
            <td>-</td>
            <td>Subtraction</td>
            <td>x - y</td>
          </tr>
          <tr>
            <td>*</td>
            <td>Multiplication</td>
            <td>x * y</td>
          </tr>
          <tr>
            <td>/</td>
            <td>Division</td>
            <td>x / y</td>
          </tr>
          <tr>
            <td>%</td>
            <td>Modulus</td>
            <td>x % y</td>
          </tr>
          <tr>
            <td>**</td>
            <td>Exponentiation</td>
            <td>x ** y</td>
          </tr>
          <tr>
            <td>//</td>
            <td>Floor division</td>
            <td>x // y</td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const AssignmentOperators = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Assignment Operators</h2>
      <p>Assignment operators are used to assign values to variables:</p>
      <table>
        <tbody>
          <tr>
            <th>Operator</th>
            <th>Example</th>
            <th>Same As</th>
          </tr>
          <tr>
            <td>=</td>
            <td>x = 5</td>
            <td>x = 5</td>
          </tr>
          <tr>
            <td>+=</td>
            <td>x += 3</td>
            <td>x = x + 3</td>
          </tr>
          <tr>
            <td>-=</td>
            <td>x -= 3</td>
            <td>x = x - 3</td>
          </tr>
          <tr>
            <td>*=</td>
            <td>x *= 3</td>
            <td>x = x * 3</td>
          </tr>
          <tr>
            <td>/=</td>
            <td>x /= 3</td>
            <td>x = x / 3</td>
          </tr>
          <tr>
            <td>%=</td>
            <td>x %= 3</td>
            <td>x = x % 3</td>
          </tr>
          <tr>
            <td>//=</td>
            <td>x //= 3</td>
            <td>x = x // 3</td>
          </tr>
          <tr>
            <td>**=</td>
            <td>x **= 3</td>
            <td>x = x ** 3</td>
          </tr>
          <tr>
            <td>&=</td>
            <td>x &= 3</td>
            <td>x = x & 3</td>
          </tr>
          <tr>
            <td>|=</td>
            <td>x |= 3</td>
            <td>x = x | 3</td>
          </tr>
          <tr>
            <td>^=</td>
            <td>x ^= 3</td>
            <td>x = x ^ 3</td>
          </tr>
          <tr>
            <td>&gt;&gt;=</td>
            <td>x &gt;&gt;= 3</td>
            <td>x = x &gt;&gt; 3</td>
          </tr>
          <tr>
            <td>&lt;&lt;=</td>
            <td>x &lt;&lt;= 3</td>
            <td>x = x &lt;&lt; 3</td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const ComparisonOperators = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Comparison Operators</h2>
      <p>Comparison operators are used to compare two values:</p>
      <table>
        <tbody>
          <tr>
            <th>Operator</th>
            <th>Name</th>
            <th>Example</th>
          </tr>
          <tr>
            <td>==</td>
            <td>Equal</td>
            <td>x == y</td>
          </tr>
          <tr>
            <td>!=</td>
            <td>Not equal</td>
            <td>x != y</td>
          </tr>
          <tr>
            <td>&gt;</td>
            <td>Greater than</td>
            <td>x &gt; y</td>
          </tr>
          <tr>
            <td>&lt;</td>
            <td>Less than</td>
            <td>x &lt; y</td>
          </tr>
          <tr>
            <td>&gt;=</td>
            <td>Greater than or equal to</td>
            <td>x &gt;= y</td>
          </tr>
          <tr>
            <td>&lt;=</td>
            <td>Less than or equal to</td>
            <td>x &lt;= y</td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const LogicalOperators = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Logical Operators</h2>
      <p>Logical operators are used to combine conditional statements:</p>
      <table>
        <tbody>
          <tr>
            <th>Operator</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
          <tr>
            <td>and </td>
            <td>Returns True if both statements are true</td>
            <td>x &lt; 5 and x &lt; 10</td>
          </tr>
          <tr>
            <td>or</td>
            <td>Returns True if one of the statements is true</td>
            <td>x &lt; 5 or x &lt; 4</td>
          </tr>
          <tr>
            <td>not</td>
            <td>Reverse the result, returns False if the result is true</td>
            <td>not(x &lt; 5 and x &lt; 10)</td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const IdentityOperators = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Identity Operators</h2>
      <p>
        Identity operators are used to compare the objects, not if they are
        equal, but if they are actually the same object, with the same memory
        location:
      </p>
      <table>
        <tbody>
          <tr>
            <th>Operator</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
          <tr>
            <td>is</td>
            <td>Returns True if both variables are the same object</td>
            <td>x is y</td>
          </tr>
          <tr>
            <td>is not</td>
            <td>Returns True if both variables are not the same object</td>
            <td>x is not y</td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const MembershipOperators = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Membership Operators</h2>
      <p>
        Membership operators are used to test if a sequence is presented in an
        object:
      </p>
      <table>
        <tbody>
          <tr>
            <th>Operator</th>
            <th>Description</th>
            <th>Example</th>
          </tr>
          <tr>
            <td>in</td>
            <td>
              Returns True if a sequence with the specified value is present in
              the object
            </td>
            <td>x in y</td>
          </tr>
          <tr>
            <td>not in</td>
            <td>
              Returns True if a sequence with the specified value is not present
              in the object
            </td>
            <td>x not in y</td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const BitwiseOperators = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Bitwise Operators</h2>
      <p>Bitwise operators are used to compare (binary) numbers:</p>
      <table>
        <tbody>
          <tr>
            <th>Operator</th>
            <th>Name</th>
            <th>Description</th>
          </tr>
          <tr>
            <td>&</td>
            <td>AND</td>
            <td>Sets each bit to 1 if both bits are 1</td>
          </tr>
          <tr>
            <td>|</td>
            <td>OR</td>
            <td>Sets each bit to 1 if one of two bits is 1</td>
          </tr>
          <tr>
            <td>^</td>
            <td>XOR</td>
            <td>Sets each bit to 1 if only one of two bits is 1</td>
          </tr>
          <tr>
            <td>~</td>
            <td>NOT</td>
            <td>Inverts all the bits</td>
          </tr>
          <tr>
            <td>&lt;&lt;</td>
            <td>Zero fill left shift</td>
            <td>
              Shift left by pushing zeros in from the right and let the leftmost
              bits fall off
            </td>
          </tr>
          <tr>
            <td>&gt;&gt;</td>
            <td>Signed right shift</td>
            <td>
              Shift right by pushing copies of the leftmost bit in from the
              left, and let the rightmost bits fall off
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const TestQuestion = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Test yourself with a simple exercise!</h1>
      <p>Steps:</p>
      <ol>
        <li>Assign the value 10 to a variable</li>
        <li>Assign the value 5 to a variable</li>
        <li>print out 10 modulus 5</li>
        <li>print out 10 exponent 5</li>
      </ol>
      <p>
        <b>Note:</b> You have to assign 2 variables correctly and use the
        correct operators in the print statements to pass the test cases.
      </p>
    </div>
  ),
  condition: ({ items }) => {
    const hasPrintModulus = items.some((item) => {
      const inputField1 = item.input1 ?? '';
      return item.type === PrintBlock.getType() && inputField1.includes('%');
    });
    const hasPrintExponent = items.some((item) => {
      const inputField1 = item.input1 ?? '';
      return item.type === PrintBlock.getType() && inputField1.includes('**');
    });
    const hasVariable10 = items.some((item) => {
      const inputField2 = item.input2 ?? '';
      return (
        item.type === AssignmentBlock.getType() && inputField2.trim() === '10'
      );
    });
    const hasVariable5 = items.some((item) => {
      const inputField2 = item.input2 ?? '';
      return (
        item.type === AssignmentBlock.getType() && inputField2.trim() === '5'
      );
    });
    return hasPrintModulus && hasPrintExponent && hasVariable10 && hasVariable5;
  },
  onStep: blockModeThunk,
  testCases: [createTestCase('', '0\n100000\n')],
  resetCode: '',
});
const CongratulationsMessage = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Congratulations!</h1>
      <p>Congratulations! You have completed this tutorial!</p>
      <p>
        Join us in the next tutorial to learn how to do more cool things in
        python!
      </p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  IntroToOperators,
  ArithmeticOperators,
  AssignmentOperators,
  ComparisonOperators,
  LogicalOperators,
  IdentityOperators,
  MembershipOperators,
  BitwiseOperators,
  TestQuestion,
  CongratulationsMessage,
];

export const Chapter7Operators = new Tutorial({
  name: 'Chapter 7: Operators',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter7Operators.addSteps(steps);
