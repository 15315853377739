import { createSlice } from '@reduxjs/toolkit';
import { CLOSED } from '../constants/block-fly-out-state';

export const blockFlyOutInitialState = {
  openedFlyOut: CLOSED,
};

export const blockFlyOutSlice = createSlice({
  name: 'Opened FlyOut',
  initialState: { ...blockFlyOutInitialState },
  reducers: {
    closeFlyOut: (state) => {
      return {
        ...state,
        openedFlyOut: CLOSED,
      };
    },
    setFlyOut: (state, action) => {
      return {
        ...state,
        openedFlyOut: action.payload,
      };
    },
  },
});

export const { closeFlyOut, setFlyOut } = blockFlyOutSlice.actions;
export default blockFlyOutSlice.reducer;
