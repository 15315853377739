import { NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { onClearThunk } from '../../redux/thunks/onClear';
import TutorialRating from '../components/TutorialRating';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 5: Python Identifiers</h1>
      <h2>At the end of this chapter, you will learn more about:</h2>
      <ol>
        <li>Python Identifiers</li>
        <li>Syntax of Identifiers</li>
        <li>Some valid and invalid Identifiers</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
});

const IntroToIdentifiers = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>
        Now that we've learnt a bit about Python syntax as well as variables,
        let's learn more about identifiers.
      </h2>
      <p>
        Identifiers are the name given to variables, classes, methods, etc. For
        example,
      </p>
      {code(["language = 'Python'"])}
      <p>
        Here, <code>language</code> is a variable (an identifier) which holds
        the value <code>'Python'</code>.
      </p>
    </div>
  ),
});

const IdentifiersCannotUseKeywords = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Identifiers</h2>
      <p>
        We cannot use keywords as variable names as they are reserved names that
        are built-in to Python. For example,
      </p>
      {code(["continue = 'Python'"])}
      <p>
        The above code is wrong because we have used <code>continue</code> as a
        variable name, which is a keyword in Python.
      </p>
    </div>
  ),
});

const IdentifiersThingsToNote = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Rules for Naming an Identifier</h2>
      <ol>
        <li>Identifiers cannot be a keyword.</li>
        <li>Identifiers are case-sensitive.</li>
        <li>
          It can have a sequence of letters and digits. However, it must begin
          with a letter or <code>_</code>. The first letter of an identifier
          cannot be a digit.
        </li>
        <li>
          It's a convention to start an identifier with a letter rather{' '}
          <code>_</code>.
        </li>
        <li>
          Whitespaces are not allowed. We cannot use special symbols like{' '}
          <b>!, @, #, $,</b> and so on.
        </li>
      </ol>
    </div>
  ),
});

const IdentifiersExamples = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Some Valid and Invalid Identifiers in Python</h2>
      <table>
        <tbody>
          <tr>
            <th>Valid Identifiers</th>
            <th>Invalid Identifers</th>
          </tr>
          <tr>
            <td>score</td>
            <td>@core</td>
          </tr>
          <tr>
            <td>return_value</td>
            <td>return</td>
          </tr>
          <tr>
            <td>highest_score</td>
            <td>highest score</td>
          </tr>
          <tr>
            <td>name1</td>
            <td>1name</td>
          </tr>
          <tr>
            <td>convert_to_string</td>
            <td>convert to_string</td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
});

const CongratulationsMessage = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Congratulations!</h1>
      <p>Congratulations! You have completed this tutorial!</p>
      <p>
        Join us in the next tutorial to learn how to do more cool things in
        python!
      </p>
      <div>
        <TutorialRating/>
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  IntroToIdentifiers,
  IdentifiersCannotUseKeywords,
  IdentifiersThingsToNote,
  IdentifiersExamples,
  CongratulationsMessage,
];

export const Chapter5Identifiers = new Tutorial({
  name: 'Chapter 5: Identifiers',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter5Identifiers.addSteps(steps);
