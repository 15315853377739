import { APP_NAME } from '../../constants/constants';
import { AGREE_MESSAGE } from '../../constants/disclaimer';
import { loadExample } from '../../redux/itemsState';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourStyle, tourDivStyle } from '../styles';
import {
  NOTHING_SELECTOR,
  RUN_CODE_SELECTOR,
  TUTORIAL_SELECTOR,
  CODE_IDE_SELECTOR,
  SELECTION_COLUMN_SELECTOR,
  IDEUTILS_SELECTOR,
  HELP_BOX_SELECTOR,
} from '../constants';
import { openRegister } from '../../redux/modalState';
import { blockModeThunk } from '../../redux/thunks/BlockMode';

const tourStep1 = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <p>
        Welcome to {APP_NAME}! This is a tutorial to help you get started with
        using the platform. Click ➔ to continue.
      </p>
      <iframe
        src="https://www.youtube.com/embed/fi4s7UACprc"
        title="YouTube video player"
        frameBorder="0"
        style={{
          width: '100%',
          height: '30vh',
        }}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <p>
        {APP_NAME} is a beginner friendly platform and only facilitates the
        learning of simple Python commands and data structures.
      </p>
      <p>
        <b>{AGREE_MESSAGE}</b>
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const tourStep2 = CreateStep({
  selector: CODE_IDE_SELECTOR,
  content: (
    <div style={tourStyle}>
      Here is the code section. Your Python coding blocks will be here!
    </div>
  ),
  onStep: blockModeThunk,
});

const tourStep3 = CreateStep({
  selector: SELECTION_COLUMN_SELECTOR,
  content: (
    <div style={tourStyle}>
      You can see all the coding blocks available in {APP_NAME} here.{' '}
      <b>Click</b> on the block to add it to the code section.
    </div>
  ),
  onStep: blockModeThunk,
});

const tourStep4 = CreateStep({
  selector: IDEUTILS_SELECTOR,
  content: (
    <div style={tourStyle}>
      Here are the features provided by the platform. Check it out one by one!
    </div>
  ),
});

const tourStep5 = CreateStep({
  selector: HELP_BOX_SELECTOR,
  content: (
    <div style={tourStyle}>
      This section provides guidance on how you can use the Python coding
      blocks.
    </div>
  ),
  onStep: blockModeThunk,
});

const tourStep6 = CreateStep({
  selector: RUN_CODE_SELECTOR,
  content: (
    <div style={tourStyle}>
      This is the code output session. You can click "Run code" in the
      navigation bar previously to execute your code.
    </div>
  ),
});

const tourStep7 = CreateStep({
  selector: TUTORIAL_SELECTOR,
  content: (
    <div style={tourStyle}>
      <p>
        The tutorial is over. Please proceed to <b>register</b> for an account
        and learn from our <b>Tutorials</b>!
      </p>
      <p>
        Please take some time (~3min) to fill in this{' '}
        <a
          href="https://forms.gle/Y8DkuoX9grcodi7i7"
          target="_blank"
          rel="noopener noreferrer"
        >
          feedback form
        </a>{' '}
        once you've tried some of our features. Your feedback is greatly
        appreciated!
      </p>
    </div>
  ),
});

const steps = [
  tourStep1,
  tourStep2,
  tourStep3,
  tourStep4,
  tourStep5,
  tourStep6,
  tourStep7,
];

const preTutorialCall = () => (dispatch, getState) => {
  dispatch(loadExample(0));
};

const postTutorialCall = () => (dispatch, getState) => {
  if (!getState().authState.loggedIn) dispatch(openRegister());
};

export const Introduction = new Tutorial({
  name: 'Introduction',
  preTutorialCall: preTutorialCall,
  postTutorialCall: postTutorialCall,
});
Introduction.addSteps(steps);
