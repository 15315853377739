import './BlocksFlyOut.css';
import { BLOCK_STYLE } from '../constants/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setHoveringValue, addBlock } from '../redux/itemsState';
import { closeFlyOut } from '../redux/blockFlyOutState';
import { useRef, useEffect } from 'react';
import { blockManager } from '../blocks/BlockManager';
import { DEFAULT_HOVER_VALUE } from '../constants/default-values';

/**
 * Stores the block when the user starts dragging it.
 * @param {Event} e - The event that is triggered when the user starts dragging a block.
 * @param {BlockType} blockType - The type of block that is being dragged.
 * @returns {void}
 */
const onDragStart = (e, blockType) => {
  e.dataTransfer.setData('blockType', parseInt(blockType, 10));
  e.dataTransfer.setDragImage(e.target, 0, 0);
};

/**
 * The BlocksFlyOut element.
 * @returns {JSX.Element} - The JSX element for the BlocksFlyOut component.
 */
function BlocksFlyOut() {
  const dispatch = useDispatch();
  const showFlyOut = useSelector(
    (state) => state.blockFlyOutState.openedFlyOut,
  );
  const flyOut = useRef(null);
  useOutsideAlerter(flyOut);

  function useOutsideAlerter(ref) {
    if (ref == null) {
      return;
    }
    const dispatch = useDispatch();
    useEffect(() => {
      // Function for click event
      function handleOutsideClick(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (
            event.target.classList.contains('MuiButton-root') ||
            event.target.classList.contains('MuiButton-label')
          ) {
            return;
          }
          dispatch(closeFlyOut());
        }
      }

      // Adding click event listener
      document.addEventListener('click', handleOutsideClick);
      return () => document.removeEventListener('click', handleOutsideClick);
    }, [ref]);
  }

  const getBlock = (block) => {
    return (
      <div
        data-testid={`block-${block.getType()}`}
        key={block.getType()}
        style={BLOCK_STYLE}
        onClick={() => {
          dispatch(addBlock(block.getType()));
          dispatch(closeFlyOut());
          dispatch(setHoveringValue(DEFAULT_HOVER_VALUE));
        }}
        draggable="true"
        className="draggableComponents"
        onMouseEnter={() => dispatch(setHoveringValue(block.getType()))}
        onMouseLeave={() => {
          dispatch(setHoveringValue(DEFAULT_HOVER_VALUE));
        }}
        onDragStart={(e) => {
          onDragStart(e, block.getType());
        }}
      >
        {block.getSelectionBlock()}
      </div>
    );
  };

  const renderFromType = (type) => {
    if (!blockManager.isTypeSelectable(type)) {
      return <div></div>;
    }
    const blocks = blockManager.getBlocksFromType(type);
    const relevantBlocks = blocks.map(function (block) {
      return getBlock(block);
    });
    return <div>{relevantBlocks}</div>;
  };

  return (
    <div className="fly-out" ref={flyOut}>
      {renderFromType(showFlyOut)}
    </div>
  );
}
export default BlocksFlyOut;
