import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const PRINT_SAME_LINE = 39;

// define block regex for parsing
const PRINT_SAME_LINE_REGEX = /print *\((.*), *end *= *'(.*)' *\)()()/;
const regexArr = [ PRINT_SAME_LINE_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'x',
  input2: 'separator',
}

// define functional component for helpBox
function PrintSameLineHelpbox() {
  const lines = [
    `# The command outputs the message "This is a sentence."`,
    `print("This is a sentence", end=".")`,
    ``,
    `# The commands below outputs its values on separate lines since the end parameter takes on the default value of a newline character`,
    `print("First line") # Internally prints out "First line\\n"`,
    `print("Second line") # Internally prints out "Second line\\n"`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The **print()** function takes in a few optional arguments. The parameter **end=** specifies what to print at the end. Default is '\\n' (represents a new line)`,
      )}
      {code(lines)}
    </div>
  );
}
// define selection block
const PRINT_SAME_LINE_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      print({TWO_VW_SIZE}, end='{ONE_VW_SIZE}')
    </code>
  </Button>
);

// define input block
const printSameLineInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {getTextInBox('print')}
      {getString('(')}
      {input1_div}
      {getString(',')} end {getString('=')} {getString("'")}
      {input2_div}
      {getString("'")}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `print(${input1}, end = '${input2}')`;
}

export const PrintSameLineBlock = new BlockAPI(PRINT_SAME_LINE,
  regexArr,
  PLACEHOLDERS,
  <PrintSameLineHelpbox />,
  PRINT_SAME_LINE_SELECTION_BLOCK,
  printSameLineInputBlock,
  parsingFunction);