import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const IMPORT_STATEMENT = 37;

// define block regex for parsing
const IMPORT_STATEMENT_REGEX = /import +(.*)()()()/;
const regexArr = [ IMPORT_STATEMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
    input1: 'module_name',
}

// define functional component for helpBox
function ImportStatementHelpbox() {
    const lines = [
        `import modulename`,
    ];
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(
                `The Python **import** statement lets you import a module into your code. A module is a file that contains functions and values that you can reference from your program.`
            )}
            {markdown(`The syntax of the **import** statement is as follows:`)}
            {code(lines)}
        </div>
    );
}

// define selection block
const IMPORT_SELECTION_BLOCK = (
    <Button
        style={{ textTransform: 'lowercase' }}
        variant="contained"
        size="small"
    >
        <code>
            import&nbsp;
            {THREE_VW_SIZE}
        </code>
    </Button>
);

// define input block
const importInputBlock = ({ input1_div }) => (
    <div>
        {getTextInBox('import')}
        {input1_div}
    </div>
)

function parsingFunction(input1) {
    return `import ${input1}`;
}

export const ImportBlock = new BlockAPI(IMPORT_STATEMENT,
    regexArr,
    PLACEHOLDERS,
    <ImportStatementHelpbox />,
    IMPORT_SELECTION_BLOCK,
    importInputBlock,
    parsingFunction);