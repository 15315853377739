import { closePythonTutor, toggleShowIndent } from '../itemsState';

/**
 * Toggle Indentation highlighting.
 * @returns {Function}
 */
export const showIndentThunk = () => (dispatch, getState) => {
  const state = getState();
  const isPythonTutorOn = state.itemStates.isPythonTutorOn;
  if (isPythonTutorOn) dispatch(closePythonTutor());
  dispatch(toggleShowIndent());
};
