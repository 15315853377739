import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { code } from '../../components/ComponentUtils';
import { createTestCase } from '../utils/HandleCodeExecution';
import { onClearThunk } from '../../redux/thunks/onClear';
import { showSnackBarMessage } from '../../redux/messageState';
import {
  closePythonTutor,
  setItems,
  setPythonCodeBoxContentFromItems,
} from '../../redux/itemsState';
import astParser from '../../utils/AstParser';
import TutorialRating from '../components/TutorialRating';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Chapter 14: Object Oriented Programming</h1>
      <h2>At the end of this chapter, you will be able to:</h2>
      <ol>
        <li>Learn about Object Oriented Programming</li>
        <li>Learn how to write a class</li>
        <li>Class methods</li>
        <li>Learn about inheritance</li>
      </ol>
    </div>
  ),
});

const WhatIsOOP = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h2>What is Object Oriented Programming?</h2>
      <p>
        Object Oriented Programming is a programming paradigm that uses objects
        and their interactions to design and program applications.
      </p>
      <p>
        You can think of it with respect to the real world. For example, a car
        is an object.
      </p>
      <p>
        We keep all the functions and the attributes of objects together. This
        will be shown in the later pages.
      </p>
    </div>
  ),
});

const CarsPart1 = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h2>Car Class</h2>
      <p>
        Let's say we are running a car dealership and we want to keep track of
        the various cars we have around.
        <br />
        These are some of the attributes we might want to keep track of:
      </p>
      <ol>
        <li>The Manufacturer of the car</li>
        <li>The model of the car</li>
        <li>The price of the car</li>
      </ol>
      <p>
        Traditionally, we will do something like this to keep track of cars:
      </p>
      {code([
        '# Making cars, stored as (manufacturer, model, price) tuples',
        'car1 = ("Toyota", "Camry", 20000)',
      ])}
      <p>
        When we want to make another car, we will have to do something like
        this:
      </p>
      {code(['car2 = ("Toyota", "Prius", 25000)', ''])}
      There are a few problems with this implementation.
      <ol>
        <li>
          This is not very efficient as we have to remember the order of the
          attributes.
        </li>
        <li>
          To work on the car, we will have to create functions catering to the
          car format.
        </li>
        <li>
          When there are changes, we must modify all the functions that work on
          as well as the way to make the car.
        </li>
        <li>
          There are also some other problems as we will see in the later pages
        </li>
      </ol>
      <p>This is where Object Oriented Programming comes in.</p>
    </div>
  ),
});

const CarsPart2OOP = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h2>Car Class</h2>
      <p>
        In Python Object Oriented Programming, we can create a class to model a
        car. A class is a blueprint for creating objects.
      </p>
      <p>An class in python looks like this:</p>
      {code([
        'class Car:',
        '    def __init__(self, manufacturer, model, price):',
        '        self.manufacturer = manufacturer',
        '        self.model = model',
        '        self.price = price',
        '',
      ])}
      <p>To create a car, we can do:</p>
      {code(['car1 = Car("Toyota", "Camry", 20000)'])}
      <p>We can access the attributes of the car by doing:</p>
      {code([
        'car1.manufacturer # Access the name',
        'car1.model # Access the model',
        'car1.price # Access the price',
      ])}
      <p>Let us try it out.</p>
    </div>
  ),
});

// Exported for testing
export const carCode1 =
  'class Car:\n    def __init__(self, manufacturer, model, price):\n        self.manufacturer = manufacturer\n        self.model = model\n        self.price = price\ncar1 = Car("Toyota", "Camry", 20000)';

const CarsPartExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h2>Car Class</h2>
      <p>
        The code for the car is provided below. Feel free to try it out and play
        around.
      </p>
      <p>
        To proceed to the next step, print out the manufacturer, model and price
        of the car (In that order on separate lines)
      </p>
    </div>
  ),
  resetCode: carCode1,
  testCases: [createTestCase('', 'Toyota\nCamry\n20000\n')],
  onStep: () => (dispatch, getState) => {
    const [isSuccess, items, errorMsg] = astParser(carCode1);
    if (!isSuccess)
      return dispatch(
        showSnackBarMessage(
          `Error encountered: Please inform an admin: ${errorMsg}`,
        ),
      );
    dispatch(closePythonTutor());
    dispatch(setItems(items));
    dispatch(setPythonCodeBoxContentFromItems());
  },
});

const CarMethod = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h2>Class Methods</h2>
      <p>
        Other than just storing values in a class, we can also add functions to
        the class. This helps us to group functions that work on the class
        together.
      </p>
      <p>
        For example, we can add a function to the car class to print out the car
        details.
      </p>
      <p>Let us take a look at an example on the next page</p>
    </div>
  ),
});

const CarMethodPart2 = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      {code([
        'class Car:',
        '    def __init__(self, manufacturer, model, price):',
        '        self.manufacturer = manufacturer',
        '        self.model = model',
        '        self.price = price',
        '',
        '    def print_details(self):',
        '        print(f"A {self.manufacturer} {self.model} selling for {self.price}")',
      ])}
      <p>
        In the function above, we can access the attributes of the class by
        using the <code>self</code> keyword. In this way we can access both the
        attributes as well as other methods of the class.
        <br />
        The <code>__init__</code> function is a special function that is called
        when we create a new instance of the class. IE: When we do
      </p>
      <pre>`car1 = Car('Toyota', 'Camry', 20000)'`</pre>
      <p>
        the <code>__init__</code> function of Car class is called.
      </p>
    </div>
  ),
});

// Exported for testing
export const CarMethodCode = `class Car:\n    def __init__(self, manufacturer, model, price):\n        self.manufacturer = manufacturer\n        self.model = model\n        self.price = int(price)\n# Do not touch the code below\nmanufacturer=input().strip()\nmodel=input().strip()\nprice=int(input().strip())\ncar1=Car(manufacturer, model, price)\nprint(car1.get_discounted_price())`;
const discount_ratio = 0.1;

const CarMethodExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h2>Car Methods</h2>
      <p>Your code needs to:</p>
      <p>
        Define a method called <code>get_discounted_price</code>
        <br />
        It computes the discounted price of a car after a{' '}
        {Math.trunc(discount_ratio * 100)}% discount
      </p>
    </div>
  ),
  resetCode: CarMethodCode,
  testCases: [
    createTestCase(
      'Toyota\nCamery\n10000',
      `${10000 * (1 - discount_ratio)}.0\n`,
    ),
    createTestCase('Toyota\nCamery\n0\n', `0.0\n`),
    createTestCase(
      'Toyota\nCamery\n-10000\n',
      `${-10000 * (1 - discount_ratio)}.0\n`,
    ),
    createTestCase(
      'Nissan\nLeaf\n300000\n',
      `${300000 * (1 - discount_ratio)}.0\n`,
    ),
  ],
  onStep: () => (dispatch, _) => {
    const [isSuccess, items, errorMsg] = astParser(CarMethodCode);
    if (!isSuccess)
      return dispatch(
        showSnackBarMessage(
          `Error encountered: Please inform an admin: ${errorMsg}`,
        ),
      );
    dispatch(closePythonTutor());
    dispatch(setItems(items));
    dispatch(setPythonCodeBoxContentFromItems());
  },
});

const CarInheritance = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h2>Inheritance</h2>
      <p>
        Other than just creating templates for different objects we want to
        represent, we can also make use of inheritance to extend different
        classes
      </p>
      <p>For example, we can create a class for a Toyota car</p>
      {code([
        'class ToyotaCar(Car):',
        '    def __init__(self, model, price):',
        '        super().__init__("Toyota", model, price)',
        '',
      ])}
      <p>
        The code snippet above creates a Toyota car class that inherits from the
        Car class. The ToyotaCar class has the same attributes as the Car class,
        but it also has a model and a price input where users' can define.
      </p>
      <p>
        Any Toyota car will have the manufacturer "Toyota" as it is inherited
        from the Car class. It is similar to how a child inherits the same eye
        colour as the parent.
      </p>
      <p>
        The <code>super()</code> function is used to call the parent class. In
        this case, the child (ToyotaCar) inherits the model and price from the
        parent (Car) with the manufacturer option being set to "Toyota".
      </p>
    </div>
  ),
});

// Exported for testing
export const CarInheritanceCode = `class Car:\n    def __init__(self, manufacturer, model, price):\n        self.manufacturer = manufacturer\n        self.model = model\n        self.price = int(price)\n# Do not touch the code below\nmanufacturer=input().strip()\nmodel=input().strip()\nprice=int(input().strip())\ncar1=Car(manufacturer, model, price)\ncar2=ToyotaCar(model, price)\nprint(car1.manufacturer, car1.model, car1.price)\nprint(car2.manufacturer, car2.model, car2.price)  `;

const CarInheritanceExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h2>Car Inheritance</h2>
      <p>
        You will be given the code snippet from the previous page. Your code
        needs to:
      </p>
      <ol>
        <li>
          Define a class called <code>ToyotaCar</code> that inherits from the
          Car class
        </li>
        <li>
          The <code>ToyotaCar</code> class with the same attributes as the Car
          class
        </li>
        <li>Should have a model and a price as input</li>
        <li>Its manufacturer will always be `Toyota`</li>
      </ol>
    </div>
  ),
  onStep: () => (dispatch, _) => {
    const [isSuccess, items, errorMsg] = astParser(CarInheritanceCode);
    if (!isSuccess) {
      dispatch(
        showSnackBarMessage(
          `Error encountered: Please inform an admin: ${errorMsg}`,
        ),
      );
      return;
    }
    dispatch(closePythonTutor());
    dispatch(setItems(items));
    dispatch(setPythonCodeBoxContentFromItems());
  },
  resetCode: CarInheritanceCode,
  testCases: [
    createTestCase('T\nC\n10000\n', 'T C 10000\nToyota C 10000\n'),
    createTestCase('Nissan\nCamery\n0\n', 'Nissan Camery 0\nToyota Camery 0\n'),
    createTestCase(
      'Honda\nCamery\n30\n',
      'Honda Camery 30\nToyota Camery 30\n',
    ),
  ],
});

const CarPolyMorph = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h2>Polymorphism</h2>
      <p>
        Polymorphism is the ability to have different classes with the same
        method name, but different implementations.
      </p>
      <p>
        For example, we can have a <code>print_details</code> method in the Car
        class that returns the details of the car. However, we can also have a{' '}
        <code>print_details</code> method in the ToyotaCar class that returns
        the details of the car, but with a different implementation.
      </p>
      <p>Let's look at an example on the next page</p>
    </div>
  ),
});

const CarPolyMorph2 = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      {code([
        'class Car:',
        '    def __init__(self, manufacturer, model, price):',
        '        self.manufacturer = manufacturer',
        '        self.model = model',
        '        self.price = int(price)',
        '    def print_details(self):',
        '        print(f"{self.manufacturer} {self.model} at {self.price}")',
        '',
        'class ToyotaCar(Car):',
        '    def __init__(self, model, price):',
        '        super().__init__("Toyota", model, price)',
        '    def print_details(self):',
        '        print(f"Only the best Toyota {self.model} at {self.price}")',
      ])}
      <p>
        In the code snippet above, we have a Car class and a ToyotaCar class.
        Both classes have a <code>print_details</code> method, but the
        implementation is different.
      </p>
      <p>
        The ToyotaCar prints <code>Only the best Toyota model at price</code>{' '}
        while the Car class prints <code>manufacturer model at price</code>.
      </p>
      <p>
        This will make ToyotaCar compatible with any functions that uses Car but
        shows a different message. (Not nessesarily true vice versa if the child
        class has more methods/attributes)
      </p>
    </div>
  ),
});

// Exported for testing
export const CarPolyMorphCode = `class Car:\n    def __init__(self, manufacturer, model, price):\n        self.manufacturer = manufacturer\n        self.model = model\n        self.price = int(price)\n    def get_discounted_price(self):\n        return self.price * ${
  1 - discount_ratio
}\nclass ToyotaCar(Car):\n    def __init__(self, model, price):\n        super().__init__("Toyota", model, price)\n\n# Do not touch the code below\nmanufacturer=input().strip()\nmodel=input().strip()\nprice=int(input().strip())\ncar1=Car(manufacturer, model, price)\ncar2=ToyotaCar(model, price)\nprint(car1.get_discounted_price())\nprint(car2.get_discounted_price())`;

const PolymorphismExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h2>Polymorphism</h2>
      <p>Your code needs to:</p>
      <ol>
        <li>
          Define a class called <code>ToyotaCar</code> (inherits from the Car
          class)
        </li>
        <li>
          <code>ToyotaCar</code> class should have the same attributes as the
          Car class
        </li>
        <li>
          <code>get_discounted_price</code> of <code>ToyotaCar</code> should
          returns the steeper discount
        </li>
      </ol>
    </div>
  ),
  resetCode: CarPolyMorphCode,
  onStep: () => (dispatch, _) => {
    const [isSuccess, items, errorMsg] = astParser(CarPolyMorphCode);
    if (!isSuccess) {
      dispatch(
        showSnackBarMessage(
          `Error encountered: Please inform an admin: ${errorMsg}`,
        ),
      );
      return;
    }
    dispatch(closePythonTutor());
    dispatch(setItems(items));
    dispatch(setPythonCodeBoxContentFromItems());
  },
  testCases: [
    createTestCase('T\nC\n10000\n', `9000.0\n8000.0\n`),
    createTestCase('Nissan\nCamery\n0\n', `0.0\n0.0\n`),
    createTestCase('Honda\nCamery\n10\n', `9.0\n8.0\n`),
  ],
});

const Conclusion = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Conclusion</h1>
      <p>In this chapter, you should have learnt:</p>
      <ul>
        <li>Learn about Object Oriented Programming</li>
        <li>Learn how to write a class</li>
        <li>Learn about inheritance</li>
      </ul>
      <p>Hope you had fun with the tutorials so far.</p>
      <p>
        Please take some time to fill in this{' '}
        <a
          href="https://forms.gle/Y8DkuoX9grcodi7i7"
          target="_blank"
          rel="noopener noreferrer"
        >
          feedback form
        </a>{' '}
        or write in to us in the feedback section!
      </p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  WhatIsOOP,
  CarsPart1,
  CarsPart2OOP,
  CarsPartExample,
  CarMethod,
  CarMethodPart2,
  CarMethodExample,
  CarInheritance,
  CarInheritanceExample,
  CarPolyMorph,
  CarPolyMorph2,
  PolymorphismExample,
  Conclusion,
];

export const Chapter14OOP = new Tutorial({
  name: 'Chapter 14: Object Oriented Programming',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter14OOP.addSteps(steps);
