import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const WHILE_LOOP = 22;

// define block regex for parsing
const WHILE_LOOP_REGEX = /while *\((.*)\) *:()()()/;
const WHILE_LOOP_NO_BRACKET_REGEX = /while *(.*) *:()()()/;
const regexArr = [ WHILE_LOOP_REGEX, WHILE_LOOP_NO_BRACKET_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'i < n',
}

// define functional component for helpBox
function WhileLoopHelpbox() {
  const lines = [`i = 1`, `while i < 6:`, `    print(i)`, `    i += 1`];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `With the **while** loop we can execute a set of statements as long as a condition is true.
        \n **Example: Print i as long as i is less than 6:**`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const WHILE_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>while ({THREE_VW_SIZE}):</code>
  </Button>
);

// define input block
const whileInputBlock = ({ input1_div }) => {
  return (
    <div>
      {getTextInBox('while')}&nbsp;{getString('(')}
      {input1_div}
      {getString(')')}
      {getString(':')}
    </div>
  );
};

function parsingFunction(input1) {
  return `while (${input1}):`;
}

export const WhileBlock = new BlockAPI(WHILE_LOOP,
  regexArr,
  PLACEHOLDERS,
  <WhileLoopHelpbox />,
  WHILE_SELECTION_BLOCK,
  whileInputBlock,
  parsingFunction);