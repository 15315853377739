import { createSlice } from '@reduxjs/toolkit';
import {
  NONE,
  TUTORIAL,
  ABOUT,
  FEEDBACK,
  IMPORT,
  LOGIN,
  REGISTER,
  EDIT_PROFILE,
} from './../constants/modal-type';

export const modalInitialState = {
  openedModal: NONE,
};

export const modalSlice = createSlice({
  name: 'Opened Modal',
  initialState: {
    ...modalInitialState,
  },
  reducers: {
    closeModal: (state) => {
      return {
        ...state,
        openedModal: NONE,
      };
    },
    openAbout: (state) => {
      return {
        ...state,
        openedModal: ABOUT,
      };
    },
    openFeedback: (state) => {
      return {
        ...state,
        openedModal: FEEDBACK,
      };
    },
    openImport: (state) => {
      return {
        ...state,
        openedModal: IMPORT,
      };
    },
    openLogin: (state) => {
      return {
        ...state,
        openedModal: LOGIN,
      };
    },
    openRegister: (state) => {
      return {
        ...state,
        openedModal: REGISTER,
      };
    },
    openTutorial: (state) => {
      return {
        ...state,
        openedModal: TUTORIAL,
      };
    },
    openEditProfile: (state) => {
      return {
        ...state,
        openedModal: EDIT_PROFILE,
      };
    },
  },
});

export const {
  closeModal,
  openTutorial,
  openAbout,
  openFeedback,
  openImport,
  openLogin,
  openRegister,
  openEditProfile,
} = modalSlice.actions;
export default modalSlice.reducer;
