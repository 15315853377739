import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const TUPLE_COUNT = 42;

// define block regex for parsing
const TUPLE_COUNT_REGEX = /(.*) *= *(.*) *\. *count *\((.*)\)()()/;
const regexArr = [ TUPLE_COUNT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'variable',
  input2: 'tuple',
  input3: 'value'
}

// define functional component for helpBox
function TupleCountHelpbox() {
  const lines = [
    `thistuple = (1, 3, 7, 8, 7, 5, 4, 6, 8, 5)`,
    ``,
    `x = thistuple.count(5) # Search for the first occurrence of the value 8, and return its position`,
    ``,
    `print(x) # 2`
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`The **count()** method returns the number of times a specified value appears in the tuple.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const TUPLE_COUNT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {ONE_VW_SIZE} = {TWO_VW_SIZE}.count({ONE_VW_SIZE})
    </code>
  </Button>
);

// define input block
const TupleCountInputBlock = ({ input1_div, input2_div, input3_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {input2_div}.count{getString('(')}
      {input3_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2, input3) {
  return `${input1} = ${input2}.count(${input3})`;
}

export const TupleCountBlock = new BlockAPI(TUPLE_COUNT,
  regexArr,
  PLACEHOLDERS,
  <TupleCountHelpbox />,
  TUPLE_COUNT_SELECTION_BLOCK,
  TupleCountInputBlock,
  parsingFunction);