import { List, ListItem } from '@material-ui/core';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { copyThunk } from '../redux/thunks/Copy';
import { onPaste, removeBlock, toFreeText } from '../redux/itemsState';
import { closeMenu } from '../redux/temporaryStates';
import { onDeleteThunk } from '../redux/thunks/onDelete';
import { unindentThunk, indentThunk } from '../redux/thunks/Indent';
import { onDeleteBlockThunk } from '../redux/thunks/onDeleteBlock';
import { toFreeTextThunk } from '../redux/thunks/toFreeText';

/**
 * The Context Menu element.
 * @param {bool} hidden - Whether the context menu is hidden or not.
 * @returns {JSX.Element} - The context menu.
 */
export const ContextMenu = ({ hidden }) => {
  const { x, y } = useSelector((state) => state.temporaryStates.anchorPoint);
  const store = useStore();
  const selected = useSelector((state) => state.itemStates.selected);
  const dispatch = useDispatch();
  return (
    <List
      style={{
        top: y,
        left: x,
        position: 'absolute',
        display: hidden ? 'none' : 'block',
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      <ListItem button onClick={() => store.dispatch(toFreeTextThunk())}>
        To Free Text
      </ListItem>
      <ListItem
        button
        onClick={(e) => {
          store.dispatch(onDeleteThunk());
          e.stopPropagation();
        }}
        className="deleteRow-context-btn"
      >
        Delete Row
      </ListItem>
      <ListItem
        button
        onClick={(e) => {
          store.dispatch(onDeleteBlockThunk());
          e.stopPropagation();
        }}
        className="deleteGrp-context-btn"
      >
        Delete Block
      </ListItem>
      <ListItem
        button
        onClick={(e) => {
          store.dispatch(copyThunk());
          e.stopPropagation();
        }}
        className="copy-context-btn"
      >
        Copy
      </ListItem>
      <ListItem
        button
        onClick={(e) => {
          dispatch(onPaste());
          e.stopPropagation();
        }}
        className="paste-context-btn"
      >
        Paste
      </ListItem>
      <ListItem
        button
        onClick={(e) => {
          store.dispatch(indentThunk());
          e.preventDefault();
        }}
        className="indent-context-btn"
      >
        Indent
      </ListItem>
      <ListItem
        button
        onClick={(e) => {
          store.dispatch(unindentThunk());
          e.preventDefault();
        }}
        className="unindent-context-btn"
      >
        Unindent
      </ListItem>
    </List>
  );
};
