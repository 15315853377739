import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { code, markdown } from '../../components/ComponentUtils';
import { createTestCase } from '../utils/HandleCodeExecution';
import { onClearThunk } from '../../redux/thunks/onClear';
import TutorialRating from '../components/TutorialRating';
import { blockModeThunk } from '../../redux/thunks/BlockMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Chapter 13: Exception</h1>
      <h2>At the end of this chapter, you will be able to:</h2>
      <ol>
        <li>Learn how to catch exceptions</li>
        <li>Learn about Try, Except, Else and Finally</li>
      </ol>
    </div>
  ),
});

const TryExceptSyntax = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Try Except Syntax</h1>
      <p>The try except syntax is used when we want to catch exceptions.</p>
      {code(['try:', '\tprint(1/0)', 'except:', '\tprint("Error")'])}
      <p>
        Normally, when we divide by zero, we get a `ZeroDivisionError`. However,
        with the try except syntax, we can catch the error and continue code
        execution as per normal.
      </p>
      <p>
        There are also many other errors which we can catch. For example, we can
        catch a `ValueError` when we try to convert a string to an integer.
        <br />
        In the next example, we will be taking a look at this.
      </p>
    </div>
  ),
});

const TryExceptCode = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Try Except Example</h1>
      <p>
        In this coding question, we will be taking a look at a use case for try
        except.
        <br />
        Write a program that fulfills the following requirements:
      </p>
      <ol>
        <li>Take in 2 integers from the user</li>
        <li>Divide the first number by the second number</li>
        <li>Print the result of the division</li>
        <li>If there are any errors encountered, print "An error occurred"</li>
      </ol>
      <p>
        <b>Note:</b> You cannot assume that the user will input integers.
      </p>
      <p>An example input will be</p>
      <pre>{'1\n1\n'}</pre>
    </div>
  ),
  onStep: blockModeThunk,
  testCases: [
    createTestCase('-1\n1\n', '-1.0\n'),
    createTestCase('1\n1\n', '1.0\n'),
    createTestCase('0\n2\n', '0.0\n'),
    createTestCase('0\n0\n', 'An error occurred\n'),
    createTestCase('1\n0\n', 'An error occurred\n'),
    createTestCase('a\nb\n', 'An error occurred\n'),
    createTestCase('1\na\n', 'An error occurred\n'),
    createTestCase('a\n1\n', 'An error occurred\n'),
  ],
});

const CatchSpecificErrors = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Catching Specific Errors</h1>
      <p>
        Now that we know how to catch errors, let's learn how to catch specific
        errors.
      </p>
      <p>
        For example, we can catch a `ValueError` when we try to convert a string
        to an integer.
      </p>
      <p>Let's take a look at an example.</p>
      {code([
        'try:',
        '\tprint(int("a"))',
        'except ValueError:',
        '\tprint("Error")',
      ])}
      <p>
        The code snippet above will print `Error` as 'a' cannot be converted to
        an integer.
      </p>
    </div>
  ),
});

const CatchSpecificErrors2 = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Catching Specific Errors</h1>
      {code([
        '# Modified from last page',
        'try:',
        '\tprint(1/0) # Changed',
        'except ValueError:',
        '\tprint("Error")',
      ])}
      <p>However, when running this code snippet, we will see this error:</p>
      {markdown('```python\n...\nZeroDivisionError: division by zero\n```')}
      <p>
        This happens as the except only catches `ValueError` and not
        `ZeroDivisionError`
      </p>
    </div>
  ),
});

const CatchSpecificErrorsCode = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Catching Specific Errors</h1>
      <p>
        Let's modify the code from before. With slightly tweaked requirements.
      </p>
      <p>The change are as follows:</p>
      <ol>
        <li>print `cannot divide by 0` if the error is `ZeroDivisionError`</li>
        <li>print `invalid number` if the error is a `ValueError`</li>
      </ol>
    </div>
  ),
  onStep: blockModeThunk,
  testCases: [
    createTestCase('1\n1\n', '1.0\n'),
    createTestCase('-1\n1\n', '-1.0\n'),
    createTestCase('0\n2\n', '0.0\n'),
    createTestCase('0\n0\n', 'cannot divide by 0\n'),
    createTestCase('1\n0\n', 'cannot divide by 0\n'),
    createTestCase('a\nb\n', 'invalid number\n'),
    createTestCase('1\na\n', 'invalid number\n'),
    createTestCase('a\n1\n', 'invalid number\n'),
  ],
  resetCode:
    'try:\n    no1=input()\n    no2=input()\n    print(int(no1)/int(no2))\nexcept:\n    print("An Error Occurred")\n',
});

const ExceptionElse = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Else</h1>
      <p>
        The else clause is used when we want to execute code when no errors are
        throw.
      </p>
      <p>Let's take a look at an example.</p>
      {code([
        'try:',
        '\tprint(1/1)',
        'except:',
        '\tprint("Error")',
        'else:',
        '\tprint("No error")',
      ])}
      <p>
        Running the code snippet above will print `No error` as no errors are
        thrown.
      </p>
    </div>
  ),
});

const ExceptionElseCode = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Using Else</h1>
      <p>
        Let's modify the code from before. With slightly tweaked requirements.
      </p>
      <p>The change are as follows:</p>
      <ol>
        <li>print `cannot divide by 0` if the error is `ZeroDivisionError`</li>
        <li>print `invalid number` if the error is a `ValueError`</li>
        <li>print the result and `no error` if no errors are thrown</li>
      </ol>
    </div>
  ),
  onStep: blockModeThunk,
  testCases: [
    createTestCase('1\n1\n', '1.0\nno error\n'),
    createTestCase('-1\n1\n', '-1.0\nno error\n'),
    createTestCase('0\n2\n', '0.0\nno error\n'),
    createTestCase('0\n0\n', 'cannot divide by 0\n'),
    createTestCase('1\n0\n', 'cannot divide by 0\n'),
    createTestCase('a\nb\n', 'invalid number\n'),
    createTestCase('1\na\n', 'invalid number\n'),
    createTestCase('a\n1\n', 'invalid number\n'),
  ],
  resetCode:
    'try:\n\tno1=input()\n\tno2=input()\n\tprint(int(no1)/int(no2))\nexcept ValueError:\n\tprint("invalid number")\nexcept ZeroDivisionError:\n\tprint("cannot divide by 0")\n',
});

const Finally = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Finally</h1>
      <p>
        The finally clause is used when we want to execute code regardless of
        whether an error is thrown.
        <br />
        This is usually used when there is a file / connection that is opened
        that has to be closed.
      </p>
      <p>Let's take a look at an example.</p>
      {code([
        'try:',
        '\tprint(int(input()))',
        'except:',
        '\tprint("Error")',
        'else:',
        '\tprint("No error")',
        'finally:',
        '\tprint("Done")',
      ])}
      <p>
        The code snippet above will print `Done` regardless of whether an error
        is thrown.
      </p>
    </div>
  ),
});

const FinallyCode = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Using Finally</h1>
      <p>
        Let's modify the code from before. With slightly tweaked requirements.
      </p>
      <p>The change are as follows:</p>
      <ol>
        <li>print `cannot divide by 0` if the error is `ZeroDivisionError`</li>
        <li>print `invalid number` if the error is a `ValueError`</li>
        <li>print the result and `no error` if no errors are thrown</li>
        <li>print `done` at the end after all the other prints</li>
      </ol>
    </div>
  ),
  onStep: blockModeThunk,
  testCases: [
    createTestCase('1\n1\n', '1.0\nno error\ndone\n'),
    createTestCase('-1\n1\n', '-1.0\nno error\ndone\n'),
    createTestCase('0\n2\n', '0.0\nno error\ndone\n'),
    createTestCase('0\n0\n', 'cannot divide by 0\ndone\n'),
    createTestCase('1\n0\n', 'cannot divide by 0\ndone\n'),
    createTestCase('a\nb\n', 'invalid number\ndone\n'),
    createTestCase('1\na\n', 'invalid number\ndone\n'),
    createTestCase('a\n1\n', 'invalid number\ndone\n'),
  ],
  resetCode:
    'try:\n\tno1=input()\n\tno2=input()\n\tprint(int(no1)/int(no2))\nexcept ValueError:\n\tprint("invalid number")\nexcept ZeroDivisionError:\n\tprint("cannot divide by 0")\nelse:\n\tprint("no error")\n',
});

const Conclusion = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Conclusion</h1>
      <p>In this chapter, you should have learnt:</p>
      <ul>
        <li>What exceptions are</li>
        <li>How to use try except</li>
        <li>How to use else</li>
        <li>How to use finally</li>
      </ul>
      <p>Hope you had fun and see you in the next chapter.</p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});
const steps = [
  Introduction,
  TryExceptSyntax,
  TryExceptCode,
  CatchSpecificErrors,
  CatchSpecificErrors2,
  CatchSpecificErrorsCode,
  ExceptionElse,
  ExceptionElseCode,
  Finally,
  FinallyCode,
  Conclusion,
];

export const Chapter13Exceptions = new Tutorial({
  name: 'Chapter 13: Exceptions',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter13Exceptions.addSteps(steps);
