import { getBlockFromType, NO_TYPE } from '../constants/block-types';

/**
 * Returns the JSX element for each block type.
 *
 * @param {BlockType} blockType The type of block to be rendered
 * @param {div} input1_div The element for the first input
 * @param {div} input2_div The element for the second input
 * @param {div} input3_div The element for the third input
 * @param {div} input4_div The element for the fourth input
 * @returns {JSX.Element} The block element.
 */
export const getBlock = (
  blockType,
  input1_div,
  input2_div,
  input3_div,
  input4_div,
) => {
  const block = getBlockFromType(blockType);
  if (block === NO_TYPE) {
    return (
      <div>
        <h1>Error Element not found</h1>
      </div>
    );
  }
  const inputs = { input1_div, input2_div, input3_div, input4_div };
  return block.getCodeBlock()(inputs);
};
