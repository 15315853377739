import { ElseBlock } from "../if_statements/Else";
import BlockAPI from "../BlockAPI";
import { code, markdown } from "../../components/ComponentUtils";

const EXCEPTION_ELSE = 57;

// override helpBox from else
function ExceptionElseStatementHelpbox() {
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(
                `You can use the else keyword to define a block of code to be executed if no errors were raised.
        \n In this example, the try block does not generate any error:`,
            )}
            {code([`try:`,
                `    print("Hello") # prints output`,
                `except:`,
                `    print("Something went wrong")`,
                `else:`,
                `    print("Nothing went wrong") # prints output`,
            ])}
        </div>
    );
}

export const ExceptionElseBlock = new BlockAPI(EXCEPTION_ELSE,
    ElseBlock.getRegex(),
    ElseBlock.getPlaceHolders(),
    <ExceptionElseStatementHelpbox />,
    ElseBlock.getSelectionBlock(),
    ElseBlock.getCodeBlock(),
    ElseBlock.getParsingFunction());
