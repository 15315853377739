import { closePythonTutor } from '../itemsState';
import { openImport } from '../modalState';

/**
 * Opens the import menu.
 * @returns {Function}
 */
export const openImportThunk =
  () =>
  (dispatch, getState) => {
    dispatch(closePythonTutor());
    dispatch(openImport());
  };
