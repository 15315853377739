import { NOTHING_SELECTOR } from '../constants';
import { defaultCondition, defaultOnStep } from '../Tutorial';

/**
 *
 * @param {String} selector - The CSS selector the current step
 * @param {JSX.Element} content - The content to display in the current step within the tour.
 * @param {ConditionFunction} condition - The condition to check before allowing the user to continue.
 * @param {ThunkFunction} onStep - The function to run when the step is reached. It is a Thunk Function
 * @param {Array<TestCase>} testCases - The test cases to run for the current step using createTestCase.
 * @param {String | undefined} position - The position of the tour. ['top' | 'right' | 'bottom' | 'left' | 'center' | [number, number]]
 * @param {String | undefined} resetCode - The code to reset the code editor to.
 * @param {Number | undefined} jumpOnComplete - The step to jump to when the current step is completed.
 *
 * @returns {Object} a step object for the tour.
 */
export const CreateStep = ({
  selector = NOTHING_SELECTOR,
  content = <div></div>,
  style = {},
  condition = defaultCondition,
  onStep = defaultOnStep,
  testCases = [],
  position = 'center',
  resetCode = undefined,
  jumpOnComplete = undefined,
}) => {
  return {
    selector: selector,
    content: () => content,
    style: style,

    // Custom Attributes
    condition: condition,
    testCases: testCases,
    position: position,
    resetCode:
      resetCode === undefined ? undefined : resetCode.replaceAll('\t', '    '),
    onStep: onStep,
    jumpOnComplete: jumpOnComplete,
  };
};

// Empty Step
export const EMPTY_STEP = CreateStep({
  content: (
    <div>
      <h1>Empty Step</h1>
      <p>
        This step is empty. An error has occurred, either in code or
        dynamically. Please report this to an admin
      </p>
    </div>
  ),
});
