import BlockAPI from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown, FOUR_SPACES } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const TRY_STATEMENT = 55;

// define block regex for parsing
const TRY_STATEMENT_REGEX = /try *:()()()()/;
const regexArr = [ TRY_STATEMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {

}

// define functional component for helpBox
function TryStatementHelpbox() {
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(
                `When an error occurs, or exception as we call it, Python will normally stop and generate an error message.
      \n These exceptions can be handled using the try statement:`,
            )}
            {markdown(`The try block will generate an exception, because x is not defined:`)}
            {code([`try:`,
                `    print(x) # generate an exception, because x is not defined`,
                `except:`,
                `    print("An exception occurred") # prints output`])}
            {markdown(
                `Since the try block raises an error, the except block will be executed.
                \nWithout the try block, the program will crash and raise an error:`,
            )}
            {code([`print(x) # This statement will raise an error, because x is not defined`])}
        </div>
    );
}

// define selection block
const TRY_SELECTION_BLOCK = (
    <Button
        style={{ textTransform: 'lowercase' }}
        variant="contained"
        size="small"
    >
        <code style={{ textAlign: 'left' }}>
            try:<br></br>
            {FOUR_SPACES + '[Code here]'}
            <br></br>
        </code>
    </Button>
);


// define input block
const tryInputBlock = () => {
    return (
        <div>
            <div style={{ display: 'inline-block' }}>{getTextInBox('try')}</div>
            {getString(':')}
        </div>
    );
};

function parsingFunction() {
    return `try:`;
}

export const TryBlock = new BlockAPI(TRY_STATEMENT,
    regexArr,
    PLACEHOLDERS,
    <TryStatementHelpbox />,
    TRY_SELECTION_BLOCK,
    tryInputBlock,
    parsingFunction);