import { createSlice } from '@reduxjs/toolkit';

export const messageInitialState = {
  message: '',
  isOpen: false,
};

export const messageStateSlice = createSlice({
  name: 'messageState',
  initialState: {
    ...messageInitialState,
  },

  reducers: {
    closeSnackbar: (state) => {
      return {
        ...state,
        isOpen: false,
      };
    },
    showSnackBarMessage: (state, action) => {
      if (action.payload.length > 1000)
        action.payload = action.payload.substring(0, 1000) + '...';
      let newLineCount = 0;
      for (let i = 0; i < action.payload.length; i++) {
        if (action.payload[i] == "\n") newLineCount++;
        if (newLineCount == 25) {
          action.payload = action.payload.substring(0, i) + '...';
          break;
        }
      }
      return {
        ...state,
        message: action.payload,
        isOpen: true,
      };
    },
  },
});

export const { closeSnackbar, showSnackBarMessage } = messageStateSlice.actions;
export default messageStateSlice.reducer;
