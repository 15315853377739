import Draggable from 'react-draggable';
import { Fab } from '@material-ui/core';
import './AutoIndentFab.css';
import { useSelector, useDispatch } from 'react-redux';
import { whitespaceCount } from '../utils/AstParserUtil';
import { setPythonCodeBoxContent } from '../redux/itemsState';

/**
 * The Auto-Indent FAB element.
 * @returns {JSX.Element} - The Auto-Indent FAB.
 */
function AutoIndentFab() {
  const dispatch = useDispatch();
  const pythonCodeBoxContent = useSelector(
    (state) => state.itemStates.pythonCodeBoxContent,
  );

  function indentLines(codeBoxContent) {
    const lines = codeBoxContent.split('\n');
    const startIndentations = lines.map((x) => {
      return Math.floor(whitespaceCount(x) / 4);
    });
    startIndentations[0] = 0;

    for (let i = 1; i < startIndentations.length; i++) {
      if (startIndentations[i] - startIndentations[i - 1] > 1) {
        startIndentations[i] = startIndentations[i - 1] + 1;
      }
    }

    const parsedLines = lines.map((line) => line.trimStart());
    startIndentations.forEach((indent, index) => {
      parsedLines[index] = ' '.repeat(indent * 4) + parsedLines[index];
    });
    dispatch(setPythonCodeBoxContent(parsedLines.join('\n')));
  }
  return (
    <Draggable bounds="body">
      <div className="fab">
        <Fab
          onClick={() => indentLines(pythonCodeBoxContent)}
          variant="extended"
          color="primary"
        >
          Auto-Indent
        </Fab>
      </div>
    </Draggable>
  );
}

export default AutoIndentFab;
