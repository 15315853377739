import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  faClipboard,
  faCopy,
  faDeleteLeft,
  faDownload,
  faFileImport,
  faIndent,
  faPaste,
  faRotateBack,
  faRotateForward,
  faOutdent,
  faTrash,
  faFileText,
  faAlignCenter,
  faBorderAll,
  faFileEdit,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  toggleCodeBlocks,
  undoStateHistory,
  togglePythonTutor,
  redoStateHistory,
  onPaste,
} from '../redux/itemsState';

import { clipboardThunk } from '../redux/thunks/Clipboard';
import { saveFileThunk } from '../redux/thunks/SaveFile';
import { openImportThunk } from '../redux/thunks/OpenImport';
import { onClearThunk } from '../redux/thunks/onClear';
import { showIndentThunk } from '../redux/thunks/ShowIndentation';
import { editorThunk } from '../redux/thunks/EditorMode';
import { Tooltip } from '@material-ui/core';
import { copyThunk } from '../redux/thunks/Copy';
import {
  DEFAULT_COPIED_ELEMENT,
  DEFAULT_SELECTED_VALUE,
} from '../constants/default-values';
import { onDeleteThunk } from '../redux/thunks/onDelete';
import { onDeleteBlockThunk } from '../redux/thunks/onDeleteBlock';
import { indentThunk, unindentThunk } from '../redux/thunks/Indent';
import { toFreeTextThunk } from '../redux/thunks/toFreeText';

const EMPTY_FUNCTION = () => {};

/**
 * Returns a button for the toolbar.
 * @param {string} name - The name of the button to display on hover
 * @param {boolean} isActive - Whether the button is active or not, false by default
 * @param {function} onClick - The function to call when the button is clicked,  () => {} by default
 * @param {bool} isDisabled - Whether the button is disabled or not, false by default
 * @param {string} value - The value of the button when selected in a toggle button group, '' by default
 * @param {object} icon - The icon to display on the button
 * @returns {object} - The toolbar button
 */
export const ToolbarBtn = ({
  name,
  isActive = false,
  onClick = EMPTY_FUNCTION,
  isDisabled = false,
  value = '',
  icon,
}) => {
  return (
    <Tooltip title={name}>
      <span>
        <ToggleButton
          data-testid={`test-${name}`}
          onClick={onClick}
          selected={isActive}
          disabled={isDisabled}
          value={value}
        >
          <FontAwesomeIcon
            icon={icon}
            style={{
              marginRight: '5px',
            }}
          />
        </ToggleButton>
      </span>
    </Tooltip>
  );
};

/**
 * The toolbar for visual python.
 * @returns {object} - The toolbar
 */
export const Toolbar = () => {
  const dispatch = useDispatch();
  const store = useStore();
  const isBorderActive = useSelector((state) => state.itemStates.showBoxes);
  const showIndent = useSelector((state) => state.itemStates.showIndent);
  const isCodeInterface = useSelector(
    (state) => state.itemStates.showPythonInterface,
  );
  const isPythonTutor = useSelector(
    (state) => state.itemStates.isPythonTutorOn,
  );
  const selected = useSelector((state) => state.itemStates.selected);
  const copiedElem = useSelector((state) => state.itemStates.copiedElement);
  const hasSelectedElement = selected !== DEFAULT_SELECTED_VALUE;
  const disableSelectionElems = !hasSelectedElement || isCodeInterface;
  const toggleGroupStyle = {
    display: isPythonTutor ? 'none' : 'flex',
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <ToggleButtonGroup size="small" style={toggleGroupStyle}>
        <ToolbarBtn
          name="Undo (Ctrl + Z)"
          onClick={() => {
            if (isPythonTutor) dispatch(togglePythonTutor());
            dispatch(undoStateHistory());
          }}
          icon={faRotateBack}
        />
        <ToolbarBtn
          name="Redo (Ctrl + Y)"
          onClick={() => {
            if (isPythonTutor) dispatch(togglePythonTutor());
            dispatch(redoStateHistory());
          }}
          icon={faRotateForward}
        />
        <ToolbarBtn
          name="Copy Element (Ctrl + C)"
          onClick={() => store.dispatch(copyThunk())}
          icon={faCopy}
          isDisabled={disableSelectionElems}
        />
        <ToolbarBtn
          name="Paste Element (Ctrl + V)"
          onClick={() => dispatch(onPaste())}
          icon={faPaste}
          isDisabled={copiedElem === DEFAULT_COPIED_ELEMENT || isCodeInterface}
        />
        <ToolbarBtn
          name="Delete Row (Del)"
          onClick={() => store.dispatch(onDeleteThunk())}
          icon={faDeleteLeft}
          isDisabled={disableSelectionElems}
        />
        <ToolbarBtn
          name="Delete Block"
          onClick={() => store.dispatch(onDeleteBlockThunk())}
          icon={faTrash}
          isDisabled={disableSelectionElems}
        />
        <ToolbarBtn
          name="Unindent (Shift + Tab)"
          onClick={() => store.dispatch(unindentThunk())}
          icon={faOutdent}
          isDisabled={disableSelectionElems}
        />
        <ToolbarBtn
          name="Indent (Tab)"
          onClick={() => store.dispatch(indentThunk())}
          icon={faIndent}
          isDisabled={disableSelectionElems}
        />
        <ToolbarBtn
          name="Convert to Text Block"
          onClick={() => store.dispatch(toFreeTextThunk())}
          icon={faFileText}
          isDisabled={disableSelectionElems}
        />
        <ToolbarBtn
          name="Clear (Ctrl + Del)"
          onClick={() => {
            if (isPythonTutor) dispatch(togglePythonTutor());
            store.dispatch(onClearThunk());
          }}
          icon={faTrashAlt}
        />
      </ToggleButtonGroup>
      <ToggleButtonGroup size="small" style={toggleGroupStyle}>
        <ToolbarBtn
          name="Show Blocks (Ctrl + B)"
          isActive={isBorderActive}
          onClick={() => {
            dispatch(toggleCodeBlocks());
          }}
          isDisabled={isCodeInterface}
          icon={faBorderAll}
        />
        <ToolbarBtn
          name="Show Indent (Ctrl + I)"
          isActive={showIndent}
          onClick={() => store.dispatch(showIndentThunk())}
          isDisabled={isCodeInterface}
          icon={faAlignCenter}
        />
        <ToolbarBtn
          name="Code Editor Mode (Ctrl + P)"
          isActive={isCodeInterface}
          onClick={() => store.dispatch(editorThunk())}
          icon={faFileEdit}
        />
      </ToggleButtonGroup>
      <ToggleButtonGroup size="small" style={toggleGroupStyle}>
        <ToolbarBtn
          name="Upload (Ctrl + O)"
          onClick={() => store.dispatch(openImportThunk())}
          icon={faFileImport}
        />
        <ToolbarBtn
          name="Copy to Clipboard (Ctrl + Shift + C)"
          onClick={() => store.dispatch(clipboardThunk())}
          icon={faClipboard}
        />
        <ToolbarBtn
          name="Download code (Ctrl + S)"
          onClick={() => store.dispatch(saveFileThunk())}
          icon={faDownload}
        />
      </ToggleButtonGroup>
    </div>
  );
};
