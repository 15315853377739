import BlockAPI from "../BlockAPI";
import { THREE_VW_SIZE, code, getAssignmentStatements, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const EXPONENT = 9;

// define block regex for parsing
const EXPONENT_REGEX = /(.*)\*\*=(.*)()()/;
const regexArr = [ EXPONENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'i',
  input2: '2',
  operator: '**=',
}

// define functional component for helpBox
function ExponentHelpbox() {
  const lines = [
    `a = 2 # initializes variable a to the value 2`,
    `a **= 5 # 2 raised to the power of 5 results in 32`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Calculate exponent(raise power) value using operands and assign value to left operand`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const EXPONENT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {THREE_VW_SIZE} **= {THREE_VW_SIZE}
    </code>
  </Button>
);

function parsingFunction(input1, input2) {
  return `${input1} **= ${input2}`;
}

export const ExponentBlock = new BlockAPI(EXPONENT, regexArr, PLACEHOLDERS, <ExponentHelpbox />, EXPONENT_SELECTION_BLOCK, getAssignmentStatements('**='), parsingFunction);