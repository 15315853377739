import BlockAPI from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown, FOUR_SPACES } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const FINALLY_STATEMENT = 58;

// define block regex for parsing
const FINALLY_STATEMENT_REGEX = /finally *:()()()()/;
const regexArr = [ FINALLY_STATEMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {

}

// define functional component for helpBox
function FinallyStatementHelpbox() {
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(
                `When an error occurs, or exception as we call it, Python will normally stop and generate an error message.
      \n These exceptions can be handled using the try statement:`,
            )}
            {markdown(`The finally block, if specified, will be executed regardless if the try block raises an error or not.`)}
            {code([`try:`,
                `    print(x) # generates an error`,
                `except:`,
                `    print("Something went wrong") # prints output`,
                `finally:`,
                `    print("The 'try except' is finished") # prints output`],
            )}
        </div>
    );
}

// define selection block
const FINALLY_SELECTION_BLOCK = (
    <Button
        style={{ textTransform: 'lowercase' }}
        variant="contained"
        size="small"
    >
        <code style={{ textAlign: 'left' }}>
            finally:<br></br>
            {FOUR_SPACES + '[Code here]'}
            <br></br>
        </code>
    </Button>
);


// define input block
const finallyInputBlock = () => {
    return (
        <div>
            <div style={{ display: 'inline-block' }}>{getTextInBox('finally')}</div>
            {getString(':')}
        </div>
    );
};

function parsingFunction() {
    return `finally:`;
}

export const FinallyBlock = new BlockAPI(FINALLY_STATEMENT,
    regexArr,
    PLACEHOLDERS,
    <FinallyStatementHelpbox />,
    FINALLY_SELECTION_BLOCK,
    finallyInputBlock,
    parsingFunction);