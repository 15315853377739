import { getFileOutput, parseTree } from '../../utils/Parsing';
import { showSnackBarMessage } from '../messageState';

/**
 * Copies the current code to clipboard as text.
 * @returns {Function}
 */
export const clipboardThunk = () => (dispatch, getState) => {
  const state = getState();
  const items = state.itemStates.items;
  navigator.clipboard.writeText(
    getFileOutput(
      parseTree({
        items: items,
      }),
    ),
  );
  dispatch(showSnackBarMessage('Copied to clipboard.'));
};
