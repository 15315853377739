import BlockAPI from "../BlockAPI";
import { THREE_VW_SIZE, code, getAssignmentStatements, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const DECREMENT = 6;

// define block regex for parsing
const DECREMENT_REGEX = /(.*)\-=(.*)()()/;;
const regexArr = [ DECREMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'i',
  input2: '1',
  operator: '-=',
}

// define functional component for helpBox
function DecrementHelpbox() {
  const lines = [
    `a = 10 # initializes variable a to the value 10`,
    `a -= 5 # subtracts 5 to variable a`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Subtract right operand from left operand and then assign to left operand`,
      )}
      {code(lines)}
    </div>
  );

}

// define selection block
const DECREMENT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {THREE_VW_SIZE} -= {THREE_VW_SIZE}
    </code>
  </Button>
);

function parsingFunction(input1, input2) {
  return `${input1} -= ${input2}`;
}

export const DecrementBlock = new BlockAPI(DECREMENT, regexArr, PLACEHOLDERS, <DecrementHelpbox />, DECREMENT_SELECTION_BLOCK, getAssignmentStatements('-='), parsingFunction);