import BlockAPI from '../BlockAPI';
import { code, getTextInBox, markdown } from '../../components/ComponentUtils';
import { Button } from '@material-ui/core';

// define id number
const CONTINUE = 19;

// define block regex for parsing
const CONTINUE_REGEX = /continue()()()()/;
const regexArr = { 0: CONTINUE_REGEX };

// define placeholders when adding blocks
const PLACEHOLDERS = {};

// define functional component for helpBox
function ContinueHelpbox() {
  const lines = [
    `lst = [4, 9, 9, 7]`,
    `target = 9`,
    `for i in range(len(lst)):`,
    `    if lst[i] == target:`,
    `        continue`,
    `    print(lst[i])`,
    `# output: 4 and 7`,
    `# when lst[i] == target, continue is executed,`,
    `# the loop will skip the rest of the statements and go to the next iteration`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `**continue** is used to terminate the **current iteration** of the loop and proceed directly to the next iteration.`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const CONTINUE_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase ' }}
    variant="contained"
    size="small"
  >
    <code style={{ display: 'inline-block' }}>continue</code>
  </Button>
);

// define input block
const continueInputBlock = () => {
  return <div>{getTextInBox('continue')}</div>;
};

function parsingFunction() {
  return `continue`;
}

export const ContinueBlock = new BlockAPI(
  CONTINUE,
  regexArr,
  PLACEHOLDERS,
  <ContinueHelpbox />,
  CONTINUE_SELECTION_BLOCK,
  continueInputBlock,
  parsingFunction,
);
