import {
  NOTHING_SELECTOR,
  PYTHON_TUTOR_SELECTOR,
  CODING_SELECTOR,
  RUN_CODE_SELECTOR,
} from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { onClearThunk } from '../../redux/thunks/onClear';
import { AssignmentBlock } from '../../blocks/assignment_statements/Assignment';
import { createTestCase } from '../utils/HandleCodeExecution';
import { PrintBlock } from '../../blocks/io_types/Print';
import TutorialRating from '../components/TutorialRating';
import { blockModeThunk } from '../../redux/thunks/BlockMode';
import { pythonTutorModeThunk } from '../../redux/thunks/PythonTutorMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 4: Python Variables</h1>
      <h2>At the end of this chapter, you will learn more about:</h2>
      <ol>
        <li>Python Variables</li>
        <li>How to assign/create a variable</li>
        <li>Modifying a variable</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const IntroToVariables = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Python Variables</h2>
      <p>Variables are containers for storing data values.</p>

      <h2>Creating Variables</h2>
      <p>Python has no command for declaring a variable.</p>
      <p>A variable is created the moment you first assign a value to it.</p>
    </div>
  ),
  onStep: blockModeThunk,
});

const CreatingAVariable = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Let's start by creating a variable!</h1>
      {code(['x = 1'])}
      <p>Steps:</p>
      <ol>
        <li>Hover over the assignments selection menu</li>
        <li>
          Click on the <code>=</code> statement
        </li>
        <li>It should appear in the code box</li>
        <li>Type in the required fields</li>
      </ol>
    </div>
  ),
  condition: ({ items }) =>
    items.some((item) => {
      const inputField1 = item.input1 ?? '';
      const inputField2 = item.input2 ?? '';
      return (
        item.type === AssignmentBlock.getType() &&
        inputField1.trim() === 'x' &&
        inputField2.trim() === '1'
      );
    }),
  onStep: blockModeThunk,
  resetCode: '',
});

const AddingPrintStatement = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Now let's print its output!</h1>
      {code(['x = 1\nprint(x)'])}
      <p>Steps:</p>
      <ol>
        <li>Hover over the IO selection menu</li>
        <li>
          Drag the <code>print</code> statement under the assignment block
        </li>
        <li>It should appear in the code box</li>
        <li>
          Type in <i>x</i> in the input field
        </li>
        <li>Submit your code!</li>
      </ol>
    </div>
  ),
  condition: ({ items }) =>
    items.some((item) => {
      const inputField1 = item.input1 ?? '';
      return item.type === PrintBlock.getType() && inputField1.trim() === 'x';
    }),
  onStep: blockModeThunk,
  testCases: [createTestCase('', '1\n')],
  resetCode: 'x = 1',
});

const RunCode = CreateStep({
  selector: RUN_CODE_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Let's run our code!</h1>
      <p>Click on the Run Code button in the navigation bar to run the code!</p>
    </div>
  ),
  condition: ({ isPythonTutorOn }) => isPythonTutorOn,
  jumpOnComplete: 5,
});

const StepThroughCode = CreateStep({
  selector: PYTHON_TUTOR_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Using Python Tutor</h1>
      <p>
        If we step through our code, we can see that variable x holds the value
        1
      </p>
    </div>
  ),
  onStep: pythonTutorModeThunk,
  position: 'bottom',
});

const GoBackToCode = CreateStep({
  selector: RUN_CODE_SELECTOR,
  content: (
    <div>
      <h1>Go back to code</h1>
      <p>
        To go back to editing your code, click on the edit code button in the
        navigation bar!
      </p>
    </div>
  ),
  position: 'bottom',
  condition: ({ isPythonTutorOn }) => !isPythonTutorOn,
  onStep: pythonTutorModeThunk,
  jumpOnComplete: 7,
});

const ModifyingVariable = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Now let us change the value of the variable!</h1>
      {code(['x = 1\nprint(x)\nx = 2\nprint(x)'])}
      <p>The first line assigns the value 1 to x.</p>
      <p>
        The third line then assigns a new value 2 to x. Lastly it prints out the
        output of x which is 2.
      </p>
      <p>Steps:</p>
      <ol>
        <li>Hover over the assignments selection menu</li>
        <li>
          Drag the <code>=</code> statement under the print statement
        </li>
        <li>Type in the required fields to override x with the value 2.</li>
        <li>Add in another print statement below to print out x again.</li>
      </ol>
      <p>
        <b>Note: x has to be reassigned and printed to pass this test case</b>
      </p>
    </div>
  ),
  condition: ({ items }) => {
    const hasPrint = items.some((item) => {
      const inputField1 = item.input1 ?? '';
      return item.type === PrintBlock.getType() && inputField1.trim() === 'x';
    });
    const hasVariableXEqual1 = items.some((item) => {
      const inputField1 = item.input1 ?? '';
      const inputField2 = item.input2 ?? '';
      return (
        item.type === AssignmentBlock.getType() &&
        inputField1.trim() === 'x' &&
        inputField2.trim() === '1'
      );
    });
    const hasVariableXEqual2 = items.some((item) => {
      const inputField1 = item.input1 ?? '';
      const inputField2 = item.input2 ?? '';
      return (
        item.type === AssignmentBlock.getType() &&
        inputField1.trim() === 'x' &&
        inputField2.trim() === '2'
      );
    });
    return hasPrint && hasVariableXEqual1 && hasVariableXEqual2;
  },
  onStep: blockModeThunk,
  testCases: [createTestCase('', '1\n2\n')],
  resetCode: 'x = 1\nprint(x)',
});

const CongratulationsMessage = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Congratulations!</h1>
      <p>Congratulations! You have completed this tutorial!</p>
      <p>
        Join us in the next tutorial to learn how to do more cool things in
        python!
      </p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  IntroToVariables,
  CreatingAVariable,
  AddingPrintStatement,
  RunCode,
  StepThroughCode,
  GoBackToCode,
  ModifyingVariable,
  CongratulationsMessage,
];

export const Chapter4Variables = new Tutorial({
  name: 'Chapter 4: Variables',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter4Variables.addSteps(steps);
