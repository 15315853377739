import { useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Modal, Box, Button, FormGroup, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openRegister } from '../redux/modalState';
import { LOGIN } from '../constants/modal-type';
import { login } from '../api/send';
import { validateFilled } from '../utils/Validator';
import { MESSAGE_KEY } from '../api/constants/constants';
import { setDisplayName, setLoggedIn } from '../redux/authState';
import { showSnackBarMessage } from '../redux/messageState';
import { clearAllSetter, setEmptyIfError } from '../utils/ModalUtil';
import { IconButton } from '@material-ui/core';

const style = {
  position: 'absolute',
  overflow: 'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

/**
 * The login modal.
 * @returns {JSX.Element} The login modal.
 */
function LoginModal() {
  const [username, setUsername] = useState('');
  const [usernameErrorMsg, setUsernameErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');

  const usernameInputRef = useRef();
  const passwordInputRef = useRef();

  const openedModal = useSelector((state) => state.openedModal.openedModal);
  const dispatch = useDispatch();

  const submitHandler = async (event) => {
    event.preventDefault();

    const enteredUsername = usernameInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    const usernameError = setEmptyIfError(
      !validateFilled(enteredUsername),
      () => setUsernameErrorMsg('Username cannot be empty'),
      () => setUsernameErrorMsg(''),
    );
    const passwordError = setEmptyIfError(
      !validateFilled(enteredPassword),
      () => setPasswordErrorMsg('Password cannot be empty'),
      () => setPasswordErrorMsg(''),
    );

    if (usernameError || passwordError) return;

    try {
      const response = await login(enteredUsername, enteredPassword);
      if (response.status !== 200) {
        const data = response.json();
        return setUsernameErrorMsg(data[MESSAGE_KEY]);
      }

      // Login success
      dispatch(showSnackBarMessage('Login success!'));
      dispatch(setDisplayName(enteredUsername));
      dispatch(setLoggedIn(true));
      dispatch(closeModal());
      clearAllSetter(setUsername, setPassword);
    } catch (error) {
      try {
        const result = error.response.data[MESSAGE_KEY];
        clearAllSetter(setUsername, setPassword);
        setPasswordErrorMsg(result);
        setUsernameErrorMsg(result);
      } catch (e) {
        dispatch(showSnackBarMessage('Login failed! Please try again later.'));
      }
    }
  };
  return (
    <Modal open={openedModal === LOGIN} onClose={() => dispatch(closeModal())}>
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Login
          </Typography>
          <IconButton
            size="small"
            data-testid="close-btn"
            onClick={() => dispatch(closeModal())}
          >
            <i className="fa fa-close" />
          </IconButton>
        </div>
        <Typography
          component={'span'}
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
          <FormGroup className="mb-3">
            <TextField
              id="outlined-required-username"
              label="Username"
              variant="outlined"
              inputRef={usernameInputRef}
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              error={usernameErrorMsg !== ''}
              helperText={usernameErrorMsg}
            />
          </FormGroup>
          <br />
          <FormGroup className="mb-3">
            <TextField
              id="outlined-required-password"
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              inputRef={passwordInputRef}
              error={passwordErrorMsg != ''}
              helperText={
                validateFilled(password)
                  ? passwordErrorMsg
                  : 'Please fill in your password!'
              }
            />
          </FormGroup>
          <Button
            data-testid="login-btn"
            variant="contained"
            onClick={(e) => submitHandler(e)}
          >
            Login
          </Button>
          <Button
            data-testid="register-btn"
            variant="outlined"
            onClick={() => dispatch(openRegister())}
            style={{
              marginLeft: '10px',
            }}>
            Register Here
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
}

export default LoginModal;
