/**
 *  Takes in a single line of input(string) and a regular expression, 
 * returns the matched string (if any) or null
 * 
 * @param {string} text The line of text to be checked
 * @returns {string} The regular expression search pattern
 */
export const checkMatchingRegex = (line, regex) => {
  return line.match(regex) != null ? line.match(regex)[0] : null;
};

/**
 *  Takes in a single line of input(string) and returns the number of indentations
 * for lines that starts with comment character, set indentation to be zero so that comment positions are preserved
 *
 * @param {string} text The line of text to be checked
 * @returns {number} The number of indentations | -1 for incorrect indentation levels
 */
export const whitespaceCount = (text) => {
  let numOfSpaces = 0;
  for (let i = 0; i < text.length; i++) {
    if (text[i] != ' ') break;
    numOfSpaces++;
  }
  return numOfSpaces;
};

/**
 * Returns true if the line is a comment.
 * @param {String} line The line to be processed
 * @returns {Boolean} True if the line is a comment, false otherwise.
 */
export const isLineComment = (line) => {
  return line.trimStart().startsWith('#');
};

/**
 * Returns true if the text is not empty.
 * @param {String} text The text to be processed.
 * @returns {Boolean}
 */
export const isNotEmpty = (text) => {
  return text.trim().length > 0;
};
