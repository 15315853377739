import { NO_TYPE } from '../../constants/block-types';
import { IfBlock } from '../../blocks/if_statements/If';
import { ElifBlock } from '../../blocks/if_statements/Elif';
import { ElseBlock } from '../../blocks/if_statements/Else';
import { ForLoopBlock } from '../../blocks/for_and_while_loop/ForLoop';
import { ForLoopElementBlock } from '../../blocks/for_and_while_loop/ForLoopElement';
import { WhileBlock } from '../../blocks/for_and_while_loop/While';
import { FunctionDeclarationBlock } from '../../blocks/functions/FunctionDeclaration';
import { ExceptBlock } from '../../blocks/Exception_handling/Except';
import { TryBlock } from '../../blocks/Exception_handling/try';
import { FinallyBlock } from '../../blocks/Exception_handling/finally';
import { ExceptionElseBlock } from '../../blocks/Exception_handling/ExceptionElse';
import { getPrevConsecutive, getStmtType, updateGenericBlock } from './BorderUtilHelper';

// List of colors for the borders
const possibleColors = [
  'red',
  'blue',
  'green',
  'gray',
  'black',
  'darkblue',
  'darkcyan',
  'darkgreen',
  'darkmagenta',
  'darkred',
  'darkyellow',
];

const targetClass = 'nestable-item';
const ErrorType = new Set([
  TryBlock.getType(),
  ExceptBlock.getType(),
  FinallyBlock.getType(),
]);
const CondTypes = new Set([IfBlock.getType(), ElifBlock.getType()]);
const loopTypes = new Set([
  ForLoopBlock.getType(),
  ForLoopElementBlock.getType(),
  WhileBlock.getType(),
]);

const ElseTypes = new Set([ElseBlock.getType(), ExceptionElseBlock.getType()]);
const borderApplyType = new Set([
  ...CondTypes,
  ...loopTypes,
  ...ErrorType,
  ...ElseTypes,
  FunctionDeclarationBlock.getType(),
]);

// Exported for testing.
export const DOTTED_CLASSNAME = 'dotted-border';

/**
 * Removes all border styles from items.
 * @returns {void}
 */
const removeBorderStyle = () => {
  const blocks = document.getElementsByClassName(targetClass);
  for (let i = 0; i < blocks.length; ++i) {
    blocks[i].style.border = '';
    blocks[i].style.borderRadius = '';
    blocks[i].style.listStylePosition = '';
  }
};

/**
 * Updates the border type of an elif block.
 * @param {JSX.element} currentBlock The currently processed block.
 * @param {JSX.element} prevBlock The previously processed block.
 * @param {Object} borderStyle The currently border style to be applied.
 * @param {Object} prevBorderStyle The border style of the previous object.
 * @returns {void}
 */
export const updateElif = (currentBlock, prevBlock, borderStyle, prevBorderStyle) => {
  const prevType = getStmtType(prevBlock);
  if (!CondTypes.has(prevType)) {
    return updateGenericBlock(
      currentBlock,
      prevBlock,
      borderStyle,
      prevBorderStyle,
    );
  }
  updateGenericBlock(currentBlock, prevBlock, prevBorderStyle, prevBorderStyle);

  currentBlock.style.borderTop = 'none';
  prevBlock.style.borderBottom = 'none';
  currentBlock.style.marginTop = '0px';
};

/**
 * Updates the border style of an Except block.
 * @param {JSX.Element} currentBlock the currently processed element
 * @param {JSX.Element} prevBlock The previously processed element
 * @param {Object} borderStyle The border style of the current object
 * @param {Object} prevBorderStyle The border style of the previous object
 * @returns {void}
 */
export const updateExcept = (
  currentBlock,
  prevBlock,
  borderStyle,
  prevBorderStyle,
) => {
  const prevType = getStmtType(prevBlock);
  if (prevType !== TryBlock.getType()) {
    return updateGenericBlock(
      currentBlock,
      prevBlock,
      borderStyle,
      prevBorderStyle,
    );
  }
  updateGenericBlock(currentBlock, prevBlock, prevBorderStyle, prevBorderStyle);

  currentBlock.style.borderTop = 'none';
  prevBlock.style.borderBottom = 'none';
  currentBlock.style.marginTop = '0px';
  return;
};

const updateFinallyBlockSet = new Set([
  TryBlock.getType(),
  ExceptBlock.getType(),
  ElseBlock.getType(),
  ExceptionElseBlock.getType(),
]);

/**
 * Updates the border style of a finally block.
 * @param {JSX.Element} currentBlock The currently processed block
 * @param {JSX.Element} prevBlock The previously processed block
 * @param {Object} borderStyle The style of the current block
 * @param {Object} prevBorderStyle The style of the previous block
 * @returns {void}
 */
export const updateFinally = (
  currentBlock,
  prevBlock,
  borderStyle,
  prevBorderStyle,
) => {
  const prevType = getStmtType(prevBlock);
  if (
    !updateFinallyBlockSet.has(prevType) ||
    (ElseTypes.has(prevType) &&
      getStmtType(getPrevConsecutive(prevBlock)) !== ExceptBlock.getType())
  ) {
    return updateGenericBlock(
      currentBlock,
      prevBlock,
      borderStyle,
      prevBorderStyle,
    );
  }
  updateGenericBlock(currentBlock, prevBlock, prevBorderStyle, prevBorderStyle);
  currentBlock.style.borderTop = 'none';
  prevBlock.style.borderBottom = 'none';
  currentBlock.style.marginTop = '0px';
  return;
};

/**
 * Updates the border style of an else block.
 * @param {JSX.Element} currentBlock The currently processed block
 * @param {JSX.Element} prevBlock The previously processed block
 * @param {Object} borderStyle The style of the current block
 * @param {Object} prevBorderStyle The style of the previous block
 * @returns {void}
 */
export const updateElse = (currentBlock, prevBlock, borderStyle, prevBorderStyle) => {
  const prevType = getStmtType(prevBlock);

  if (
    !CondTypes.has(prevType) &&
    !loopTypes.has(prevType) &&
    prevType !== ExceptBlock.getType()
  ) {
    return updateGenericBlock(
      currentBlock,
      prevBlock,
      borderStyle,
      prevBorderStyle,
    );
  }
  updateGenericBlock(currentBlock, prevBlock, prevBorderStyle, prevBorderStyle);
  prevBlock.style.borderBottom = 'none';
  currentBlock.style.borderTop = 'none';
  currentBlock.style.marginTop = '0px';
};

/**
 * Apply border styles to all blocks.
 * @param {Boolean} showBoxes Whether or not to show the boxes
 * @returns {void}
 */
export const applyBorderBlocks = (showBoxes) => {
  removeBorderStyle();
  if (!showBoxes) return;
  const blocks = document.getElementsByClassName(targetClass);

  for (let i = 0; i < blocks.length; ++i) {
    const color = possibleColors[i % possibleColors.length];
    const currBlock = blocks[i];
    const prevBlock = getPrevConsecutive(currBlock);
    updateBorderStyle(currBlock, color, prevBlock);
  }
};

const borderFuncDict = {
  // Elif blocks
  [ElifBlock.getType()]: updateElif,
  // Else blocks
  [ElseBlock.getType()]: updateElse,

  // Exception Blocks
  [ExceptionElseBlock.getType()]: updateElse,
  [ExceptBlock.getType()]: updateExcept,
  [FinallyBlock.getType()]: updateFinally,
};

/**
 * Removes the dotted border from currently applied element.
 * @returns {void}
 */
export const removeDottedBorder = () => {
  const elements = document.getElementsByClassName(DOTTED_CLASSNAME);
  for (let i = 0; i < elements.length; ++i) {
    elements[i].classList.remove(DOTTED_CLASSNAME);
  }
};

/**
 * Highlights the id of the object in a dotted line.
 * @param {int} id Id of the copied element.
 */
export const applyDottedBorder = (id) => {
  removeDottedBorder();
  const element =
    document.getElementById(id)?.parentElement?.parentElement?.parentElement;
  if (element === null || element === undefined) return;
  element.classList.add(DOTTED_CLASSNAME);
};

/**
 * Highlights the border of the current block based on the previous block.
 * @param {JSX.Element} currentBlock
 * @param {Color} borderColor
 * @param {JSX.Element} prevBlock
 * @returns
 */
const updateBorderStyle = (currentBlock, borderColor, prevBlock) => {
  const currType = getStmtType(currentBlock);
  if (!borderApplyType.has(currType)) {
    return;
  }
  const borderStyle = `2px solid ${borderColor}`;
  const prevBorderStyle = prevBlock?.style?.borderLeft;
  currentBlock.style.listStylePosition = 'inside';
  currentBlock.style.borderRadius = '2';

  const func = borderFuncDict[currType];
  if (func) {
    return func(currentBlock, prevBlock, borderStyle, prevBorderStyle);
  }
  return updateGenericBlock(
    currentBlock,
    prevBlock,
    borderStyle,
    prevBorderStyle,
  );
};
