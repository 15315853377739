import { useDispatch, useSelector } from 'react-redux';
import { closeSnackbar } from '../redux/messageState';
import { IconButton, Snackbar } from '@material-ui/core';


/**
 * Message Bar for Visual Python.
 *
 * @param {Object} anchorOrigin - anchor origin for the message bar
 * @param {Boolean} open - whether the message bar is open
 * @param {Number} autoHideDuration - duration for the message to show
 * @returns {JSX.Element} - Message Bar
 */
export const MessageBar = ({
  anchorOrigin = null,
  autoHideDuration = 5000,
}) => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.messageState.message);
  const open = useSelector((state) => state.messageState.isOpen);

  const closeBtn = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => dispatch(closeSnackbar())}
    >
      <i className="fa fa-close" /></IconButton>
  )

  return (
    <Snackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={() => dispatch(closeSnackbar())}
      message={message}
      action={closeBtn}
      style={{ whiteSpace: 'pre-line' }}
    />
  );
};
