import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const COMMENT = 35;

// define block regex for parsing
const COMMENT_REGEX = /\#(.*)()()()/;
const regexArr = [ COMMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
    input1: 'comment here',
}

// define functional component for helpBox
function CommentHelpbox() {
    const lines = [`# this is a comment`, `# this is another comment`];
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(
                `Starting a line with **#** to write a comment in Python. Comments will not be executed.`,
            )}
            {code(lines)}
        </div>
    );
}

// define selection block
const COMMENT_SELECTION_BLOCK = (
    <Button
        style={{ textTransform: 'lowercase ' }}
        variant="contained"
        size="small"
    >
        <code style={{ display: 'inline-block', fontFamily: 'monospace' }}>
            #&nbsp;
        </code>
        <code style={{ display: 'inline-block' }}>{TWO_VW_SIZE}</code>
    </Button>
);

// define input block
const commentInputBlock = ({ input1_div }) => {
    return (
        <div>
            {getTextInBox('#')}
            {input1_div}
        </div>
    )
}

function parsingFunction(input1) {
    return `# ${input1}`;
}

export const CommentBlock = new BlockAPI(COMMENT,
    regexArr,
    PLACEHOLDERS,
    <CommentHelpbox />,
    COMMENT_SELECTION_BLOCK,
    commentInputBlock,
    parsingFunction);