// General Selectors
export const EVERYTHING_SELECTOR = '[data-tut="everything"]';
export const NOTHING_SELECTOR = '';
export const CODE_IDE_SELECTOR = '[data-tut="codeIDE"]';
export const HELP_BOX_SELECTOR = '[data-tut="helpbox"]';
export const SELECTION_COLUMN_SELECTOR = '[data-tut="choosingColumn"]';
export const CODING_SELECTOR = `[data-tut="coding_req"]`;
export const IDEUTILS_SELECTOR = '[data-tut="IDEUtils"]';

// Navbar elements
export const RUN_CODE_SELECTOR = '#_2iAdU > div._2trtS > ul > li:nth-child(1)';
export const TUTORIAL_SELECTOR = '#_2iAdU > div._2trtS > ul > li:nth-child(4)';
export const INFO_SELECTOR = '#_2iAdU > div._2trtS > ul > li:nth-child(6)';

// Selection Column items
export const PRINT_SELECTION_MENU =
  '#root > div > div > div:nth-child(1) > div > div.vertical-flex-container.selection-column > div > div > div:nth-child(3) > button';

// Python Tutor Selector
export const EDIT_CODE_SELECTOR = '#_2iAdU > div._2trtS > ul > li._3HDv1';
export const PYTHON_TUTOR_SELECTOR = '#pythonTutor';

// Used for retrieving error messages from backend.
export const EXTRACT_ERR_REGEX = /(\w+Error:.+)/;
export const OUTPUT_MESSAGE_SUCCESS =
  'Your output is correct! Please continue.';

// Used for handling code execution
export const ALL_TEST_CASE_PASS_MESSAGE = [
  true,
  'Output is correct! Please check if you have satisfied other requirements!',
];
