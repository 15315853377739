import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const LIST_POP = 32;

// define block regex for parsing
const LIST_POP_REGEX = /(.*) *= *(.*) *\. *pop *\( *\)()()/;
const regexArr = [ LIST_POP_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'last_element',
  input2: 'lst',
}

// define functional component for helpBox
function ListPopHelpbox() {
  const lines = [
    `lst = [1, 5, 3]`,
    `# remove the last element of the list`,
    `popped = lst.pop()`,
    `print(popped) # output: 3`,
    ``,
    `# What happens if we give an index to lst.pop()?`,
    `popped = lst.pop(1) # try it out!`,
    ``,
    `# What is the time complexity of list.pop()?`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `This method **removes** the element at the end of the list, and returns that element.`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const LIST_POP_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE} = {TWO_VW_SIZE}.pop()
    </code>
  </Button>
);

// define input block
const listPopInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {input2_div}
      {getString('.')}pop{getString('(')}
      {getString(')')}
    </div>
  );
};


function parsingFunction(input1, input2) {
  return `${input1} = ${input2}.pop()`;
}

export const ListPopBlock = new BlockAPI(LIST_POP,
  regexArr,
  PLACEHOLDERS,
  <ListPopHelpbox />,
  LIST_POP_SELECTION_BLOCK,
  listPopInputBlock,
  parsingFunction);