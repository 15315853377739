import React, { useCallback } from 'react';
import 'react-nestable/dist/styles/index.css';
import './app.css';
import 'custom-cursor-react/dist/index.css';
import 'reactjs-navbar/dist/index.css';
import './navigation-bar.css';
import CustomCursor from 'custom-cursor-react';
import { HELPBOX_BLOCK } from './components/helpbox';
import { parseTree, getFileOutput } from './utils/Parsing';
import {
  saveToLocalStorage,
  loadFromLocalStorage,
} from './storage/localStorage';
import { PythonTutorDiv } from './components/PythonTutor';
import { BlockPythonNavbar } from './components/NavBar';
import { SelectionColumn } from './components/SelectionColumn';
import CodeBox from './components/CodeBox';
import FadeLoader from 'react-spinners/FadeLoader';
import {
  DELETE,
  Z,
  Y,
  S,
  C,
  V,
  O,
  P,
  B,
  I,
  ENTER,
  TAB,
  BACKSPACE,
} from './constants/keyboard';
import AboutModal from './components/AboutModal';
import FeedbackModal from './components/FeedbackModal';
import LoginModal from './components/LoginModal';
import ImportFileModal from './components/ImportFileModal';
import { sendKeyPress, sendReferrer } from './api/send';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-python';
import 'prismjs/themes/prism.css';
import astParser from './utils/AstParser';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { openTutorial } from './redux/modalState';
import { closeMenu, setIsFirstTime } from './redux/temporaryStates';
import {
  setItems,
  setI,
  setPythonCodeBoxContent,
  undoStateHistory,
  redoStateHistory,
  onPaste,
  toggleCodeBlocks,
  togglePythonTutor,
  toggleShowIndent,
} from './redux/itemsState';
import { showSnackBarMessage } from './redux/messageState';
import { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import RegisterModal from './components/RegisterModal';
import { setDisplayName, setLoggedIn } from './redux/authState';
import { getUserFromServer } from './api/ServerInterface';
import $ from 'jquery';
import { registerAllTutorials } from './tutorials/AllTutorials';
import { TutorialBlock } from './tutorials/TutorialBlock';
import { tutorialManager } from './tutorials/TutorialManager';
import { createTheme, ThemeProvider } from '@material-ui/core';
import KeyboardMap from './utils/KeyboardMaps';
import AutoIndentFab from './components/AutoIndentFab';
import { MessageBar } from './components/MessageBar';
import { usePromiseTracker } from 'react-promise-tracker';
import { copyThunk } from './redux/thunks/Copy';
import { editorThunk } from './redux/thunks/EditorMode';
import { onSubmitCode } from './redux/thunks/SubmitThunk';
import { openImportThunk } from './redux/thunks/OpenImport';
import { saveFileThunk } from './redux/thunks/SaveFile';
import { clipboardThunk } from './redux/thunks/Clipboard';
import { indentThunk, unindentThunk } from './redux/thunks/Indent';
import { onDeleteThunk } from './redux/thunks/onDelete';
import { EditModal } from './components/EditProfile';
import { onClearThunk } from './redux/thunks/onClear';
import { NONE } from './constants/modal-type';
import { setCurrentStep, setCurrTutorialNo } from './redux/tutorialState';
import ignoredShortcuts from './utils/Shortcut';

const theme = createTheme({
  typography: {
    fontFamily: 'Ubuntu Mono !important',
  },
});

/**
 * The homepage of the Visual Python application.
 * @returns {JSX.Element} The main app component.
 */
const Home_functional = () => {
  const store = useStore();
  const isLoggedIn = useSelector((state) => state.authState.loggedIn);
  const currModal = useSelector((state) => state.openedModal.openedModal);
  const isFirstTime = useSelector((state) => state.temporaryStates.isFirstTime);
  const isPythonTutorOn = useSelector(
    (state) => state.itemStates.isPythonTutorOn,
  );
  const showPythonInterface = useSelector(
    (state) => state.itemStates.showPythonInterface,
  );
  const items = useSelector((state) => state.itemStates.items);
  const i = useSelector((state) => state.itemStates.i);
  const pythonCodeBoxContent = useSelector(
    (state) => state.itemStates.pythonCodeBoxContent,
  );

  const hovering = useSelector((state) => state.itemStates.hovering);
  const dispatch = useDispatch();
  const { promiseInProgress } = usePromiseTracker();

  useEffect(() => {
    registerAllTutorials(tutorialManager);
    const closeMenuDispatch = () => dispatch(closeMenu());

    document.addEventListener('click', closeMenuDispatch);
    const cleanup = () => {
      document.removeEventListener('click', closeMenuDispatch);
      tutorialManager.reset();
    };
    const contents = loadFromLocalStorage();
    sendReferrer();

    if (contents.isFirstTime) {
      if (isPythonTutorOn) dispatch(togglePythonTutor());
      dispatch(setCurrTutorialNo(0));
      dispatch(setCurrentStep(0));
      dispatch(openTutorial());
      return cleanup;
    }

    const fetchUserInfo = async () => {
      try {
        const resp = await getUserFromServer();
        if (resp) {
          dispatch(setLoggedIn(true));
          dispatch(setDisplayName(resp.data.username));
        }
      } catch (err) {
        dispatch(setLoggedIn(false));
      }
    };

    fetchUserInfo().catch(() => { });
    dispatch(setItems(contents.items));
    dispatch(setI(contents.i));
    dispatch(setIsFirstTime(false));
    return cleanup;
  }, []);

  useEffect(() => {
    //small device warning
    var width = $(window).width();
    var height = $(window).height();
    if (width < 1100 || height < 675) {
      alert(
        'You are using this on a smaller screen, some of the features may not work as expected! This web-app is best used on standard monitor resolutions.',
      );
    }
  }, []);

  useEffect(() => {
    saveToLocalStorage({ items: items, i: i, isFirstTime: isFirstTime });
  }, [items, i, isFirstTime]);

  let keyboardMap = new KeyboardMap(false);
  /// This intentionally has no arguments at the back for it to run before render.
  useEffect(() => {
    // Normal Keyboard Shortcuts
    keyboardMap.addDefaultKey(DELETE, () => store.dispatch(onDeleteThunk()));
    keyboardMap.addDefaultKey(BACKSPACE, () => store.dispatch(onDeleteThunk()));
    keyboardMap.addDefaultKey(TAB, () => store.dispatch(indentThunk()));

    // Ctrl key Shortcuts.
    keyboardMap.addCtrlKey(S, () => store.dispatch(saveFileThunk()));
    keyboardMap.addCtrlKey(C, () => store.dispatch(copyThunk()));
    keyboardMap.addCtrlKey(V, () => dispatch(onPaste()));
    keyboardMap.addCtrlKey(Z, () => dispatch(undoStateHistory()));
    keyboardMap.addCtrlKey(Y, () => dispatch(redoStateHistory()));
    keyboardMap.addCtrlKey(B, () => dispatch(toggleCodeBlocks()));
    keyboardMap.addCtrlKey(P, () => store.dispatch(editorThunk()));
    keyboardMap.addCtrlKey(O, () => store.dispatch(openImportThunk()));
    keyboardMap.addCtrlKey(DELETE, () => store.dispatch(onClearThunk()));
    keyboardMap.addCtrlKey(BACKSPACE, () => store.dispatch(onClearThunk()));
    keyboardMap.addCtrlKey(ENTER, () => store.dispatch(onSubmitCode()));
    keyboardMap.addCtrlKey(I, () => dispatch(toggleShowIndent()));

    // Shift key Shortcuts
    keyboardMap.addShiftKey(TAB, () => store.dispatch(unindentThunk()));

    // Combination key Shortcuts
    keyboardMap.addKey(1, 1, 0, C, () => store.dispatch(clipboardThunk()));
  });

  const handleGlobalKeyboardPress = useCallback((e) => {
    /// Collect keys only when logged in and not in modals
    if (isLoggedIn && currModal === NONE)
      sendKeyPress(e.key, e.ctrlKey, e.shiftKey, e.altKey);

    /// Ignore some keys in code editor mode.
    const elementTagName = document.activeElement.tagName.toLowerCase();
    const isDisabled = ignoredShortcuts.isDisabled(
      elementTagName,
      currModal !== NONE,
      e.code,
    );
    if (isDisabled) return;

    /// We use the code instead as {keyCode} is deprecated.
    const keyFunction = keyboardMap.getKey(
      e.shiftKey,
      e.ctrlKey,
      e.altKey,
      e.code,
    );
    if (keyFunction === null) return;
    keyFunction();
    e.preventDefault();
  });

  useEffect(() => {
    document.addEventListener('keydown', handleGlobalKeyboardPress);
    return () =>
      document.removeEventListener('keydown', handleGlobalKeyboardPress);
  });

  let iFrame = null;
  if (isPythonTutorOn) {
    const outputString = getFileOutput(
      parseTree({
        items: items,
      }),
    );
    iFrame = <PythonTutorDiv code={outputString} />;
  }

  const pythonIDE = (
    <div className="module-border-wrap">
      <div className="module">
        <div
          className="code-section"
          style={{
            overflowY: 'scroll',
            flexDirection: 'horizontal',
          }}
        >
          <Editor
            value={pythonCodeBoxContent}
            onValueChange={(code) => {
              dispatch(setPythonCodeBoxContent(code));
              const [isSuccessful, newItems, errorMsg] = astParser(code);
              if (!isSuccessful) return dispatch(showSnackBarMessage(errorMsg));

              dispatch(setItems(newItems));
              dispatch(showSnackBarMessage('Code Successfully Saved!'));
            }}
            highlight={(code) => highlight(code, languages.python, 'python')}
            padding={10}
            tabSize={4}
            style={{
              fontSize: 16,
            }}
            data-testid='python-editor'
          />
        </div>
      </div>
      <AutoIndentFab />
    </div>
  );


  const normalDiv = (
    <div className="flex-container">
      <div
        data-tut="coding_req"
        style={{
          flex: '70%',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <CSSTransition
          in={!showPythonInterface}
          timeout={100}
          classNames="transition-drawer"
          unmountOnExit
        >
          <div
            data-tut="choosingColumn"
            style={{
              position: 'relative',
              flex: '0 15%',
              width: '15vw',
              minWidth: '120px',
              maxHeight: '100%',
            }}
            className="vertical-flex-container selection-column"
          >
            <ThemeProvider theme={theme}>
              <SelectionColumn />
            </ThemeProvider>
          </div>
        </CSSTransition>
        <div
          data-tut="codeIDE"
          style={{
            display: 'flex',
            flex: '65%',
            position: 'relative',
            flexDirection: 'column',
          }}
        >
          <CSSTransition
            in={!showPythonInterface}
            timeout={100}
            classNames="transition-drawer"
            unmountOnExit
          >
            <div>
              <div data-tut="IDEUtils" className="normal-div">
                <BlockPythonNavbar />
              </div>
              <div
                data-tut="codeIDE"
                style={{
                  position: 'relative',
                  overflowX: 'scroll',
                  overflowY: 'scroll',
                }}
              >
                <CodeBox />
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            in={showPythonInterface}
            timeout={100}
            classNames="transition-drawer"
            unmountOnExit
          >
            <div>
              <div data-tut="IDEUtils">
                <BlockPythonNavbar />
              </div>
              <div
                data-tut="codeIDE"
                style={{
                  position: 'relative',
                  overflowX: 'scroll !important',
                  overflowY: 'scroll !important',
                }}
              >
                {pythonIDE}
              </div>
            </div>
          </CSSTransition>
          <MessageBar
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'bottom',
            }}
          />
        </div>
      </div>
      <div
        data-tut="helpbox"
        style={{
          flex: '20%',
          minWidth: '20vw',
          overflowY: 'scroll',
          overflowX: 'scroll',
          position: 'relative',
          zIndex: '-1',
        }}
        className="helpbox"
      >
        {HELPBOX_BLOCK(hovering)}
      </div>
    </div>
  );

  const pythonTutorDiv = (
    <div data-tut="coding_req" style={{ display: 'flex' }}>
      <div
        style={{ flex: '45%', padding: '0.5vh 0.5vw 0 0.5vw' }}
        className="vertical-flex-container tutor-codebox-column"
      >
        <CSSTransition
          in={isPythonTutorOn}
          timeout={100}
          classNames="transition-drawer"
          unmountOnExit
        >
          <div>
            <div data-tut="IDEUtils">
              <BlockPythonNavbar />
            </div>
            <div
              data-tut="codeIDE"
              style={{
                flex: '90%',
                overflowX: 'scroll',
                overflowY: 'scroll',
              }}
            >
              <CodeBox />
              <MessageBar
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
              />
            </div>
          </div>
        </CSSTransition>
      </div>
      <div className="tutor-iframe-column">
        <div className="tutor-navbar-small">
          <BlockPythonNavbar />
        </div>
        <div>{iFrame}</div>
      </div>
    </div>
  );

  return (
    <div
      data-tut="everything"
      style={{ maxHeight: '100vh', overflow: 'hidden', maxWidth: '100vw' }}
    >
      {!isPythonTutorOn ? normalDiv : pythonTutorDiv}
      <ThemeProvider theme={theme}>
        <TutorialBlock keyboardMap={keyboardMap} />
      </ThemeProvider>
      <AboutModal />
      <FeedbackModal />
      <ImportFileModal />
      <LoginModal />
      <RegisterModal />
      <EditModal />
      <CustomCursor
        dimensions={40}
        fill="#FFF"
        id="custom-cursor"
        smoothness={{
          movement: 0.2,
          scale: 0.1,
          opacity: 0.1,
        }}
      />
      <div className="loader">
        <FadeLoader loading={promiseInProgress} color="#36d7b7" radius={3} />
      </div>
    </div>
  );
};
export default Home_functional;
