import { useState } from 'react';
import { Rating } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';
import { sendTutorialRating } from '../../api/send';
import { useDispatch, useSelector } from 'react-redux';
import { showSnackBarMessage } from '../../redux/messageState';
import { tutorialManager } from '../TutorialManager';

const ratingMap = new Map([
  [1, 'Not at all useful'],
  [2, 'Slightly useful'],
  [3, 'Moderately useful'],
  [4, 'Very useful'],
  [5, 'Extremely useful'],
]);
export function getRating(rating) {
  return ratingMap.get(rating) ?? 'None';
}

function TutorialRating() {
  const dispatch = useDispatch();
  const currTutorialNo = useSelector(
    (state) => state.tutorialState.currTutorialNo,
  );
  const [rating, setRating] = useState(0);
  const [hoveredRating, setHoveredRating] = useState(0);

  const [isReadOnly, setIsReadOnly] = useState(false);
  async function handleAsyncSubmission(selectedValue) {
    try {
      setIsReadOnly(true);
      setRating(selectedValue);
      await sendTutorialRating(
        tutorialManager.getTutorialByNo(currTutorialNo).getName(),
        getRating(selectedValue),
      );
      dispatch(showSnackBarMessage('Tutorial rating has been sent!'));
    } catch (error) {
      setIsReadOnly(false);
      setRating(0);
      dispatch(
        showSnackBarMessage(
          "Couldn't send tutorial rating. Please try again later.",
        ),
      );
    }
  }
  return (
    <div style={{ maxWidth: '300px' }}>
      <p>Please rate this tutorial!</p>
      <Rating
        value={rating}
        readOnly={isReadOnly}
        onChange={handleAsyncSubmission}
        onHoverChange={setHoveredRating}
      />
      <div>
        <div data-testid="hovered-rating">
          {`Click to submit!: ${getRating(hoveredRating)}`}
        </div>
      </div>
    </div>
  );
}

export default TutorialRating;
