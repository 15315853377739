import {
  EVERYTHING_SELECTOR,
  NOTHING_SELECTOR,
  PYTHON_TUTOR_SELECTOR,
  CODING_SELECTOR,
  RUN_CODE_SELECTOR,
  EDIT_CODE_SELECTOR,
} from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { onClearThunk } from '../../redux/thunks/onClear';
import { PrintBlock } from '../../blocks/io_types/Print';
import { openLogin } from '../../redux/modalState';
import { blockModeThunk } from '../../redux/thunks/BlockMode';
import { pythonTutorModeThunk } from '../../redux/thunks/PythonTutorMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 1: Your First program</h1>
      <h2>At the end of this chapter, you will be able to:</h2>
      <ol>
        <li>Learn about the basics of Python</li>
        <li>Write a simple hello world program</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const IntroToPrint = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Let us start by writing a print statement.</h1>
      <h2>What is a print statement?</h2>
      {code(["print('Hi')"])}
      <p>
        A print statement like the one shown above.
        <br />
        This statement will output the word "Hi" to your console.
        <br />
      </p>
      <p>Let us see how this happens for ourselves!</p>
    </div>
  ),
  onStep: blockModeThunk,
});

const ShowingPrintSelection = CreateStep({
  selector: EVERYTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Let's start writing a print statement</h1>
      <p>Steps:</p>
      <ol>
        <li>Hover over the IO menu</li>
        <li>
          Click on the <code>print</code> statement
        </li>
        <li>It should appear in the code box</li>
      </ol>
    </div>
  ),
  condition: ({ items }) =>
    items.some((item) => item.type === PrintBlock.getType()),
  resetCode: '',
  onStep: blockModeThunk,
});

const KeyInHelloWorld = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Printing Hello World!</h1>
      <ol>
        <li>Click on the input of the print statement</li>
        <li>
          Type in <code>'Hello World!'</code>
        </li>
      </ol>
    </div>
  ),
  condition: ({ items }) => {
    if (items.length != 1) return false;
    const firstItem = items[0];
    const inputField = firstItem.input1 ?? '';
    return firstItem.type === PrintBlock.getType() &&
      (inputField.trim() === "'Hello World!'" || inputField.trim() === '"Hello World!"');
  },
  resetCode: 'print()',
  jumpOnComplete: 4,
  onStep: blockModeThunk,
});

const RunCode = CreateStep({
  selector: RUN_CODE_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Run your code!</h1>
      <p>
        Now that we have prepared our print statement, let's run it and see what
        happens!
      </p>
      <p>Click on the Run Code button in the navigation bar to run the code!</p>
    </div>
  ),
  condition: ({ isPythonTutorOn }) => isPythonTutorOn,
  jumpOnComplete: 5
});

const PythonTutorScreen = CreateStep({
  selector: PYTHON_TUTOR_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Python Tutor</h1>
      <p>
        Our website uses Python Tutor to run our code so you can see how
        everything works!
      </p>
    </div>
  ),
  position: 'bottom',
  onStep: pythonTutorModeThunk,
});

const StepThroughCode = CreateStep({
  selector: PYTHON_TUTOR_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Using Python Tutor</h1>
      <p>
        By clicking on the "Next" button on python tutor, you can step through
        the various lines of code and view the output at that point of time.
      </p>
    </div>
  ),
  position: 'bottom',
  onStep: pythonTutorModeThunk,
});

const CurrentOutputOfCode = CreateStep({
  selector: PYTHON_TUTOR_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Current console output of Code</h1>
      <p>The top right blank shows what is the current output of the program</p>
    </div>
  ),
  position: 'bottom',
  onStep: pythonTutorModeThunk,
});
const ObjectVisualization = CreateStep({
  selector: PYTHON_TUTOR_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Object Visualization</h1>
      <p>
        In the bottom right section, Python Tutor will show what python is
        storing internally.
      </p>
      <p>
        For now, this will be empty, but in the future, this section will be
        very useful in learning what is right and wrong about your current code
        snippet.
      </p>
    </div>
  ),
  position: 'bottom',
  onStep: pythonTutorModeThunk,
});

const TestOutPythontutor = CreateStep({
  selector: EVERYTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Try around</h1>
      <p>
        Feel free to play around with the python tutor screen and see the output
        of the code!
      </p>
      <p>Whenever you are ready, feel free to go to the next step.</p>
    </div>
  ),
  position: 'bottom',
  onStep: pythonTutorModeThunk,
});

const GoBackToCode = CreateStep({
  selector: EDIT_CODE_SELECTOR,
  content: (
    <div>
      <h1>Go back to code</h1>
      <p>
        To go back to editing your code, click on the edit code button in the
        navigation bar!
      </p>
    </div>
  ),
  position: 'bottom',
  condition: ({ isPythonTutorOn }) => !isPythonTutorOn,
  onStep: pythonTutorModeThunk,
  jumpOnComplete: 11,
});

const CongratulationsMessage = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Congratulations!</h1>
      <p>Congratulations on completing Chapter 1! There are 14 Chapters available!</p>
      <p>
        Please <b>login</b> to learn Syntax, Variables, Conditionals, Functions and more!
      </p>
    </div>
  ),
});

const steps = [
  Introduction,
  IntroToPrint,
  ShowingPrintSelection,
  KeyInHelloWorld,
  RunCode,
  PythonTutorScreen,
  StepThroughCode,
  CurrentOutputOfCode,
  ObjectVisualization,
  TestOutPythontutor,
  GoBackToCode,
  CongratulationsMessage,
];

const postTutorialCall = () => (dispatch, getState) => {
  if (!getState().authState.loggedIn) dispatch(openLogin());
};

export const Chapter1Program = new Tutorial({
  name: 'Chapter 1: First Program',
  preTutorialCall: onClearThunk,
  postTutorialCall: postTutorialCall,
});
Chapter1Program.addSteps(steps);
