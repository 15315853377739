import { getFileOutput, parseTree } from '../../utils/Parsing';
import { togglePythonTutor, closePythonTutor } from '../itemsState';
import { showSnackBarMessage } from '../messageState';
import { closeMenu } from '../temporaryStates';
import { sendCode } from '../../api/send';

/**
 * Submits the code to python tutor / closes python tutor.
 * @returns {Function}
 */
export const onSubmitCode = () => (dispatch, getState) => {
  const state = getState();
  const { isPythonTutorOn, items } = state.itemStates;

  // If Python Tutor is on, turn it off and return.
  if (isPythonTutorOn) return dispatch(closePythonTutor());

  // Otherwise submit the code to python tutor.
  const outputString = getFileOutput(
    parseTree({
      items: items,
    }),
  );

  if (outputString.length === 0)
    return dispatch(showSnackBarMessage("Can't submit empty code!"));

  sendCode(outputString);
  dispatch(closeMenu());
  dispatch(togglePythonTutor());
};
