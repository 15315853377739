export default class ItemBlock {
  /**
   * An item block used to represent python code snippets.
   * @param {Number} id id of the block
   * @param {BlockType} type type of the block
   * @param {String} input1 the first input of the block
   * @param {String} input2 the second input of the block
   * @param {String} input3 the third input of the block
   * @param {String} input4 the fourth input of the block
   */
  constructor(id, type, input1 = '', input2 = '', input3 = '', input4 = '') {
    this.id = id;
    this.type = type;
    this.input1 = input1;
    this.input2 = input2;
    this.input3 = input3;
    this.input4 = input4;
    this.children = [];
  }

  /**
   * Sets the children of the current block.
   * @param {Array} itemBlocks the children of the block
   * @returns {void}
   */
  setChildren(itemBlocks) {
    this.children = itemBlocks;
  }

  /**
   * Converts the block to a JSON object.
   * @returns {Object} the object representation of the block
   */
  convertToPOJO() {
    return {
      id: this.id,
      type: this.type,
      input1: this.input1,
      input2: this.input2,
      input3: this.input3,
      input4: this.input4,
      children: this.children,
    };
  }
}
