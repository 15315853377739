export default class BlockAPI {
  /**
   * A block API for a block in python.
   * @param {BlockType} type Type of the block
   * @param {String} regex The regex to parse this type of block
   * @param {String} placeHolders The placeholders for the inputs.
   * @param {Function} helpboxFunction The function used to display helpbox messages.
   * @param {JSX.Element} selectionBlock The block to be displayed in the selection column.
   * @param {JSX.Element} codeBlock The block to be displayed in the code editor.
   * @param {Function} parsingFunction The function used to parse the block.
   */
  constructor(
    type,
    regex,
    placeHolders,
    helpboxFunction,
    selectionBlock,
    codeBlock,
    parsingFunction,
  ) {
    this.type = type;
    this.regex = regex;
    this.placeHolders = placeHolders; //dictionary of input values
    this.helpboxFunction = helpboxFunction;
    this.selectionBlock = selectionBlock;
    this.codeBlock = codeBlock;
    this.parsingFunction = parsingFunction;
  }

  /**
   * Returns the block type of the current block.
   * @returns {BlockType}
   */
  getType() {
    return this.type;
  }

  /**
   * Returns the regex for the current block.
   * @returns {String}
   */
  getRegex() {
    return this.regex;
  }

  /**
   * Returns the placeholders for the current object.
   * @returns {String}
   */
  getPlaceHolders() {
    return this.placeHolders;
  }

  /**
   * Returns the function used to display helpbox messages.
   * @returns {Function} The function used to display helpbox messages.
   */
  getHelpboxFunction() {
    return this.helpboxFunction;
  }

  /**
   * Returns the block to be displayed in the selection column.
   * @returns {JSX.Element} The block to be displayed in the selection column.
   */
  getSelectionBlock() {
    return this.selectionBlock;
  }

  /**
   * Returns the block to be displayed in the code editor.
   * @returns {JSX.Element} The block to be displayed in the code editor.
   */
  getCodeBlock() {
    return this.codeBlock;
  }

  /**
   * Returns the function used to parse the block from a string.
   * @returns {Function} The function used to parse the block.
   */
  getParsingFunction() {
    return this.parsingFunction;
  }
}
