import BlockAPI from '../BlockAPI';
import {
  ONE_VW_SIZE,
  TWO_VW_SIZE,
  code,
  getString,
  markdown,
} from '../../components/ComponentUtils';
import { Button } from '@material-ui/core';

// define id number
const DICT_POP = 17;

// define block regex for parsing
const DICT_POP_REGEX = /(.*) *= *(.*) *\. *pop *\( *(.*) *\)()/;
const regexArr = [DICT_POP_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'dictionary',
  input2: 'key_to_pop',
  input3: 'value_of_popped_key',
};

// define functional component for helpBox
function DictPopHelpbox() {
  const lines = [
    `example_dict = {`,
    `    "brand": "Ford",`,
    `    "model": "Mustang",`,
    `    "year": 1964,`,
    `}`,
    `example_dict.pop("model")`,
    `print(example_dict)`,
    `# Output: {'brand': 'Ford', 'year': 1964}`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The **pop()** method removes the item with the specified key name`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const DICT_POP_SELECTION_BLOCK = // `${input1} = {${input2}}`
  (
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <code>
        {ONE_VW_SIZE} = {TWO_VW_SIZE}.pop({ONE_VW_SIZE})
      </code>
    </Button>
  );

// define input block
const dictPopInputBlock = ({ input1_div, input2_div, input3_div }) => {
  return (
    <div>
      {input3_div} {getString('=')} {input1_div}
      {getString('.')}pop{getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2, input3) {
  return `${input3} = ${input1}.pop(${input2})`;
}

export const DictPopBlock = new BlockAPI(
  DICT_POP,
  regexArr,
  PLACEHOLDERS,
  <DictPopHelpbox />,
  DICT_POP_SELECTION_BLOCK,
  dictPopInputBlock,
  parsingFunction,
);
