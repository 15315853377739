import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const LIST_APPEND = 28;

// define block regex for parsing
const LIST_APPEND_REGEX = /(.*)\. *append *\((.*)\)()()/;
const regexArr = [ LIST_APPEND_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'lst',
  input2: 'item_to_add',
}

// define functional component for helpBox
function ListAppendHelpbox() {
  const lines = [
    `lst = [3, 4]`,
    `lst.append(1) # add 1 to the end of the list`,
    `print(lst) # output: [3, 4, 1]`,
    `# What is the time complexity of list.append(element)?`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`This method **adds** **an element** to the end of the list.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const LIST_APPEND_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE}.append({ONE_VW_SIZE})
    </code>
  </Button>
);

// define input block
const listAppendInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div}.append{getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1}.append(${input2})`;
}

export const ListAppendBlock = new BlockAPI(LIST_APPEND,
  regexArr,
  PLACEHOLDERS,
  <ListAppendHelpbox />,
  LIST_APPEND_SELECTION_BLOCK,
  listAppendInputBlock,
  parsingFunction);