import FileSaver from 'file-saver';
import { parseTree, getFileOutput } from './Parsing';

/**
 * Saves the python code to the user's computer.
 * @param {Array<Item>} items The array of items to be stored in the file.
 * @param {String} fileName The name of the file.
 */
function saveFile(items, fileName) {
  // take in this.state
  const outputString = getFileOutput(
    parseTree({
      items: items,
    }),
  );
  var blob = new Blob([outputString], { type: 'text/plain;charset=utf-8' });
  FileSaver.saveAs(blob, fileName);
}

/// Exported for testing
export const DEFAULT_FILENAME = 'Untitled';

/**
 * Saves the file to the user's computer with the correct `.py` extension.
 * @param {Array<Item>} items An array of items to be saved.
 * @param {String} name the name of the file to be saved.
 * @returns
 */
function save(items, name = DEFAULT_FILENAME) {
  if (!name.endsWith('.py')) name += '.py';
  return saveFile(items, name);
}

export default save;
