import BlockAPI from '../BlockAPI';
import { code, getTextInBox, markdown } from '../../components/ComponentUtils';
import { Button } from '@material-ui/core';

// define id number
const BREAK = 18;

// define block regex for parsing
const BREAK_REGEX = /break()()()()/;
const regexArr = [BREAK_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {};

// define functional component for helpBox
function BreakHelpbox() {
  const lines = [
    `lst = [4, 9, 9]`,
    `target = 9`,
    `for i in range(len(lst)):`,
    `    if lst[i] == target:`,
    `        print(i)`,
    `        break`,
    `# output: 1 (there is no 2)`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`**break** is used to terminate a loop completely.`)}
      {code(lines)}
    </div>
  );
}
// define selection block
const BREAK_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase ' }}
    variant="contained"
    size="small"
  >
    <code style={{ display: 'inline-block' }}>break</code>
  </Button>
);

// define input block
const breakInputBlock = () => {
  return <div>{getTextInBox('break')}</div>;
};

function parsingFunction() {
  return `break`;
}

export const BreakBlock = new BlockAPI(
  BREAK,
  regexArr,
  PLACEHOLDERS,
  <BreakHelpbox />,
  BREAK_SELECTION_BLOCK,
  breakInputBlock,
  parsingFunction,
);
