import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourStyle } from '../styles';
import { CODING_SELECTOR, NOTHING_SELECTOR, INFO_SELECTOR } from '../constants';
import { onClearThunk } from '../../redux/thunks/onClear';
import { blockModeThunk } from '../../redux/thunks/BlockMode';

const IntroductionStep = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourStyle}>
      <p>
        Welcome to your first tutorial! This is a tutorial to help you get
        started with using the website.
      </p>
      <p>
        As this is our first tutorial, please let us know if you have any
        feedback.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});
const TryToDrag = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Adding an element to the page</h1>
      <ol>
        <li>Click / Hover over the "IO" category to open it.</li>
        <li>
          <p>
            You can choose either 1 of the ways below to add elements to the
            screen
          </p>
          <ul>
            <li>Drag and drop the element you want to add to the screen.</li>
            <li>Click on the element you want to add to the screen.</li>
          </ul>
        </li>
      </ol>
    </div>
  ),
  condition: ({ items }) => items.length >= 1,
  resetCode: '',
  onStep: blockModeThunk,
});
const GoodJob = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <p>Good Job! you managed to drag the item onto the code box</p>
      <p>
        By default, the elements will be added at the end of the code section
      </p>
      <p>Now, we will move on to add elements behind one another.</p>
    </div>
  ),
  onStep: blockModeThunk,
});

const Add2ndElem = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Let's add another element</h1>
      <p>Now, we will add another component to the screen.</p>
      <p>Likewise, you can drag and drop the element or click on it.</p>
    </div>
  ),
  condition: ({ items }) => items.length >= 2,
  resetCode: 'print("Hello World!")',
  onStep: blockModeThunk,
});

const Explainer = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Adding an element behind an existing element</h1>
      <p>There are also 2 different ways to add an element after another.</p>
      <ol>
        <li>
          Drag and drop the element that you want to add behind the currently
          selected element
        </li>
      </ol>

      <b>OR</b>
      <ol>
        <li>Click on the element you have added in the code box.</li>
        <li>Click on the element you want to add behind.</li>
      </ol>
    </div>
  ),
  helpboxContent: "Let's try to add a component behind another component",
  condition: ({ items }) => items.length >= 3,
  resetCode: 'print("Hello World!")\nprint("Hello World!")',
  onStep: blockModeThunk,
});

const Indentation = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Indenting an element</h1>
      <p>Good Job! you managed to add an element behind another element</p>
      <p>Now, we will be moving on to how to indent items</p>
      <ul>
        <li>Click on the element you have added in the code box.</li>
        <li>
          Drag the element to the right
          <ol>
            <li>Use the tab shortcut</li>
            <li>Or use the toolbar</li>
          </ol>
        </li>
      </ul>
      <p>
        <b>Note:</b> There must be another element above it to indent an element
        in.
      </p>
    </div>
  ),
  helpboxContent: "Let's try to indent an element",
  condition: ({ items }) =>
    items.some((item) => item.children && item.children.length > 0),
  resetCode:
    'print("Hello World!")\nprint("Hello World!")\nprint("Hello World!")',
  onStep: blockModeThunk,
});

const UsageComplete = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <p>Good Job! You managed to indent an element</p>
      <p>
        This is the end of the basic tutorial on how to use the webpage. Hope
        you enjoy using the website!
      </p>
    </div>
  ),
  helpboxContent: 'Tutorial Complete',
  onStep: blockModeThunk,
});

const Feedback = CreateStep({
  selector: INFO_SELECTOR,
  content: (
    <div>
      <h1>Ideas & suggestions</h1>
      <p>
        If you have any suggestions on how we can improve this, please leave a
        feedback in this section.
      </p>
    </div>
  ),
});

const steps = [
  IntroductionStep,
  TryToDrag,
  GoodJob,
  Add2ndElem,
  Explainer,
  Indentation,
  UsageComplete,
  Feedback,
];

export const Usage = new Tutorial({
  name: 'Usage Guide',
  preTutorialCall: onClearThunk,
  tabNavigation: false,
});
Usage.addSteps(steps);
