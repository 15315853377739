import { onCopy } from '../itemsState';
import { showSnackBarMessage } from '../messageState';
import { COPY_MSG, PLS_SELECT_ELEM } from '../../constants/constants';
import { DEFAULT_SELECTED_VALUE } from '../../constants/default-values';

/**
 * Copies the selected item.
 * @returns {Function}
 */
export const copyThunk = () => (dispatch, getState) => {
  const state = getState();
  const selected = state.itemStates.selected;
  dispatch(onCopy());
  dispatch(
    showSnackBarMessage(
      selected == DEFAULT_SELECTED_VALUE ? PLS_SELECT_ELEM : COPY_MSG,
    ),
  );
};
