import Nestable from 'react-nestable';
import { useSelector } from 'react-redux';
import {
  BORDER_ACTIVE_STYLE,
  BORDER_INACTIVE_STYLE,
  COLLAPSED_ICON_STYLE,
  RENDER_ITEM_STYLE,
} from '../constants/styles';
import { ContextMenu } from './ContextMenu';
import GetInputField from './GetInputField';
import { getStatementNumber } from '../utils/Code';
import { parseTree } from '../utils/Parsing';
import { useDispatch } from 'react-redux';
import {
  setSelectedItem,
  onDrop,
  onDropCodeBox,
  setItems,
  borderUpdated,
  borderNeedsUpdate,
  borderUpdateRequired,
} from '../redux/itemsState';
import { setAnchorPoint, openMenu } from '../redux/temporaryStates';
import { NO_TYPE, getBlockFromType } from '../constants/block-types';
import { useCallback, useEffect } from 'react';
import {
  applyBorderBlocks,
  applyDottedBorder,
  removeDottedBorder,
} from '../utils/UI/BorderUtil';
import Down from '../images/angle-small-down-black.svg';
import Left from '../images/angle-small-left-black.svg';
import { closeFlyOut } from '../redux/blockFlyOutState';
import { createOnDropItem } from '../utils/BlockUtil';
import { ShowIndentation } from '../utils/UI/IdentationUtil';

/**
 * The CodeBox component.
 * @returns {JSX.Element} - The JSX element for the CodeBox component.
 */
const CodeBox = () => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.itemStates.items);
  const copiedElement = useSelector((state) => state.itemStates.copiedElement);
  const selected = useSelector((state) => state.itemStates.selected);
  const showBoxes = useSelector((state) => state.itemStates.showBoxes);
  const showIndent = useSelector((state) => state.itemStates.showIndent);
  const borderToBeUpdated = useSelector(
    (state) => state.itemStates.borderToBeUpdated,
  );
  const isPythonTutorOn = useSelector(
    (state) => state.itemStates.isPythonTutorOn,
  );
  const showMenu = useSelector((state) => state.temporaryStates.showMenu);
  const showCopiedBox = useSelector((state) => state.itemStates.showCopiedBox);

  const collapseListener = (e) => {
    if (e.target.id == 'collapseBtn') {
      dispatch(borderNeedsUpdate());
    }
  };

  useEffect(() => {
    document.addEventListener('click', collapseListener);
    return () => {
      document.removeEventListener('click', collapseListener);
    };
  }, []);

  useEffect(() => {
    applyBorderBlocks(showBoxes);
    ShowIndentation(showIndent);
    dispatch(borderUpdated());
  }, [showBoxes, borderToBeUpdated]);

  useEffect(() => {
    if (showCopiedBox) applyDottedBorder(copiedElement?.id);
    return removeDottedBorder;
  }, [copiedElement?.id, borderToBeUpdated]);

  const renderItem = useCallback(({ item, collapseIcon }) => {
    return (
      <div
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          const blockType = parseInt(e.dataTransfer.getData('blockType'), 10);
          if (getBlockFromType(blockType) === NO_TYPE || isNaN(blockType))
            return;
          dispatch(onDrop(createOnDropItem(item.id, blockType)));
          dispatch(closeFlyOut());
        }}
        onClick={(e) => {
          dispatch(setSelectedItem(item.id));
          dispatch(closeFlyOut());
          e.preventDefault();
        }}
        onContextMenu={(e) => {
          const childElemRect =
            e.currentTarget.firstElementChild.getBoundingClientRect();
          const boxY = childElemRect.top;
          const boxX = childElemRect.left;
          const mouseX = e.clientX;
          const mouseY = e.clientY;
          dispatch(
            setAnchorPoint({
              x: mouseX - boxX,
              y: mouseY - boxY,
            }),
          );
          dispatch(setSelectedItem(item.id));
          dispatch(openMenu());
          e.preventDefault();
        }}
      >
        <div
          id={item.id}
          tabIndex="0"
          style={{
            ...RENDER_ITEM_STYLE,
            ...(selected === item.id
              ? BORDER_ACTIVE_STYLE
              : BORDER_INACTIVE_STYLE),
          }}
          className={`separate-item type-${item.type}`}
        >
          <GetInputField id={item.id} is_read_only={false} />
        </div>
        <ContextMenu
          hidden={selected != item.id || !showMenu} // !(selected === item.id && showMenu)
        />
        <div
          style={COLLAPSED_ICON_STYLE}
          onClick={() => dispatch(borderUpdateRequired())}
        >
          {collapseIcon}
        </div>
      </div>
    );
  });

  const renderItemDisplay = useCallback((item) => {
    const COLOR = 160;
    return (
      <div>
        <div
          id={item.item.id}
          tabIndex="0"
          style={RENDER_ITEM_STYLE}
          className={`disabled-item type-${item.item.type}`}
        >
          <GetInputField id={item.item.id} is_read_only={true} />
        </div>
        <div
          style={{
            display: 'inline-block',
            float: 'right',
            color: `rgb(${COLOR}, ${COLOR}, ${COLOR})`,
          }}
        >
          {1 + getStatementNumber(parseTree({ items }), item.item.id)}
        </div>
      </div>
    );
  });

  const renderCollapseIcon = (collapsableItem) => (
    <img
      src={collapsableItem.isCollapsed ? Left : Down}
      width={20}
      height={20}
      id={`collapseBtn-${collapsableItem.id}`}
      data-testid="collapseBtn"
    />
  );

  return (
    <div className="module-border-wrap">
      <div className="module">
        <div
          className="code-section"
          data-tut="code_item_box"
          style={{
            maxHeight: '92vh',
            minHeight: '92vh',
            overflowY: 'scroll',
          }}
          onDrop={(e) => {
            if (!e.target.classList.contains('code-section')) return;
            let blockType = parseInt(e.dataTransfer.getData('blockType'), 10);
            if (getBlockFromType(blockType) === NO_TYPE || isNaN(blockType))
              return;
            dispatch(onDropCodeBox({ blockType: blockType }));
            dispatch(closeFlyOut());
          }}
          onDragOver={(e) => e.preventDefault()}
        >
          <Nestable
            items={items}
            renderItem={!isPythonTutorOn ? renderItem : renderItemDisplay}
            // onChange contains these args {items, item, dragItem, targetPath}
            onChange={({ items }) => dispatch(setItems(items))}
            collapsed={false}
            renderCollapseIcon={renderCollapseIcon}
            confirmChange={() => !isPythonTutorOn}
            group="codeIDE"
            id="code-section-nestable"
            data-testid="code-section-nestable"
          />
          <br />
        </div>
      </div>
    </div>
  );
};

export default CodeBox;
