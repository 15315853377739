import BlockAPI from '../BlockAPI';
import {
  THREE_VW_SIZE,
  TWO_VW_SIZE,
  code,
  getString,
  markdown,
  OPEN_CURLY,
  CLOSE_CURLY,
} from '../../components/ComponentUtils';
import { Button } from '@material-ui/core';

// define id number
const DICT_INIT = 16;

// define block regex for parsing
const DICT_INIT_REGEX = /(.*) *= *\{ *(.*) *\}()()/;
const regexArr = [DICT_INIT_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'dictionary',
  input2: '"key1": "value1", "key2": "value2"',
};

// define functional component for helpBox
function DictInitHelpbox() {
  const lines = [
    `example_dict = {`,
    `    "brand": "Ford",`,
    `    "model": "Mustang",`,
    `    "year": 1964,`,
    `}`,
    `print(example_dict)`,
    `# Output: {'brand': 'Ford', 'model': 'Mustang', 'year': 1964}`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Dictionaries are used to store data values in key:value pairs.`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const DICT_INIT_SELECTION_BLOCK = // `${input1} = {${input2}}`
  (
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <code>
        {TWO_VW_SIZE} = {OPEN_CURLY}
        {THREE_VW_SIZE}
        {CLOSE_CURLY}
      </code>
    </Button>
  );

// define input block

const dictInitInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {getString('{')}
      {input2_div}
      {getString('}')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1} = {${input2}}`;
}

export const DictInitBlock = new BlockAPI(
  DICT_INIT,
  regexArr,
  PLACEHOLDERS,
  <DictInitHelpbox />,
  DICT_INIT_SELECTION_BLOCK,
  dictInitInputBlock,
  parsingFunction,
);
