import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const TUPLE_INDEX = 41;

// define block regex for parsing
const TUPLE_INDEX_REGEX = /(.*) *= *(.*) *\. *index *\((.*)\)()()/;
const regexArr = [ TUPLE_INDEX_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
    input1: 'variable',
    input2: 'tuple',
    input3: 'value',
}

// define functional component for helpBox
function TupleIndexHelpbox() {
    const lines = [
      `thistuple = (1, 3, 7, 8, 7, 5, 4, 6, 8, 5)`,
      ``,
      `x = thistuple.index(8) # Search for the first occurrence of the value 8, and return its position`,
      ``,
      `print(x) # 3`
    ];
    return (
      <div style={{ maxWidth: '100%' }}>
        {markdown(`This method search for the first occurrence of the input value, and return its position.`)}
        {code(lines)}
      </div>
    );
  }

// define selection block
const TUPLE_INDEX_SELECTION_BLOCK = (
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <code>
      {ONE_VW_SIZE} = {TWO_VW_SIZE}.index({ONE_VW_SIZE})
      </code>
    </Button>
  );

// define input block
const TupleIndexInputBlock = ({ input1_div, input2_div, input3_div }) => {
    return (
      <div>
        {input1_div} {getString('=')} {input2_div}.index{getString('(')}
        {input3_div}
        {getString(')')}
      </div>
    );
  };

  
function parsingFunction(input1, input2, input3) {
  return `${input1} = ${input2}.index(${input3})`;
}

export const TupleIndexBlock = new BlockAPI(TUPLE_INDEX,
    regexArr,
    PLACEHOLDERS,
    <TupleIndexHelpbox/>,
    TUPLE_INDEX_SELECTION_BLOCK,
    TupleIndexInputBlock,
    parsingFunction);