import './GetInputField.css';
import { getBlock } from './Inputs';
import { findPath } from '../utils/Path';
import { INPUT_STYLE, INPUT_LENGTH_STYLE_GENERATOR } from '../constants/styles';
import { useDispatch, useSelector } from 'react-redux';
import { setItems } from '../redux/itemsState';
import { copyJSON } from '../utils/JSON';
import { getBlockFromType } from '../constants/block-types';
import { CommentBlock } from '../blocks/others/Comment';

/**
 * The event for handling input change in input field.
 * @param {Event} event The input change event
 * @param {Number} id The id of the input field
 * @param {String} input The new Input of the items
 * @param {Array} items The items array
 * @param {Function} dispatch Dispatch function
 * @returns {void}
 */
function handleInputChange(event, id, input, items, dispatch) {
  const newItems = copyJSON(items);
  const path = findPath(newItems, id);

  let curr = newItems;
  for (let i = 0; i < path.length - 1; i++) {
    curr = curr[path[i]].children;
  }

  const newValue = event.target.value;
  const MAXIMUM_LENGTH =
    curr[path[path.length - 1]].type === CommentBlock.getType() ? 70 : 40;

  // stop accepting input
  if (newValue.length > MAXIMUM_LENGTH) return;

  curr[path[path.length - 1]][input] = newValue;
  dispatch(setItems(newItems));
}

/**
 * The GetInputField component.
 * @param {bool} is_read_only - whether the input field is read only. False by default
 * @param {Number} id - the id of the input field
 * @returns {JSX.Element} - the JSX element for the input field
 */
function GetInputField({ is_read_only = false, id }) {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.itemStates.items) ?? [];
  const path = findPath(items, id);

  let curr = items;
  for (let i = 0; i < path.length - 1; i++) curr = curr[path[i]].children;

  const fields = curr[path[path.length - 1]];
  if (fields === undefined || !('type' in fields)) return <div>Null</div>;

  const blockType = fields.type;

  let input1, input2, input3, input4;

  ({ input1, input2, input3, input4 } =
    getBlockFromType(blockType).getPlaceHolders());
  const i1Value = fields.input1 ?? '';
  const i2Value = fields.input2 ?? '';
  const i3Value = fields.input3 ?? '';
  const i4Value = fields.input4 ?? '';

  const inputBlocks = [input1, input2, input3, input4];
  const inputs = [i1Value, i2Value, i3Value, i4Value];
  const inputDivs = [];

  for (let i = 1; i <= 4; ++i) {
    const input = (
      <div style={INPUT_STYLE}>
        <input
          aria-label={`${id}-input${i}`}
          id={`${id}-input${i}`}
          placeholder={inputBlocks[i - 1]}
          disabled={is_read_only}
          size={20}
          value={inputs[i - 1]}
          onChange={(e) => {
            handleInputChange(e, id, `input${i}`, items, dispatch);
          }}
          style={{
            ...INPUT_LENGTH_STYLE_GENERATOR(
              inputs[i - 1].length > 0
                ? inputs[i - 1].length
                : inputBlocks[i - 1] != undefined
                ? inputBlocks[i - 1].length
                : null,
            ),
          }}
        />
      </div>
    );
    inputDivs.push(input);
  }

  return getBlock(blockType, ...inputDivs);
}
export default GetInputField;
