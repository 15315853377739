import { closePythonTutor, togglePythonInterface } from '../itemsState';

/**
 * Toggles the Python Code Editor.
 * @returns {Function}
 */
export const editorThunk = () => (dispatch, getState) => {
  dispatch(togglePythonInterface());
  dispatch(closePythonTutor());
};
