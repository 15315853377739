import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const FOR_LOOP = 20;

// define block regex for parsing
const FOR_LOOP_REGEX = /for (.*) in +range *\((.*)\) *:()()/;
const regexArr = [ FOR_LOOP_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'num',
  input2: 'start_value, end_value',
}

// define functional component for helpBox

function ForLoopHelpbox() {
  const lines = [`for i in range(3):`, `    print(i)`];
  const output = [`0`, `1`, `2`];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `A for loop is used for iterating over a sequence (that is either a list, a tuple, a dictionary, a set, or a string). 
        \nWe can generate a sequence of numbers using **range()** function. range(10) will generate numbers from 0 to 9 (10 numbers).`,
      )}
      {code(lines)}
      {markdown(`**When you run the program, the output will be:**`)}
      {code(output)}
    </div>
  );
}

// define selection block
const FOR_LOOP_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      for {ONE_VW_SIZE} in range({TWO_VW_SIZE}):
    </code>
  </Button>
);

// define input block
const forLoopInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        {getTextInBox('for')}&nbsp;{input1_div}&nbsp;
      </div>
      {getString('in')}
      <div style={{ display: 'inline-block' }}> &nbsp; </div>
      {getString('range')}

      <div style={{ display: 'inline-block' }}>
        {getString('(')}
        {input2_div}
        {getString(')')}
        {getString(':')}
      </div>
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `for ${input1} in range(${input2}):`;
}

export const ForLoopBlock = new BlockAPI(FOR_LOOP,
  regexArr,
  PLACEHOLDERS,
  <ForLoopHelpbox />,
  FOR_LOOP_SELECTION_BLOCK,
  forLoopInputBlock,
  parsingFunction);