class KeyboardMap {
  /**
   * The main keyboard map class to resolve key pressed into functions.
   * @param {bool} disabled - whether the keyboard map is disabled
   */
  constructor(disabled = false) {
    this.map = new Map();
    this.disabled = disabled;
  }

  /**
   * @param {bool} isShift: is shift key pressed
   * @param {bool} isCtrl: is ctrl key pressed
   * @param {bool} isAlt: is alt key pressed
   * @param {string} key: the key of the key to be added
   *
   * @returns {string} key: the representation of the key as a key
   */
  toKey(isShift = 0, isCtrl = 0, isAlt = 0, key = -1) {
    return String(+isShift) + String(+isCtrl) + String(+isAlt) + key;
  }

  /**
   * @param {bool} disabled: whether the keyboard map is disabled
   * @returns {void}
   */
  setDisabled(disabled) {
    this.disabled = disabled;
  }

  /**
   * Returns whether the keyboard map is disabled.
   * @returns {bool} disabled: whether the keyboard map is disabled
   */
  isDisabled() {
    return this.disabled;
  }

  /**
   * Gets the key from the keyboard map.
   *
   * @param {bool} isShift: is shift key pressed
   * @param {bool} isCtrl: is ctrl key pressed
   * @param {bool} isAlt: is alt key pressed
   * @param {string} key: the key of the key to be added
   *
   * @returns {any} value: the value associated with the key
   */
  getKey(isShift, isCtrl, isAlt, key) {
    const mapKey = this.toKey(isShift, isCtrl, isAlt, key);
    if (this.isDisabled() || !this.map.has(mapKey)) return null;
    return this.map.get(mapKey);
  }

  /**
   * Puts a key into the keyboard map.
   *
   * @param {bool} isShift is shift key pressed
   * @param {bool} isCtrl is ctrl key pressed
   * @param {bool} isAlt is alt key pressed
   * @param {string} key: the key of the key to be added
   * @param {bool} value the value associated with the key
   *
   * @returns {void}
   */
  addKey(isShift, isCtrl, isAlt, key = -1, value) {
    if (key === -1) return;
    this.map.set(this.toKey(isShift, isCtrl, isAlt, key), value);
  }

  /**
   * Add a key without any modifiers.
   *
   * @param {string} key the key of the key to be added
   * @param {string} value the value associated with the key
   *
   * @returns {void}
   */
  addDefaultKey(key, value) {
    return this.addKey(0, 0, 0, key, value);
  }

  /**
   * Add a key with ctrl modifier.
   *
   * @param {string} key the key of the key to be added
   * @param {string} value the value associated with the key
   *
   * @returns {void}
   */
  addCtrlKey(key, value) {
    return this.addKey(0, 1, 0, key, value);
  }

  /**
   * Add a key with shift modifier.
   *
   * @param {string} key the key of the key to be added
   * @param {string} value the value associated with the key
   *
   * @returns {void}
   */
  addShiftKey(key, value) {
    return this.addKey(1, 0, 0, key, value);
  }

  /**
   * Add a key with alt modifier.
   *
   * @param {string} key the key of the key to be added
   * @param {string} value the value associated with the key
   *
   * @returns {void}
   */
  addAltKey(key, value) {
    return this.addKey(0, 0, 1, key, value);
  }

  /**
   * Clear the keyboard map.
   *
   * @returns {void}
   */
  clear() {
    this.map.clear();
  }
}

export default KeyboardMap;
