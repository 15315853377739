import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const SET_UNION = 50;

// define block regex for parsing
const SET_UNION_REGEX = /(.*) *= *(.*) *\. *union *\((.*)\)()/;;
const regexArr = [ SET_UNION_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'set_name',
  input2: 'set1',
  input3: 'set2, ...',
}

// define functional component for helpBox
function SetUnionHelpbox() {
  const lines = [
    `x = {"apple", "banana", "cherry"} # set 1`,
    `y = {"google", "microsoft", "apple"} # set 2`,
    ``,
    `z = x.union(y)`,
    ``,
    `print(z) # {'apple', 'banana', 'microsoft', 'cherry', 'google'}`
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`The **union()** method returns a set that contains all items from the original set, and all items from the specified set(s).`)}
      {markdown(`You can specify as many sets you want, separated by commas. If an item is present in more than one set, the result will contain only one appearance of this item.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const SET_UNION_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {ONE_VW_SIZE} = {TWO_VW_SIZE}.union({ONE_VW_SIZE})
    </code>
  </Button>
);

// define input block
const SetUnionInputBlock = ({ input1_div, input2_div, input3_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {input2_div}
      {getString('.')}union{getString('(')}
      {input3_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2, input3) {
  return `${input1} = ${input2}.union(${input3})`;
}

export const SetUnionBlock = new BlockAPI(SET_UNION,
  regexArr,
  PLACEHOLDERS,
  <SetUnionHelpbox />,
  SET_UNION_SELECTION_BLOCK,
  SetUnionInputBlock,
  parsingFunction);