import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { createTestCase } from '../utils/HandleCodeExecution';
import { onClearThunk } from '../../redux/thunks/onClear';
import TutorialRating from '../components/TutorialRating';
import { blockModeThunk } from '../../redux/thunks/BlockMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 8: Builtin Functions</h1>
      <h2>At the end of this chapter, you will be able to:</h2>
      <ol>
        <li>Know about the common builtin functions</li>
        <li>Learn how to use `print`</li>
        <li>Learn how to use `input`</li>
        <li>Learn how to use `len`</li>
        <li>Learn how to use `range`</li>
        <li>Learn how to use `type`</li>
      </ol>
    </div>
  ),
  onStep: blockModeThunk,
});

const BuiltinFunctionsList = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Builtin Functions</h1>
      <p>
        In python, there are many builtin functions we can use to make our lives
        easier
      </p>
      <p>Here is a list of some of the most common builtin functions</p>
      <ul>
        <li>print</li>
        <li>input</li>
        <li>len</li>
        <li>range</li>
      </ul>
      <p>
        <b>Note: This list is non-exhausive.</b>
        <br />
        For the full list, visit{' '}
        <a href="https://docs.python.org/3/library/functions.html">
          the Official Python Docs
        </a>
      </p>
      <p>We will be focusing on these functions stated in the list.</p>
    </div>
  ),
  onStep: blockModeThunk,
});

const PrintFunction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>The Print Function</h1>
      <p>
        As you have seen in the previous parts, the print functions will output
        its arguments into standard output.
      </p>
      {code(['print("Hello World!")'])}
      <p>
        The code snippet above will result in `Hello World!` to be printed out.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const PrintExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Print Example</h1>
      <p>Let us go through an example with print.</p>
      <p>Write a program that prints out `Hello World!` to standard output.</p>
    </div>
  ),
  testCases: [createTestCase('', 'Hello World!\n')],
  resetCode: '',
  onStep: onClearThunk,
});

const InputFunction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>The input function</h1>
      <p>
        The input functions is a way to read data from standard input (stdin)
      </p>
      {code(["a = input('Write a string')"])}
      <p>The code snippet above prompts the user to write a string</p>
      <p>The input received will be treated as a string.</p>
    </div>
  ),
});

const InputExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Input Example</h1>
      <p>Let us go through an example of using `input`</p>
      <p>Lets write a function which does the following</p>
      <ol>
        <li>Gets a name from the user</li>
        <li>Prints out the name.</li>
      </ol>
      <pre>
        What is your name:<b>John</b>
        <br />
        Hi John!
      </pre>
      <p>Note that the bolded text is keyed in by the user.</p>
      <br />
    </div>
  ),
  onStep: onClearThunk,
  testCases: [
    createTestCase('John', 'What is your name:Hi John!\n'),
    createTestCase('Jane', 'What is your name:Hi Jane!\n'),
  ],
});

const LenFunction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Len function</h1>
      <p>
        The len function is used when we want to find out the length of an
        object
      </p>
      <p>For example, to find the length of a string, we can do:</p>
      {code([
        '# Length of a String',
        'string = "Hello World!"',
        'print(len(string)) # 12',
      ])}
      <p>
        The code snippet above shows how `len` can be used to find the length of
        a string.
      </p>
    </div>
  ),
});

const LenExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Len Example</h1>
      <p>In this example, we will be learning how to use the `len` function</p>
      <p>Write a program that does the following:</p>
      <ol>
        <li>Gets a string from the user</li>
        <li>Prints out the length of the string</li>
      </ol>
    </div>
  ),
  onStep: onClearThunk,
  testCases: [
    createTestCase('testtest', '8\n'),
    createTestCase('test', '4\n'),
    createTestCase('Hello World!', '12\n'),
  ],
  resetCode: '',
});

const RangeFunction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Range Function</h1>
      <p>
        The range function is used when we want a numerical sequence of numbers
        without typing them out.
      </p>
      <p>
        For example, to print the values from 1 to 10 we can make use of the
        code snippet below
      </p>
      {code(['for i in range(1, 11):', '\tprint(i)'])}
      <p>
        We can also pass in the start, end and step to tell the range function
        how to iterate through the numbers
      </p>
      {code(['for i in range(1, 11, 2):', '\tprint(i)'])}
      <p>The code snippet above will print out the odd numbers from 1 to 10</p>
      <p>
        <b>Note:</b> Range does not include the end number.
      </p>
    </div>
  ),
});

const RangeExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Range Example</h1>
      <p>
        In this example, we will be learning how to use the `range` function
      </p>
      <p>Write a program that does the following:</p>
      <ol>
        <li>Gets a number from the user</li>
        <li>
          Prints out the numbers from 1 to the number but avoid the odd numbers
        </li>
      </ol>
      <p>
        <b>Note:</b> The <code>input()</code> function takes in a string and assigns it to a variable.
        <br />
        we can use type casting to convert the variable to an integer.
        <br />
        e.g.
        <br />
        <code>x = int(x)</code> converts x to an integer.
      </p>
    </div>
  ),
  onStep: onClearThunk,
  testCases: [
    createTestCase('10', '2\n4\n6\n8\n'),
    createTestCase('20', '2\n4\n6\n8\n10\n12\n14\n16\n18\n'),
    createTestCase(
      '30',
      '2\n4\n6\n8\n10\n12\n14\n16\n18\n20\n22\n24\n26\n28\n',
    ),
    createTestCase('12', '2\n4\n6\n8\n10\n'),
  ],
});

const TypeFunction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>The type function</h1>
      <p>The type function is used to get the type of the function.</p>
      <p>For example, to get the type of a string, we can do:</p>
      {code([
        '# Type of a String',
        'string = "Hello World!"',
        "type(string) # <class 'str'>",
      ])}
      <p>The code snippet above shows how to get the type of a string.</p>
    </div>
  ),
});

const TypeExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Type Example</h1>
      <p>In this example, we will be learning how to use the `type` function</p>
      <p>Write a program that does the following:</p>
      <p>Print the type for the following values</p>
      <ol>
        <li>1</li>
        <li>'Hello World!'</li>
        <li>print</li>
      </ol>
    </div>
  ),
  onStep: onClearThunk,
  testCases: [
    createTestCase(
      '',
      "<class 'int'>\n<class 'str'>\n<class 'builtin_function_or_method'>\n",
    ),
  ],
});

const TypeCasting = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Specify a Variable Type</h1>
      <p>There may be times when you want to specify a type on to a variable. This can be done with casting.</p>
      <p>Casting in python is therefore done using constructor functions:</p>
      <ol>
        <li>
          <code>int()</code> - constructs an integer number from an integer literal, a float literal (by removing all decimals), or a string literal (providing the string represents a whole number)
        </li>
        <li>
          <code>float()</code> - constructs a float number from an integer literal, a float literal or a string literal (providing the string represents a float or an integer)
        </li>
        <li>
          <code>str()</code> - constructs a string from a wide variety of data types, including strings, integer literals and float literals
        </li>
      </ol>
      {code([
        '# Examples of type casting using int()',
        'x = int(1)   # x will be 1',
        'y = int(2.8) # y will be 2',
        'z = int("3") # z will be 3',
      ])}
    </div>
  ),
});

const Conclusion = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Conclusion</h1>
      <p>During this chapter you should have learnt the following:</p>
      <ol>
        <li>How to use the print function</li>
        <li>How to use the input function</li>
        <li>How to use the len function</li>
        <li>How to use the type function</li>
        <li>How to use Python casting</li>
        <li>How to use the range function</li>
      </ol>
      <p>
        That's it for the builtin functions. See you guys in the next chapter!
      </p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  BuiltinFunctionsList,
  PrintFunction,
  PrintExample,
  InputFunction,
  InputExample,
  LenFunction,
  LenExample,
  TypeFunction,
  TypeExample,
  TypeCasting,
  RangeFunction,
  RangeExample,
  Conclusion,
];

export const Chapter8Builtins = new Tutorial({
  name: 'Chapter 8: Builtin functions',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter8Builtins.addSteps(steps);
