import BlockAPI from '../BlockAPI';
import {
  THREE_VW_SIZE,
  code,
  getAssignmentStatements,
  markdown,
} from '../../components/ComponentUtils';
import { Button } from '@material-ui/core';

// define id number
const MODULUS = 11;

// define block regex for parsing
const MODULUS_REGEX = /(.*)\%=(.*)()()/;
const regexArr = [MODULUS_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'i',
  input2: '2',
  operator: '%=',
};

// define functional component for helpBox
function ModulusHelpbox() {
  const lines = [
    `a = 10 # initializes variable a to the value 10`,
    `a %= 3 # assigns the value 1 (remainder of 10/3) to a`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Takes modulus using left and right operands and assign result to left operand`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const MODULUS_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {THREE_VW_SIZE} %= {THREE_VW_SIZE}
    </code>
  </Button>
);

function parsingFunction(input1, input2) {
  return `${input1} %= ${input2}`;
}

export const ModulusBlock = new BlockAPI(
  MODULUS,
  regexArr,
  PLACEHOLDERS,
  <ModulusHelpbox />,
  MODULUS_SELECTION_BLOCK,
  getAssignmentStatements('%='),
  parsingFunction,
);
