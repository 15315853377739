import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { createTestCase } from '../utils/HandleCodeExecution';
import { onClearThunk } from '../../redux/thunks/onClear';
import TutorialRating from '../components/TutorialRating';
import { blockModeThunk } from '../../redux/thunks/BlockMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 9: Conditionals</h1>
      <h2>At the end of this chapter, you will be able to:</h2>
      <ol>
        <li>Writing If, Elif and Else Statements.</li>
        <li>Incorporating Conditionals into code.</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
});

const IfStatements = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>If Statements</h1>
      {code(['if (1 == 2):', '\tprint("This will never print")'])}
      <p>
        If statements are used to execute a block of code if a condition is met.
        In this example, the condition is <code>1 == 2</code>, which is false as
        1 is not equal to 2.
      </p>
      <p>
        Therefore, the code inside the if statement will <b>not</b> be executed.
      </p>
    </div>
  ),
});

const ElseStatements = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Else Statements</h1>
      {code([
        'if (1 == 2):',
        '\tprint("This will never print")',
        'else:',
        '\tprint("This will print")',
      ])}
      <p>
        Else statements are used to execute a block of code if a condition is
        not met. In this example, the condition is <code>1 == 2</code>, which is
        false as 1 is not equal to 2.
      </p>
      <p>
        Therefore, the code inside the else statement will <b>be</b> executed.
      </p>
    </div>
  ),
});

const WritingAnExample = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Writing an Example</h1>
      <p>
        Now that you know how to write if and else statements, try writing the
        example below.
      </p>
      <ol>
        <li>Read a number from input and assign it to a variable</li>
        <li>If the variable x is equals to 1, print out "Hello World"</li>
        <li>Otherwise, print out "x is not equal to 1"</li>
      </ol>
      <p>
        When you are ready, press the submit button to check your results before
        moving on.
      </p>
    </div>
  ),
  testCases: [
    createTestCase('1', 'Hello World\n'),
    createTestCase('2', 'x is not equal to 1\n'),
    createTestCase('-3', 'x is not equal to 1\n'),
  ],
  resetCode: '',
  position: 'right',
  condition: ({ items }) => items.length > 0,
  onStep: blockModeThunk,
});

const ElifStatements = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Elif Statements</h1>
      {code([
        'if (1 == 2):',
        '\tprint("This will never print")',
        'elif (1 == 1):',
        '\tprint("This will print")',
        'else:',
        '\tprint("This will never print")',
      ])}
      <p>
        Elif statements are used to check for other conditions <b>Only If</b>{' '}
        the previous condition is not met. In this example, the condition is{' '}
        <code>1 == 2</code>, which is false as 1 is not equal to 2. Only then it
        will check the next condition, which is <code>1 == 1</code>, which is
        true as 1 is equal to 1. All the other conditions after the{' '}
        <code>elif</code> will be ignored.
      </p>
      <p>
        In this example, <b>only</b> the code inside the <code>elif</code>{' '}
        statement will <b>be</b> executed.
      </p>
    </div>
  ),
});

const FinalCodeWriting = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Another Coding Question</h1>
      <p>
        Now that you know how to write if, elif and else statements,
        <br />try writing the example below
      </p>
      <ol>
        <li>Read a number from input and assign it to a variable.</li>
        <li>If the variable x is equal to 1, print "Hello" </li>
        <li>If the variable x is equal to 2, print "Goodbye" </li>
        <li>If the variable x is equal to 3, print "Hello & Goodbye" </li>
        <li>Otherwise, print out "See you again"</li>
      </ol>
      <p>
        When you are ready, press the submit button to check your results
        <br />before moving on.
      </p>
    </div>
  ),
  testCases: [
    createTestCase('1', 'Hello\n'),
    createTestCase('2', 'Goodbye\n'),
    createTestCase('3', 'Hello & Goodbye\n'),
    createTestCase('4', "See you again\n"),
    createTestCase('0', "See you again\n"),
    createTestCase('-1', "See you again\n"),
  ],
  resetCode: '',
  position: 'right',
  condition: ({ items }) => items.length > 0,
  onStep: blockModeThunk,
});

const CongratulationsMessage = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Congratulations!</h1>
      <p>Congratulations! You now know conditionals!</p>
      <p>
        Join us in the next tutorial to learn how to do more cool things in
        python!
      </p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  IfStatements,
  ElseStatements,
  WritingAnExample,
  ElifStatements,
  FinalCodeWriting,
  CongratulationsMessage,
];

export const Chapter9Conditionals = new Tutorial({
  name: 'Chapter 9: Conditionals',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter9Conditionals.addSteps(steps);
