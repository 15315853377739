import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { setFlyOut, closeFlyOut } from '../redux/blockFlyOutState';
import './SelectionColumn.css';
import { useState, useEffect } from 'react';
import { CLOSED } from '../constants/block-fly-out-state';
import BlocksFlyOut from './BlocksFlyOut';
import $ from 'jquery';
import logo from './../images/VisualPython-removeBG.png';
import { statements } from '../constants/block-fly-out-state';

/**
 * The Selection Column.
 * @returns {JSX.Element} - The Selection Column.
 */
export const SelectionColumn = () => {
  const dispatch = useDispatch();
  const showFlyOut = useSelector(
    (state) => state.blockFlyOutState.openedFlyOut,
  );
  useEffect(() => {
    if (showFlyOut == CLOSED) isClicked(false);
  }, [showFlyOut]);

  useEffect(() => {
    $(function () {
      // whenever we hover over a menu item that has a submenu
      $('div.parent').on('mouseover click', function () {
        var $menuItem = $(this),
          $submenuWrapper = $('> .wrapper', $menuItem);

        // grab the menu item's position relative to its positioned parent
        var menuItemPos = $menuItem.offset();

        $submenuWrapper.css('top', 'initial');
        $submenuWrapper.css('bottom', 'initial');
        $submenuWrapper.css({
          left: menuItemPos.left + Math.round($menuItem.innerWidth()),
        });

        const menuBottom =
          $(window).height() - menuItemPos.top - $menuItem.outerHeight();
        if (menuItemPos.top > $(window).outerHeight() / 2) {
          $submenuWrapper.css({
            bottom: menuBottom,
          });
        } else {
          $submenuWrapper.css({
            top: menuItemPos.top,
          });
        }
      });
    });
  }, []);

  const [clicked, isClicked] = useState(false);

  function onScroll(e) {
    if (e.target.classList.contains('column')) {
      dispatch(closeFlyOut());
    }
  }

  function onClick(state) {
    isClicked(true);
    dispatch(setFlyOut(state));
  }
  function onMouseEnter(state) {
    if (clicked) {
      return;
    }
    dispatch(setFlyOut(state));
  }
  function onMouseLeave() {
    if (clicked) {
      return;
    }
    dispatch(closeFlyOut());
  }

  function displayFlyout(type) {
    if (type == showFlyOut) {
      return { position: 'fixed', display: 'block' };
    }
    return { display: 'none' };
  }

  return (
    <div className="wrapper">
      <div data-testid="scrollable-column" className="column" onScroll={(e) => onScroll(e)}>
        <img className="header" src={logo} alt="Logo" />

        {statements.map(function (item, i) {
          return (
            <div
              key={i}
              className="parent"
              onClick={() => onClick(item.type)}
              onMouseOver={() => onMouseEnter(item.type)}
              onMouseLeave={onMouseLeave}
              data-testid={`selection-${i}`}
            >
              <Button className="button-item" variant="contained">
                {item.name}
              </Button>
              <div style={displayFlyout(item.type)} className="wrapper">
                <BlocksFlyOut />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
