import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const SET_CLEAR = 44;

// define block regex for parsing
const SET_CLEAR_REGEX = /(.*)\. *clear *\( *\)()()()/;;
const regexArr = [ SET_CLEAR_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'set_name',
}

// define functional component for helpBox
function SetClearHelpbox() {
  const lines = [
    `fruits = {"apple", "banana", "cherry"} # create a new set`,
    ``,
    `fruits.clear() # clears the set`,
    ``,
    `print(fruits) # set()`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`The **clear()** method removes all elements in a set.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const SET_CLEAR_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE}.clear()
    </code>
  </Button>
);

// define input block
const SetClearInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div}.clear()
    </div>
  );
};

function parsingFunction(input1) {
  return `${input1}.clear()`;
}

export const SetClearBlock = new BlockAPI(SET_CLEAR,
  regexArr,
  PLACEHOLDERS,
  <SetClearHelpbox />,
  SET_CLEAR_SELECTION_BLOCK,
  SetClearInputBlock,
  parsingFunction);