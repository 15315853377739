import BlockAPI from '../BlockAPI';
import {
  ONE_VW_SIZE,
  TWO_VW_SIZE,
  code,
  getString,
  markdown
} from "../../components/ComponentUtils";
import { Button } from '@material-ui/core';

// define id number
const DICT_ADD = 15;

// define block regex for parsing
const DICT_ADD_REGEX = /(.*) *\[ *(.*) *\] *= *(.*) *()/;
const regexArr = [DICT_ADD_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'dictionary',
  input2: 'key',
  input3: 'value',
};

// define functional component for helpBox
function DictAddHelpbox() {
  const lines = [
    `example_dict = {`,
    `    "brand": "Ford",`,
    `    "model": "Mustang",`,
    `    "year": 1964,`,
    `}`,
    `example_dict["color"] = "red"`,
    `print(example_dict)`,
    `# Output: {'brand': 'Ford', 'model': 'Mustang', 'year': 1964, 'color': 'red'}`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Adding an item to the dictionary is done by using a new index key and assigning a value to it`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const DICT_ADD_SELECTION_BLOCK = // `${input1} = {${input2}}`
  (
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <code>
        {TWO_VW_SIZE}[{ONE_VW_SIZE}] = {TWO_VW_SIZE}
      </code>
    </Button>
  );

// define input block
const dictAddInputBlock = ({ input1_div, input2_div, input3_div }) => {
  return (
    <div>
      {input1_div}
      {getString('[')}
      {input2_div}
      {getString(']')} {getString('=')} {input3_div}
    </div>
  );
};

function parsingFunction(input1, input2, input3) {
  return `${input1}[${input2}] = ${input3}`;
}

export const DictAddBlock = new BlockAPI(
  DICT_ADD,
  regexArr,
  PLACEHOLDERS,
  <DictAddHelpbox />,
  DICT_ADD_SELECTION_BLOCK,
  dictAddInputBlock,
  parsingFunction,
);
