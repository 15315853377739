import Modal from '@material-ui/core/Modal';
import { Box, Typography, FormGroup, TextField } from '@material-ui/core';
import { useRef } from 'react';
import astParser from '../utils/AstParser';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../redux/modalState';
import { IMPORT } from '../constants/modal-type';
import {
  setItems,
  closePythonTutor,
  setPythonCodeBoxContentFromItems,
} from '../redux/itemsState';

const mui_sx = {
  position: 'absolute',
  overflow: 'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// Exported for testing
export const NOT_ENDING_WITH_PY =
  "Please only upload Python files ending with '.py'";
export const FILE_TOO_LARGE =
  'File Size too large! Shorten your file to 5600 bytes!';
export const MAX_SIZE = 5600;
export const EXPECTED_EXT = '.py';

/**
 * Shows in the file within the code editor.
 *
 * @param {Event} e The event for showing the file.
 * @returns {[boolean, Array, string]} [successStatus, itemBlocks, errorMsg] - The result of parsing the file.
 */
export const showFile = async (e) => {
  e.preventDefault();

  const file = e.target.files[0];
  const fileName = file.name;
  if (!fileName.endsWith(EXPECTED_EXT)) return [false, [], NOT_ENDING_WITH_PY];
  if (file.size > MAX_SIZE) return [false, [], FILE_TOO_LARGE];

  const resultPromise = new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = async () => {
        resolve(astParser(reader.result));
      };
      reader.readAsText(file);
    } catch (e) {
      resolve([false, [], e]);
    }
  });

  return await resultPromise;
};

/**
 * The modal for importing a Python file.
 * @returns {JSX.Element} - The Import File modal.
 */
function ImportFileModal() {
  const fileInputRef = useRef();

  const openedModal = useSelector((state) => state.openedModal.openedModal);
  const dispatch = useDispatch();

  return (
    <Modal open={openedModal == IMPORT} onClose={() => dispatch(closeModal())}>
      <Box sx={mui_sx}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Import a Python File:
        </Typography>
        <FormGroup className="mb-3">
          <TextField
            inputProps={{ 'data-testid': 'file-input' }}
            id="outlined-required"
            type="file"
            variant="outlined"
            inputRef={fileInputRef}
            onChange={async (e) => {
              const [isSuccessful, newItems, errorMsg] = await showFile(e);
              if (!isSuccessful) return alert(errorMsg);
              dispatch(closePythonTutor());
              dispatch(setItems(newItems));
              dispatch(setPythonCodeBoxContentFromItems());
              dispatch(closeModal());
            }}
          />
        </FormGroup>
      </Box>
    </Modal>
  );
}
export default ImportFileModal;
