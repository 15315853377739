import { copyJSON } from './JSON';

/**
 * Clones on item block and all its children with new Ids.
 *
 * @param {Object} elem the current item block to be cloned.
 * @param {Number} currId The next available id for the block.
 * @returns {Object} the cloned item block with new unique ids.
 */
export const cloneElem = (elem, currId) => {
  const newElem = copyJSON(elem);
  newElem.id = currId++;
  if (newElem.children?.length ?? 0 > 0) {
    for (let i = 0; i < newElem.children.length; ++i) {
      [newElem.children[i], currId] = cloneElem(newElem.children[i], currId);
    }
  }
  return [newElem, currId];
};
