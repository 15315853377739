import { copyJSON } from '../utils/JSON';
import { blockManager } from '../blocks/BlockManager';

/**
 * Parses the block into a string.
 * @param {BlockType} type BlockType of the items
 * @param {String} input1 Input1 of the items
 * @param {String} input2 Input2 of the items
 * @param {String} input3 Input3 of the items
 * @param {String} input4 Input4 of the items
 * @returns {String}
 */
export const parseBlock = ({
  type,
  input1 = '',
  input2 = '',
  input3 = '',
  input4 = '',
}) => {
  const block = blockManager.getBlockFromTypeID(type);
  if (block === undefined) return '';

  const func = block.getParsingFunction();
  return func(input1, input2, input3, input4);
};

/**
 * Converts the items into a parsed tree.
 * @param {Array<Item>} items The items to be parsed into a tree.
 * @returns {Array<Object>} The parsed tree.
 */
export const parseTree = ({ items }) => {
  if (items === undefined || items === null || items.length <= 0) return [];
  return parseTreeHelper({ items, indent: 0 });
};

/**
 * The helper function for parsing items into a tree.
 * @param {Array<Object>} items The items to be parsed into a tree.
 * @param {Number} indent The indent of the current block.
 * @returns {Array<Object>}
 */
const parseTreeHelper = ({ items, indent }) => {
  if (items === undefined || items === null) return [];

  let result = [];
  for (let i = 0; i < items.length; i++) {
    let item = items[i];
    result.push({
      indent: indent,
      command: parseBlock(item),
      blockType: item.type,
      blockId: item.id,
    });

    let childBlocks = parseTreeHelper({
      items: item.children,
      indent: indent + 4,
    });

    result = result.concat(childBlocks);
  }

  return copyJSON(result); // deep copy
};

/**
 * Parses the tree into a normal python file.
 * @param {Array<Object>} parsedTree The parsed tree
 * @returns {String} the python file object.
 */
export const getFileOutput = (parsedTree) => {
  var result = '';
  for (let i = 0; i < parsedTree.length; i++) {
    result += ' '.repeat(parsedTree[i].indent) + parsedTree[i].command;
    result += '\n';
  }
  return result;
};
