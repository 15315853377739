import { configureStore } from '@reduxjs/toolkit';
import modalReducer from './modalState';
import temporaryStateReducer from './temporaryStates';
import itemStateReducer from './itemsState';
import authStateReducer from './authState';
import blockFlyOutReducer from './blockFlyOutState';
import tutorialStateReducer from './tutorialState';
import messageStateReducer from './messageState';
import thunk from 'redux-thunk';

export const store = configureStore({
  reducer: {
    openedModal: modalReducer,
    temporaryStates: temporaryStateReducer,
    itemStates: itemStateReducer,
    authState: authStateReducer,
    blockFlyOutState: blockFlyOutReducer,
    tutorialState: tutorialStateReducer,
    messageState: messageStateReducer,
  },
  middleware: [thunk],
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: {
      openedModal: modalReducer,
      temporaryStates: temporaryStateReducer,
      itemStates: itemStateReducer,
      authState: authStateReducer,
      blockFlyOutState: blockFlyOutReducer,
      tutorialState: tutorialStateReducer,
      messageState: messageStateReducer,
    },
    middleware: [thunk],
    preloadedState,
  });
};
