import { DEFAULT_SELECTED_VALUE } from '../constants/default-values';
import { findPath } from './Path';

/**
 * Returns the id of the next item to be selected based on the currently selected item.
 * @param {Array<Item>} items An array of python code
 * @param {Number} currentSelected The id of the currently selected item.
 * @returns {Number} The id of the next item to be selected.
 */
export function getNextSelectionIndex(items, currentSelected) {
  if (currentSelected === DEFAULT_SELECTED_VALUE || items.length === 0) {
    return DEFAULT_SELECTED_VALUE;
  }

  const path = findPath(items, currentSelected);
  let prevList = null;
  let currItemList = items;

  for (let i = 0; i < path.length - 1; ++i) {
    prevList = currItemList;
    currItemList = currItemList[path[i]].children;
  }

  // Finds the index of the currently selected element.
  const currIndex = currItemList.findIndex(
    (elem) => elem.id != null && elem.id == currentSelected,
  );
  const nextIndex = currIndex + 1;

  // Select next item in list.
  if (nextIndex < currItemList.length) return currItemList[currIndex + 1].id;
  if (prevList === null) return DEFAULT_SELECTED_VALUE;

  const prevListNext = prevList[path[path.length - 2] + 1];
  return prevListNext != null ? prevListNext.id : DEFAULT_SELECTED_VALUE;
}
