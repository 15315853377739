import { useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import {
  Modal,
  Box,
  Button,
  FormGroup,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
  FormHelperText,
  Checkbox,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, openLogin } from '../redux/modalState';
import { REGISTER } from '../constants/modal-type';
import { register } from '../api/send';
import { validateFilled, validateEmail } from '../utils/Validator';
import { showSnackBarMessage } from '../redux/messageState';
import { ERROR_KEY } from '../api/constants/constants';
import { clearAllSetter, setEmptyIfError } from '../utils/ModalUtil';
import { IconButton } from '@material-ui/core';

const style = {
  position: 'absolute',
  overflow: 'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

/**
 * The register modal.
 * @returns {JSX.Element} The register modal.
 */
function RegisterModal() {
  const [username, setUsername] = useState('');
  const [usernameErrorMsg, setUsernameErrorMsg] = useState('');
  const [password, setPassword] = useState('');
  const [passwordErrorMsg, setPasswordErrorMsg] = useState('');
  const [cfmPassword, setCfmPassword] = useState('');
  const [cfmPasswordErrorMsg, setCfmPasswordErrorMsg] = useState('');
  const [email, setEmail] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');

  const [proficiency, setProficiency] = useState('');
  const [proficiencyErrorMsg, setProficiencyErrorMsg] = useState('');
  const [checkbox, setCheckbox] = useState(true);
  const [checkboxErrorMsg, setcheckboxErrorMsg] = useState('');

  const usernameRef = useRef();
  const passwordRef = useRef();
  const emailRef = useRef();
  const cfmPasswordRef = useRef();
  const proficiencyRef = useRef();
  const checkBoxRef = useRef();

  const openedModal = useSelector((state) => state.openedModal.openedModal);
  const dispatch = useDispatch();

  const submitHandler = async (event) => {
    event.preventDefault();
    const enteredUsername = usernameRef.current.value;
    const enteredPassword = passwordRef.current.value;
    const enteredCfmPassword = cfmPasswordRef.current.value;
    const enteredEmail = emailRef.current.value;
    const enteredProficiency = proficiencyRef.current.value;
    const isCheckboxChecked = checkBoxRef.current.checked;

    const usernameError = setEmptyIfError(
      !validateFilled(enteredUsername),
      () => setUsernameErrorMsg('Username cannot be empty'),
      () => setUsernameErrorMsg(''),
    );
    const emailError = setEmptyIfError(
      !validateEmail(enteredEmail),
      () => setEmailErrorMsg('Email is not valid'),
      () => setEmailErrorMsg(''),
    );

    const passwordError = setEmptyIfError(
      !validateFilled(enteredPassword),
      () => setPasswordErrorMsg('Password cannot be empty'),
      () => setPasswordErrorMsg(''),
    );
    const cfmPasswordError = setEmptyIfError(
      enteredPassword !== enteredCfmPassword,
      () => setCfmPasswordErrorMsg('Passwords do not match'),
      () => setCfmPasswordErrorMsg(''),
    );

    const proficiencyError = setEmptyIfError(
      !validateFilled(enteredProficiency),
      () => setProficiencyErrorMsg('Please set your proficiency'),
      () => setProficiencyErrorMsg(''),
    );

    const checkboxError = setEmptyIfError(
      !isCheckboxChecked,
      () => setcheckboxErrorMsg('Please agree to our terms!'),
      () => setcheckboxErrorMsg(''),
    );

    if (
      usernameError ||
      emailError ||
      passwordError ||
      cfmPasswordError ||
      proficiencyError ||
      checkboxError
    )
      return;

    try {
      const response = await register(
        enteredUsername,
        enteredEmail,
        enteredPassword,
        enteredProficiency,
      );
      const errorMessage = response.data[ERROR_KEY] ?? '';
      if (errorMessage !== '') {
        dispatch(showSnackBarMessage(errorMessage));
        return;
      }
      dispatch(showSnackBarMessage(`${response.data.message}`));
      dispatch(openLogin());
      clearAllSetter(
        setUsername,
        setPassword,
        setCfmPassword,
        setEmail,
        setProficiency,
        setCheckbox,
      );
    } catch (error) {
      try {
        const response = error.response.data;
        return dispatch(showSnackBarMessage(response[ERROR_KEY]));
      } catch (error1) {
        return dispatch(
          showSnackBarMessage(
            `Registration failed. Please try again later. ${error}`,
          ),
        );
      }
    }
  };
  return (
    <Modal
      open={openedModal === REGISTER}
      onClose={() => dispatch(closeModal())}
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Register
          </Typography>
          <IconButton
            size="small"
            data-testid="close-btn"
            onClick={() => dispatch(closeModal())}
          >
            <i className="fa fa-close" />
          </IconButton>
        </div>
        <Typography
          component={'span'}
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
          <FormGroup className="mb-3">
            <TextField
              id="outlined-required-username"
              label="Username"
              variant="outlined"
              inputRef={usernameRef}
              value={username}
              onChange={(event) => setUsername(event.target.value)}
              error={usernameErrorMsg !== ''}
              helperText={
                validateFilled(username)
                  ? ''
                  : usernameErrorMsg !== ''
                    ? usernameErrorMsg
                    : 'Please fill in your username!'
              }
            />
          </FormGroup>
          <br />
          <FormGroup className="mb-3">
            <TextField
              id="outlined-required-email"
              label="Email"
              variant="outlined"
              inputRef={emailRef}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              error={emailErrorMsg !== ''}
              helperText={
                validateEmail(email)
                  ? ''
                  : emailErrorMsg !== ''
                    ? emailErrorMsg
                    : 'Please enter a valid email address!'
              }
            />
          </FormGroup>
          <br />
          <FormGroup className="mb-3">
            <TextField
              id="outlined-required-password"
              label="Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              inputRef={passwordRef}
              error={passwordErrorMsg !== ''}
              helperText={
                validateFilled(password)
                  ? ''
                  : passwordErrorMsg !== ''
                    ? passwordErrorMsg
                    : 'Please fill in your password!'
              }
            />
          </FormGroup>
          <br />
          <FormGroup className="mb-3">
            <TextField
              id="outlined-required-cfm-password"
              label="Confirm Password"
              variant="outlined"
              type="password"
              value={cfmPassword}
              onChange={(event) => setCfmPassword(event.target.value)}
              inputRef={cfmPasswordRef}
              error={cfmPasswordErrorMsg !== '' || passwordErrorMsg !== ''}
              helperText={
                validateFilled(cfmPassword)
                  ? cfmPasswordErrorMsg
                  : 'Please confirm your password!'
              }
            />
          </FormGroup>
          <br />
          <FormControl required variant="filled" fullWidth>
            <InputLabel>Proficiency</InputLabel>
            <Select
              value={proficiency}
              inputRef={proficiencyRef}
              label="Proficiency"
              onChange={(event) => setProficiency(event.target.value)}
              error={proficiencyErrorMsg !== ''}
            >
              <MenuItem value={'1: Beginner'}>Complete Beginner</MenuItem>
              <MenuItem value={'1: Advanced Beginner'}>
                Able to understand basic Python concepts and statements
                (variables, conditionals, print, etc...)
              </MenuItem>
              <MenuItem value={'1: Intermediate'}>
                Able to write simple programs (e.g. calculator program) in
                Python (Understanding of data types, nested loops, etc...)
              </MenuItem>
              <MenuItem value={'4: Advanced'}>
                Comfortable in writing complex in Python(Understanding of
                classes, OOP, using external libraries, etc...)
              </MenuItem>
            </Select>
            <FormHelperText error={proficiencyErrorMsg !== ''}>
              {validateFilled(proficiency)
                ? ''
                : proficiencyErrorMsg !== ''
                  ? proficiencyErrorMsg
                  : 'Please set your proficiency!'}
            </FormHelperText>
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  inputRef={checkBoxRef}
                  checked={checkbox}
                  inputProps={{ 'data-testid': 'agree-btn' }}
                  onChange={(event) => setCheckbox(event.target.checked)}
                />
              }
              label="By using our website, you agree to let us collect and use your personal data taken from our website"
            />
            <FormHelperText error={true}>
              {checkbox
                ? ''
                : checkboxErrorMsg !== ''
                  ? checkboxErrorMsg
                  : 'Please agree to our terms!'}
            </FormHelperText>
          </FormControl>
          <Button
            data-testid="register-btn"
            variant="contained"
            onClick={submitHandler}
          >
            Register
          </Button>
          <Button
            data-testid="login-btn"
            variant="outlined"
            onClick={() => dispatch(openLogin())}
            style={{
              marginLeft: '10px',
            }}>
            Login Here
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
}

export default RegisterModal;
