import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { code } from '../../components/ComponentUtils';
import { createTestCase } from '../utils/HandleCodeExecution';
import { onClearThunk } from '../../redux/thunks/onClear';
import TutorialRating from '../components/TutorialRating';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Chapter 12: Modules</h1>
      <h2>At the end of this chapter, you will be able to:</h2>
      <ol>
        <li>Know how to import modules</li>
        <li>Learn how to use imported modules</li>
      </ol>
    </div>
  ),
});

const ImportFunction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>The Import Function</h1>
      <p>
        The import function is used when we want to import external files /
        modules.
      </p>
      {code(['import math', 'print(math.pi)'])}
      <p>
        The code snippet above imports the math module and prints the value of
        the variable `pi` in the Math module.
      </p>
      <p>
        For functions and variables, we can just import and use them directly.
        However, instead of calling their name directly, we have to do
        `module.name` instead.
      </p>
    </div>
  ),
});

const ImportExample1 = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Importing example 1</h1>
      <p>
        In this example we will be learning how to import variables from modules
      </p>
      <ol>
        <li>Import the math module</li>
        <li>Print the value of pi from the math module</li>
      </ol>
    </div>
  ),
  onStep: onClearThunk,
  testCases: [createTestCase('', '3.141592653589793\n')],
});

const ImportPart2 = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Finding out module functions</h1>
      <p>
        To find out the functions which are available in the module, you will
        have to do one of the following:
      </p>
      <ol>
        <li>Read the documentation</li>
        <li>Read the source code</li>
      </ol>
      <p>
        Reading the documentation is easier compared to reading the source code.
        For builtin modules, you can find the documentation{' '}
        <a href="https://docs.python.org/3/library/index.html">here.</a>
      </p>
    </div>
  ),
});

const ImportExample2 = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Importing example 2</h1>
      <p>In this example, we will be making use of imported functions.</p>
      <ol>
        <li>Import the math module</li>
        <li>Receive input from stdin</li>
        <li>Print the square root of the input</li>
      </ol>
      <p>
        <b>Note: </b>To find the function, you will have to search in the math
        library to find it.
      </p>
      <p>
        <b>Hint: </b> The number might not just be an integer (Might be a decimal (float) too)
      </p>
    </div>
  ),
  onStep: onClearThunk,
  testCases: [
    createTestCase('4', '2.0\n'),
    createTestCase('9', '3.0\n'),
    createTestCase('2', '1.4142135623730951\n'),
    createTestCase('1', '1.0\n'),
    createTestCase('1.1025', '1.05\n'),
  ],
});

const AnotherWayToImport = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Another way to import</h1>
      <p>
        Instead of typing the module name in front of the function that you want
        to call, you can import in a different way to extract what you want
        immediately.
      </p>
      {code(['from math import pi', 'print(pi)'])}
      <p>
        The code above is the same as the code in the first example, but we do
        not have to include the `math` module component in front when using it.
      </p>
      <ol>
        <li>There are some pros and cons when using this.</li>
        <li>
          If there are multiple functions with the same name in 2 different
          packages, they might conflict with each other.
        </li>
        <li>
          If there is also a local function with the same name, you might not be
          able to reach the old function due to overriding. (We will go through
          more of this in OOP.)
        </li>
        <li>
          Depending on the use case please take note of how you import your
          functions and variables.
        </li>
      </ol>
    </div>
  ),
});

const Conclusion = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Conclusion</h1>
      <p>
        That is all for the tutorial. During this tutorial, you would have
        learnt:
      </p>
      <ol>
        <li>2 Different ways to import a module</li>
        <li>How to use an imported function or variable</li>
        <li>Reading the documentation to find library functions</li>
      </ol>
      <div>
        <TutorialRating/>
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  ImportFunction,
  ImportExample1,
  ImportPart2,
  ImportExample2,
  AnotherWayToImport,
  Conclusion,
];

export const Chapter12Modules = new Tutorial({
  name: 'Chapter 12: Modules',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter12Modules.addSteps(steps);
