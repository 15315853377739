import {
  InputTagName,
  KeysIgnoredInInput,
  KeysIgnoredInTextArea,
  TAB,
  TextAreaTagName,
} from '../constants/keyboard';

// Exported for testing.
export class ShortcutIgnore {
  constructor() {
    this.shortcuts = new Map();
  }

  /**
   *
   * @param {String} element The name of the element that is currently selected.
   * @param {Boolean} isModelOpen If the model is open or not.
   * @param {String} KeyCode The KeyCode of the key pressed
   * @returns
   */
  toKey(element, isModelOpen, KeyCode) {
    return `${element}-${isModelOpen}-${KeyCode}`;
  }

  /**
   * Returns true if a shortcut to the ignore list.
   * @param {String} element The name of the element that is currently selected.
   * @param {Boolean} isModelOpen If the model is open or not.
   * @param {String} KeyCode The KeyCode of the key pressed
   * @returns {Boolean} isDisabled If the shortcut is disabled or not.
   */
  isDisabled(element, isModelOpen, KeyCode) {
    const key = this.toKey(element, isModelOpen, KeyCode);
    return this.shortcuts.get(key) || false;
  }

  /**
   * Adds a shortcut to the ignore list.
   * @param {String} element The name of the element that is currently selected.
   * @param {Boolean} isModelOpen If the model is open or not.
   * @param {String} KeyCode The KeyCode of the key pressed
   * @param {Boolean} isDisabled If the shortcut is disabled or not. (Defaults to true)
   * @returns {void}
   */
  add(element, isModelOpen, KeyCode, isDisabled = true) {
    const key = this.toKey(element, isModelOpen, KeyCode);
    if (isDisabled) {
      this.shortcuts.set(key, isDisabled);
    } else {
      this.shortcuts.delete(key);
    }
  }
}

/// Add the default shortcuts to ignore.
const ignoredShortcuts = new ShortcutIgnore();

for (const key of KeysIgnoredInTextArea) {
  ignoredShortcuts.add(TextAreaTagName, false, key);
  ignoredShortcuts.add(TextAreaTagName, true, key);
}
for (const key of KeysIgnoredInInput) {
  ignoredShortcuts.add(InputTagName, false, key);
  ignoredShortcuts.add(InputTagName, true, key);
}

// Disable tab shortcut for modals.
ignoredShortcuts.add(InputTagName, true, TAB);

export default ignoredShortcuts;
