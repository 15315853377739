import { toFreeText } from '../itemsState';
import { closeMenu } from '../temporaryStates';

/**
 * Creates a thunk that will convert the selected items to free text
 * @returns {function} - The thunk
 */
export const toFreeTextThunk = () => (dispatch, getState) => {
  const { selected } = getState().itemStates;
  dispatch(toFreeText(selected));
  dispatch(closeMenu());
};
