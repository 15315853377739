import axios from 'axios';
import {
  ANALYTICS_URL,
  FEEDBACK_URL,
  TUTORIAL_RATING_URL,
  BACKEND_URL,
  LOGIN_URL,
  LOGOUT_URL,
  REGISTER_URL,
  GET_USER_URL,
  CODE_EXECUTION_URL,
  EDIT_PROFILE_URL,
} from './constants/constants';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = BACKEND_URL;

/**
 * Transform an object into FormData. (Exported for testing)
 * @param {Object} object An object to be transformed into form data.
 * @returns {FormData}
 */
export const getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    formData.append(key, object[key]);
  });
  return formData;
};

/**
 * Sends analytics to the server.
 * @param {Object} data Analytics to be sent to the server
 * @returns {Response}
 */
export const sendAnalyticsToServer = (data) => {
  return axios.post(ANALYTICS_URL, data);
};

/**
 * Sends the feedback to the server and returns the response.
 * @param {Object} data feedback to be sent to the server
 * @returns {Response}
 */
export const sendFeedbackToServer = (data) =>
  axios.post(FEEDBACK_URL, getFormData(data));

/**
 * Logs the user in based on the data.
 * @param {Object} data data to be sent to the server
 * @returns {Response}
 */
export const loginToServer = (data) => axios.post(LOGIN_URL, getFormData(data));

/**
 * Registers the user based on the data.
 * @param {Object} data data to be sent to the server
 * @returns {Response}
 */
export const registerToServer = (data) =>
  axios.post(REGISTER_URL, getFormData(data));

/**
 * Logs the user out.
 * @returns {Response}
 */
export const logoutFromServer = () => axios.get(LOGOUT_URL);

/**
 * Gets the user from backend
 * @returns {Response}
 */
export const getUserFromServer = () => axios.get(GET_USER_URL);

/**
 * Sends code to the server API
 * @param {Object} data Data to send to the server
 * @returns {Response}
 */
export const sendCodeToServer = (data) => {
  return axios.post(CODE_EXECUTION_URL, getFormData(data));
};

/**
 * Sends edited profile to the server API
 * @param {Object} data Date to send to the server
 * @returns {Response}
 */
export const sendEditProfile = (data) => {
  return axios.post(EDIT_PROFILE_URL, getFormData(data));
};


/**
 * Sends the tutorial rating to the server and returns the response.
 * @param {Object} data feedback to be sent to the server
 * @returns {Response}
 */
export const sendTutorialRatingToServer = (data) => {
  return axios.post(TUTORIAL_RATING_URL, getFormData(data));
}