import { DataCollector } from './DataCollector';
import { CLICK, CODE, EVENT_KEY, KEY_PRESS } from './constants/Events';
import {
  loginToServer,
  sendFeedbackToServer,
  registerToServer,
  sendCodeToServer,
  sendEditProfile,
  sendTutorialRatingToServer,
} from './ServerInterface';

// Export for testing
export const collector = new DataCollector();

/** Sends the keypress to the server.
 * @param {string} key - The key that was pressed.
 * @param {bool} ctrlClick - If the ctrl key was pressed.
 * @param {bool} shiftClick - If the shift key was pressed.
 * @param {bool} altClick - If the alt key was pressed.
 */
export const sendKeyPress = async (
  key,
  ctrlClick = false,
  shiftClick = false,
  altClick = false,
) => {
  const data = {
    key: key,
    ctrlClick: ctrlClick,
    shiftClick: shiftClick,
    altClick: altClick,
  };
  collector.add({
    [EVENT_KEY]: KEY_PRESS,
    data: data,
  });
};

/**
 * Sends the click to the server.
 * @param {string} target - The target of the click.
 */
export const sendClicks = async (target) => {
  const data = {
    target: target,
  };
  collector.add({
    [EVENT_KEY]: CLICK,
    data: data,
  });
};

/**
 * Sends the code of the user to the server
 * @param {string} code - The code of the user.
 */
export const sendCode = async (code) => {
  const data = {
    code: code,
  };
  collector.add({
    [EVENT_KEY]: CODE,
    data: data,
  });
};

/**
 * Sends the referrer to analytics,
 * Does not send if the referrer and current domain is the same.
 * @returns {void}
 */
export const sendReferrer = () => {
  const referrer = document.referrer;
  if (referrer === null || referrer === '') return;
  const referrerURL = new URL(referrer);
  if (referrerURL.origin === location.origin) return;

  const data = {
    referrer: referrer,
  };
  collector.add({
    [EVENT_KEY]: 'referrer',
    data: data,
  });
};

/**
 * Sends the feedback to the server.
 * @param {string} name - The name of the user.
 * @param {string} email - The email of the user.
 * @param {string} message - The message of the user.
 * @returns {Promise<AxiosResponse>} The response from the server.
 */
export const sendFeedback = (name, email, message) => {
  const data = {
    name: name,
    email: email,
    message: message,
  };
  return sendFeedbackToServer(data);
};

/**
 * Logs the user in.
 * @param {string} username - The username of the user.
 * @param {string} password - The password of the user.
 * @returns {Promise<AxiosResponse>} The response from the server.
 */
export const login = (username, password) => {
  const data = {
    username: username,
    password: password,
  };
  return loginToServer(data);
};

/**
 * Registers the user.
 * @param {string} username - The username of the user.
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @returns {Promise<AxiosResponse>} The response from the server.
 */
export const register = (username, email, password, proficiency) => {
  const data = {
    username: username,
    email: email,
    password: password,
    proficiency: proficiency,
  };
  return registerToServer(data);
};

/**
 * Sends the code to the server and returns the response.
 * @param {String} code Python Code to be executed
 * @param {String} stdin Input to be given to the code
 * @returns {Response} Response from the server
 */
export const sendCodeExecution = (code, stdin) => {
  const data = {
    code: code,
    stdin: stdin,
  };
  return sendCodeToServer(data);
};

/**
 * Sends the edit profile request to the server.
 * @param {String} currentPassword Current password of the user
 * @param {String} newPassword New password of the user
 *
 * @returns {Promise<AxiosResponse>} The response from the server.
 */

export const editProfile = (currentPassword, newPassword) => {
  const data = {
    currentPassword: currentPassword,
    newPassword: newPassword,
  };
  return sendEditProfile(data);
};


/**
 * Sends the tutorial rating to the server.
 * @param {String} tutorialTitle - The title of the tutorial.
 * @param {String} rating - The rating from the user.
 * @returns {Promise<AxiosResponse>} The response from the server.
 */
export const sendTutorialRating = (tutorialTitle, rating) => {
  const data = {
    tutorialTitle: tutorialTitle,
    rating: rating,
  };
  return sendTutorialRatingToServer(data);
};
