import BlockAPI from '../BlockAPI';
import {
  THREE_VW_SIZE,
  code,
  getString,
  getTextInBox,
  markdown
} from "../../components/ComponentUtils";
import { Button } from '@material-ui/core';

// define id number
const CLASS_HEADER = 13;

// define block regex for parsing
const CLASS_HEADER_REGEX = /class +(.*) *:()()()/;
const regexArr = [CLASS_HEADER_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'class_name',
};

// define functional component for helpBox
function ClassHeaderHelpbox() {
  const lines = [`class ClassName:`, `    # Statement`];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `A class is a user-defined blueprint or prototype from which objects are created. Classes provide a means of bundling data and functionality together.`,
      )}
      {markdown(
        `Creating a new class creates a new type of object, allowing new instances of that type to be made. Each class instance can have attributes attached to it for maintaining its state. Class instances can also have methods (defined by their class) for modifying their state.`,
      )}
      {markdown(`The syntax for defining a **class** is as follows:`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const CLASS_HEADER_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      class&nbsp;
      {THREE_VW_SIZE}:
    </code>
  </Button>
);

// define input block
const classHeaderInputBlock = ({ input1_div }) => {
  return (
    <div>
      {getTextInBox('class')}&nbsp;
      {input1_div}
      {getString(':')}
    </div>
  );
};

function parsingFunction(input1) {
  return `class ${input1}:`;
}

export const ClassHeaderBlock = new BlockAPI(
  CLASS_HEADER,
  regexArr,
  PLACEHOLDERS,
  <ClassHeaderHelpbox />,
  CLASS_HEADER_SELECTION_BLOCK,
  classHeaderInputBlock,
  parsingFunction,
);
