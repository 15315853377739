import { useRef, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Modal, Box, Button, FormGroup, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../redux/modalState';
import { FEEDBACK } from '../constants/modal-type';
import { sendFeedback } from '../api/send';
import { validateFilled, validateEmail } from '../utils/Validator';
import { showSnackBarMessage } from '../redux/messageState';
import { useEffect } from 'react';
import { getUserFromServer } from '../api/ServerInterface';
import { clearAllSetter, setEmptyIfError } from '../utils/ModalUtil';
import { IconButton } from '@material-ui/core';

const style = {
  position: 'absolute',
  overflow: 'scroll',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

/**
 * The Feedback Modal element.
 * @returns {JSX.Element} - The Feedback modal.
 */
function FeedbackModal() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameErrorMsg, setNameErrorMsg] = useState('');
  const [emailErrorMsg, setEmailErrorMsg] = useState('');
  const [messageErrorMsg, setMessageErrorMsg] = useState('');

  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const messageInputRef = useRef();

  const openedModal = useSelector((state) => state.openedModal.openedModal);
  const isLoggedIn = useSelector((state) => state.authState.loggedIn);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const result = await getUserFromServer();
      setName(result.data.username);
      setEmail(result.data.email);
    };
    fetchData().catch(() => { });
  }, [isLoggedIn]);

  const submitHandler = async (event) => {
    event.preventDefault();

    const enteredName = nameInputRef.current.value;
    const enteredEmail = emailInputRef.current.value;
    const enteredMessage = messageInputRef.current.value;

    const nameError = setEmptyIfError(
      !validateFilled(enteredName),
      () => setNameErrorMsg('Please fill in your name!'),
      () => setNameErrorMsg(''),
    );
    const emailError = setEmptyIfError(
      !validateEmail(enteredEmail),
      () => setEmailErrorMsg('Please fill in a valid email!'),
      () => setEmailErrorMsg(''),
    );

    const messageError = setEmptyIfError(
      !validateFilled(enteredMessage),
      () => setMessageErrorMsg('Please fill in your feedback!'),
      () => setMessageErrorMsg(''),
    );

    if (nameError || emailError || messageError) return;

    try {
      await sendFeedback(enteredName, enteredEmail, enteredMessage);
      dispatch(showSnackBarMessage('Your feedback has been sent!'));
      dispatch(closeModal());
      clearAllSetter(setName, setEmail, setMessage);
    } catch (error) {
      dispatch(
        showSnackBarMessage("Couldn't send feedback. Please try again later.")
      );
    }
  };
  return (
    <Modal
      open={openedModal == FEEDBACK}
      onClose={() => dispatch(closeModal())}
    >
      <Box sx={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Feedback Form
          </Typography>
          <IconButton data-testid="close-icon-button" size="small" onClick={() => dispatch(closeModal())}>
            <i className="fa fa-close" />
          </IconButton>
        </div>
        <h4>
          Take 3 minutes to fill in this <a
            href="https://forms.gle/Y8DkuoX9grcodi7i7"
            target="_blank"
            rel="noopener noreferrer"
          >google survey</a> or write in to us below!
        </h4>
        <Typography
          component={'span'}
          id="modal-modal-description"
          sx={{ mt: 2 }}
        >
          <FormGroup className="mb-3">
            <TextField
              id="outlined-required-name"
              label="Name"
              variant="outlined"
              inputRef={nameInputRef}
              value={name}
              disabled={isLoggedIn}
              onChange={(event) => setName(event.target.value)}
              error={nameErrorMsg != ''}
              helperText={
                nameErrorMsg === '' ? 'Fill in your name' : nameErrorMsg
              }
            />
          </FormGroup>
          <br />
          <FormGroup className="mb-3">
            <TextField
              id="outlined-required-email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              inputRef={emailInputRef}
              disabled={isLoggedIn}
              error={emailErrorMsg !== ''}
              helperText={
                emailErrorMsg === '' ? 'Fill in your email' : emailErrorMsg
              }
            />
          </FormGroup>
          <br />
          <FormGroup className="mb-3">
            <TextField
              multiline
              maxRows={15}
              minRows={7}
              id="outlined-multiline-flexible"
              label="Message"
              variant="outlined"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              error={messageErrorMsg !== ''}
              inputRef={messageInputRef}
              helperText={
                messageErrorMsg === ''
                  ? 'Fill in your feedback'
                  : messageErrorMsg
              }
            />
          </FormGroup>

          <Button variant="contained" onClick={submitHandler}>
            Submit
          </Button>
        </Typography>
      </Box>
    </Modal>
  );
}

export default FeedbackModal;
