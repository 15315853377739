import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const SET_POP = 48;

// define block regex for parsing
const SET_POP_REGEX = /(.*) *= *(.*) *\. *pop *\( *\)()()/;
const regexArr = [ SET_POP_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'return_value',
  input2: 'set',
}

// define functional component for helpBox
function SetPopHelpbox() {
  const lines = [
    `fruits = {"apple", "banana", "cherry"} # create a new set`,
    ``,
    `x = fruits.pop() # remove and returns a random element`,
    ``,
    `print(x) # 'cherry'`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The **pop()** method removes a *random* item from the set.

          This method returns the removed item.`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const SET_POP_SELECTION_BLOCK =
  (
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <code>
        {ONE_VW_SIZE} = {TWO_VW_SIZE}.pop()
      </code>
    </Button>
  );

// define input block
const SetPopInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {input2_div}
      {getString('.')}pop()
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1} = ${input2}.pop()`;
}

export const SetPopBlock = new BlockAPI(SET_POP,
  regexArr,
  PLACEHOLDERS,
  <SetPopHelpbox />,
  SET_POP_SELECTION_BLOCK,
  SetPopInputBlock,
  parsingFunction);