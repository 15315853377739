import { onClear } from '../itemsState';
import { setPythonCodeBoxContentFromItems } from '../itemsState';
import { showSnackBarMessage } from '../messageState';

/**
 * Returns the thunk that clears all items.
 * @returns {function}
 */
export const onClearThunk = () => (dispatch, getState) => {
  dispatch(onClear());
  dispatch(setPythonCodeBoxContentFromItems());
  dispatch(showSnackBarMessage('Cleared all items'));
};
