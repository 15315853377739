import { indentItems, unindentItems } from '../../utils/IndentUtil';
import { closeMenu } from '../temporaryStates';
import { setItems } from '../itemsState';

/**
 * Returns the thunk that indents the selected items.
 * @returns {function}
 */
export const indentThunk = () => (dispatch, getState) => {
  const state = getState();
  const { items, selected } = state.itemStates;

  dispatch(closeMenu());
  dispatch(setItems(indentItems(items, selected)));
};

/**
 * Returns the thunk that unindents the selected items.
 * @returns {function}
 */
export const unindentThunk = () => (dispatch, getState) => {
  const state = getState();
  const { items, selected } = state.itemStates;

  dispatch(closeMenu());
  dispatch(setItems(unindentItems(items, selected, true)));
};
