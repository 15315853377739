import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const SET_INTERSECTION = 47;

// define block regex for parsing
const SET_INTERSECTION_REGEX = /(.*) *= *(.*) *\. *intersection *\((.*)\)()/;;
const regexArr = [ SET_INTERSECTION_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'set_name',
  input2: 'set1',
  input3: 'set2, ...'
}

// define functional component for helpBox
function SetIntersectionHelpbox() {
  const lines = [
    `x = {"apple", "banana", "cherry"} # set 1`,
    `y = {"google", "microsoft", "apple"} # set 2`,
    ``,
    `z = x.intersection(y)`,
    ``,
    `print(z) # {'apple'}`
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`The **intersection()** method returns a set that contains the similarity between two or more sets.`)}
      {markdown(`Meaning: The returned set contains only items that exist in both sets, or in all sets if the comparison is done with more than two sets.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const SET_INTERSECTION_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {ONE_VW_SIZE} = {TWO_VW_SIZE}.intersection({ONE_VW_SIZE})
    </code>
  </Button>
);

// define input block
const SetIntersectionInputBlock = ({ input1_div, input2_div, input3_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {input2_div}
      {getString('.')}intersection{getString('(')}
      {input3_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2, input3) {
  return `${input1} = ${input2}.intersection(${input3})`;
}

export const SetIntersectionBlock = new BlockAPI(SET_INTERSECTION,
  regexArr,
  PLACEHOLDERS,
  <SetIntersectionHelpbox />,
  SET_INTERSECTION_SELECTION_BLOCK,
  SetIntersectionInputBlock,
  parsingFunction);