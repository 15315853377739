import { createSlice } from '@reduxjs/toolkit';
import {
  DEFAULT_I,
  DEFAULT_ITEMS,
  DEFAULT_HOVER_VALUE,
  DEFAULT_SELECTED_VALUE,
  DEFAULT_PYTHON_EDITOR,
  DEFAULT_COPIED_ELEMENT,
} from '../constants/default-values';
import { parseTree, getFileOutput } from '../utils/Parsing';
import { copyJSON } from '../utils/JSON';
import { findPath } from '../utils/Path';
import { PYTHON_EDITOR } from '../constants/block-types';
import astParser from '../utils/AstParser';
import { cloneElem } from '../utils/Cloner';
import { loadFromExample } from '../constants/examples';
import { getNextSelectionIndex } from '../utils/ItemSelector';
import { FreeTextBlock } from '../blocks/others/FreeText';
import { createBlock } from '../utils/BlockUtil';

//hidden
const MAXIMUM_HISTORY_LENGTH = 100;
let selectedHistory = [];
let history = [];
let stateHead = -1;

// Export for testing
export const resetHistory = () => {
  history = [];
  stateHead = -1;
};

const addToHistory = (items, selected) => {
  if (
    stateHead != -1 &&
    JSON.stringify(items) == JSON.stringify(history[stateHead])
  ) {
    return;
  }
  stateHead = stateHead + 1;
  history.splice(stateHead, history.length, items);
  selectedHistory.splice(stateHead, selectedHistory.length, selected);
  if (history.length > MAXIMUM_HISTORY_LENGTH) {
    history.shift(); // remove first element in history (FIFO)
    selectedHistory.shift();
  }
};

function addBlockBehindItemId(i, blockType, block_id, stateItems) {
  // code for dragging logical statement (case 5) and drop to if statement
  const newIndex = i++;
  const newItem = createBlock(newIndex, blockType);

  // add block after the block that was dragged on
  const [newId, newItems] = addElemBehindItemId(newItem, block_id, stateItems);
  return [i, newId, newItems];
}

function addElemBehindItemId(elem, blockId, stateItems) {
  // code for dragging logical statement (case 5) and drop to if statement
  const newItems = copyJSON(stateItems);
  const path = findPath(newItems, blockId);
  let curr = newItems;
  for (let i = 0; i < path.length - 1; ++i) {
    curr = curr[path[i]].children;
  }
  curr.splice(path[path.length - 1] + 1, 0, elem); // add block after the block that was dragged on
  addToHistory(newItems, elem.id);
  return [elem.id, newItems];
}

function addBlockToEnd(i, blockType, stateItems) {
  const newIndex = i++;
  const newItem = createBlock(newIndex, blockType); // add block after the block that was dragged on
  const [newId, newItems] = addElemToEnd(newItem, stateItems);
  return [i, newId, newItems];
}

function addElemToEnd(elem, stateItems) {
  const newItems = copyJSON(stateItems);
  newItems.push(elem); // add block after the block that was dragged on
  addToHistory(newItems, elem.id);
  return [elem.id, newItems];
}
export const itemInitialState = {
  //public
  isPythonTutorOn: false,
  items: DEFAULT_ITEMS,
  i: DEFAULT_I,
  pythonCodeBoxContent: null,
  showPythonInterface: false,
  showBoxes: false,
  showIndent: false,
  hovering: DEFAULT_HOVER_VALUE, // hovering block type
  copiedElement: DEFAULT_COPIED_ELEMENT,
  selected: DEFAULT_SELECTED_VALUE,

  // Border Related
  borderToBeUpdated: false,
  showBoxes: false,
  showCopiedBox: false,
  showIndent: false,
};
export const itemStateSlice = createSlice({
  name: 'items state',
  initialState: { ...itemInitialState },
  reducers: {
    setShowCopiedBorder: (state, action) => {
      return {
        ...state,
        showCopiedBox: action.payload,
      };
    },
    toggleShowIndent: (state) => {
      return {
        ...state,
        showIndent: !state.showIndent,
        showBoxes: false,
        borderToBeUpdated: true,
      };
    },
    borderUpdateRequired: (state) => {
      return {
        ...state,
        borderToBeUpdated: true,
      };
    },
    borderUpdated: (state) => {
      return {
        ...state,
        borderToBeUpdated: false,
      };
    },
    borderNeedsUpdate: (state) => {
      return {
        ...state,
        borderToBeUpdated: true,
      };
    },
    toggleCodeBlocks: (state) => {
      return {
        ...state,
        showBoxes: !state.showBoxes,
        showIndent: false,
        borderToBeUpdated: true,
      };
    },
    togglePythonTutor: (state) => {
      return {
        ...state,
        isPythonTutorOn: !state.isPythonTutorOn,
        borderToBeUpdated: true,
      };
    },
    closePythonTutor: (state) => {
      return {
        ...state,
        borderToBeUpdated: true,
        isPythonTutorOn: false,
      };
    },
    setItems: (state, action) => {
      if (state.isPythonTutorOn) return state;
      const newState = {
        ...state,
        borderToBeUpdated: true,
        items: action.payload,
      };
      addToHistory(newState.items, state.selected);
      return newState;
    },
    setI: (state, action) => {
      return {
        ...state,
        i: action.payload,
      };
    },
    setPythonCodeBoxContentFromItems: (state) => {
      return {
        ...state,
        pythonCodeBoxContent: getFileOutput(
          parseTree({
            items: state.items,
          }),
        ),
      };
    },
    setPythonCodeBoxContent: (state, action) => {
      return {
        ...state,
        pythonCodeBoxContent: action.payload,
      };
    },
    removeLine: (state, action) => {
      const item_id = action.payload;
      if (item_id === DEFAULT_SELECTED_VALUE || state.items.length === 0)
        return { ...state };
      const newItems = copyJSON(state.items);
      const path = findPath(newItems, item_id);
      if (path.length === 0) return { ...state };

      let curr = newItems;
      for (let i = 0; i < path.length - 1; ++i) {
        curr = curr[path[i]].children;
      }
      const children = curr[path[path.length - 1]].children;
      const childrenLen = children?.length ?? 0;
      const nextSelected =
        childrenLen === 0
          ? getNextSelectionIndex(newItems, item_id)
          : children[0].id;

      const spliceValue = curr[path[path.length - 1]]?.children ?? [];
      curr.splice(path[path.length - 1], 1, ...spliceValue);
      addToHistory(newItems, nextSelected);
      return {
        ...state,
        borderToBeUpdated: true,
        items: newItems,
        selected: nextSelected,
      };
    },
    removeBlock: (state, action) => {
      const item_id = action.payload;
      if (item_id === DEFAULT_SELECTED_VALUE || state.items.length === 0)
        return { ...state };

      const newItems = copyJSON(state.items);
      const nextSelected = getNextSelectionIndex(newItems, item_id);
      const path = findPath(newItems, item_id);
      let curr = newItems;
      for (let i = 0; i < path.length - 1; i++) {
        curr = curr[path[i]].children;
      }
      curr.splice(path[path.length - 1], 1); // remove block at the specified path
      addToHistory(newItems, state.selected);
      return {
        ...state,
        items: newItems,
        borderToBeUpdated: true,
        selected: nextSelected,
      };
    },
    undoStateHistory: (state) => {
      if (stateHead <= 0) {
        // cannot undo since we only have current history
        return state;
      }
      stateHead = stateHead - 1;
      const previousHead = stateHead;
      const prevHistory = history[previousHead];
      const prevSelected = selectedHistory[previousHead];
      const newItems = prevHistory;
      return {
        ...state,
        items: newItems,
        selected: prevSelected,
        borderToBeUpdated: true,
        isPythonTutorOn: false,
        pythonCodeBoxContent: getFileOutput(
          parseTree({
            items: newItems,
          }),
        ),
      };
    },
    redoStateHistory: (state) => {
      if (history.length - 1 <= stateHead) {
        // cannot redo since no redo states
        return state;
      }

      stateHead = stateHead + 1;
      const redoState = history[stateHead];
      const newItems = redoState;
      const newSelected = selectedHistory[stateHead];
      return {
        ...state,
        borderToBeUpdated: true,
        items: newItems,
        selected: newSelected,
        isPythonTutorOn: false,
        pythonCodeBoxContent: getFileOutput(
          parseTree({
            items: newItems,
          }),
        ),
      };
    },
    setHoveringValue: (state, action) => {
      return {
        ...state,
        hovering: action.payload,
      };
    },
    togglePythonInterface: (state) => {
      const newState = {
        ...state,
        borderToBeUpdated: true,
      };
      if (!state.showPythonInterface) {
        newState.showPythonInterface = true;
        newState.hovering = PYTHON_EDITOR;
        newState.pythonCodeBoxContent = getFileOutput(
          parseTree({
            items: state.items,
          }),
        );
        return newState;
      }

      const [isSuccessful, newItems, errorMsg] = astParser(
        newState.pythonCodeBoxContent,
      );
      if (!isSuccessful) {
        alert(errorMsg);
        return newState;
      }
      newState.showPythonInterface = false;
      newState.hovering = DEFAULT_HOVER_VALUE;
      newState.items = newItems;
      return newState;
    },
    setCopiedElement: (state, action) => {
      return {
        ...state,
        copiedElement: action.payload,
      };
    },
    onCopy: (state) => {
      const id = state.selected;
      if (id === DEFAULT_SELECTED_VALUE) return { ...state };

      const newItems = copyJSON(state.items);
      const path = findPath(newItems, id);
      const newState = {
        ...state,
        showCopiedBox: true,
        borderToBeUpdated: true,
      };
      if (path.length === 0) {
        newState.selected = DEFAULT_SELECTED_VALUE;
        newState.copiedElement = DEFAULT_COPIED_ELEMENT;
        return newState;
      }
      let selectedItem = { children: newItems };
      for (let i = 0; i < path.length; ++i) {
        selectedItem = selectedItem.children[path.at(i)];
      }
      newState.isPythonTutorOn = false;
      newState.copiedElement = selectedItem;
      return newState;
    },
    onPaste: (state) => {
      if (state.copiedElement === DEFAULT_COPIED_ELEMENT) {
        return { ...state };
      }

      /// Clone and assign new IDs
      const [newCopiedElement, newI] = cloneElem(state.copiedElement, state.i);
      const id = state.selected;
      if (id === -1) {
        const [newSelected, newItems] = addElemToEnd(
          newCopiedElement,
          state.items,
          state.selected,
        );
        return {
          ...state,
          borderToBeUpdated: true,
          showCopiedBox: false,
          i: newI,
          selected: newSelected,
          items: newItems,
        };
      }
      const [newSelected, newItems] = addElemBehindItemId(
        newCopiedElement,
        state.selected,
        state.items,
      );
      return {
        ...state,
        borderToBeUpdated: true,
        showCopiedBox: false,
        selected: newSelected,
        items: newItems,
        isPythonTutorOn: false,
        i: newI,
      };
    },
    setSelectedItem: (state, action) => {
      return {
        ...state,
        selected: action.payload,
      };
    },
    onDrop: (state, action) => {
      const { blockType, itemId } = action.payload;
      const [newI, newSelected, newItem] = addBlockBehindItemId(
        state.i,
        blockType,
        itemId,
        state.items,
      );
      return {
        ...state,
        i: newI,
        borderToBeUpdated: true,
        selected: newSelected,
        items: newItem,
      };
    },
    addBlock: (state, action) => {
      const type = action.payload;
      let newI, newSelected, newItem;
      if (state.selected === DEFAULT_SELECTED_VALUE) {
        [newI, newSelected, newItem] = addBlockToEnd(
          state.i,
          type,
          state.items,
        );
      } else {
        [newI, newSelected, newItem] = addBlockBehindItemId(
          state.i,
          type,
          state.selected,
          state.items,
        );
      }
      return {
        ...state,
        i: newI,
        borderToBeUpdated: true,
        selected: newSelected,
        items: newItem,
      };
    },
    onDropCodeBox: (state, action) => {
      const { blockType } = action.payload;
      const [newI, newSelected, newItem] = addBlockToEnd(
        state.i,
        blockType,
        state.items,
        state.selected,
      );
      return {
        ...state,
        i: newI,
        borderToBeUpdated: true,
        selected: newSelected,
        items: newItem,
      };
    },
    loadExample: (state, action) => {
      const index = action.payload;
      const [items, i] = loadFromExample(index);
      addToHistory(items, DEFAULT_SELECTED_VALUE);
      return {
        ...state,
        i: i,
        items: items,
        borderToBeUpdated: true,
        selected: DEFAULT_SELECTED_VALUE,
        pythonCodeBoxContent: getFileOutput(
          parseTree({
            items: items,
          }),
        ),
      };
    },
    toFreeText: (state, action) => {
      const selected = action.payload;
      if (selected === DEFAULT_SELECTED_VALUE) return { ...state };

      const newItems = copyJSON(state.items);
      const path = findPath(newItems, selected);
      let selectedItem = { children: newItems };
      for (let i = 0; i < path.length; ++i) {
        selectedItem = selectedItem.children[path.at(i)];
      }
      const output = getFileOutput(
        parseTree({
          items: [
            {
              ...selectedItem,
              children: [],
            },
          ],
        }),
      ).trim();
      selectedItem.input1 = output;
      selectedItem.type = FreeTextBlock.getType();
      addToHistory(newItems, selected);
      return {
        ...state,
        borderToBeUpdated: true,
        items: newItems,
        selected: selected,
      };
    },
    onClear: (state) => {
      addToHistory(DEFAULT_ITEMS, DEFAULT_SELECTED_VALUE);
      return {
        ...state,
        isPythonTutorOn: false,
        selected: DEFAULT_SELECTED_VALUE,
        borderToBeUpdated: true,
        items: DEFAULT_ITEMS,
        i: DEFAULT_I,
        pythonCodeBoxContent: DEFAULT_PYTHON_EDITOR,
      };
    },
  },
});

export const {
  togglePythonTutor,
  closePythonTutor,
  setItems,
  setI,
  setPythonCodeBoxContentFromItems,
  setPythonCodeBoxContent,
  removeLine,
  removeBlock,
  undoStateHistory,
  redoStateHistory,
  setHoveringValue,
  togglePythonInterface,
  setCopiedElement,
  onCopy,
  onPaste,
  setSelectedItem,
  onDrop,
  addBlock,
  onDropCodeBox,
  loadExample,
  onClear,
  toggleCodeBlocks,
  borderUpdated,
  borderNeedsUpdate,
  setShowCopiedBorder,
  toFreeText,
  borderUpdateRequired,
  toggleShowIndent,
} = itemStateSlice.actions;
export default itemStateSlice.reducer;
