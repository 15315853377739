import astParser from '../utils/AstParser';
import { DEFAULT_I } from './default-values';

// Exported for testing
export class CodeExample {
  /**
   * A code example for the user.
   * @param {String} name - The name of the example.
   * @param {String} code - The code for the example.
   */
  constructor(name, code) {
    this.name = name;
    this.code = code;
    this.cache = [];
  }

  /**
   * Returns the name of the example.
   * @returns {String} the name of the example
   */
  getName() {
    return this.name;
  }

  /**
   * Returns the items within the example.
   * @returns {Array}
   */
  getItems() {
    if (this.cache.length > 0) return this.cache;

    const [isSuccess, items, _] = astParser(this.code);
    if (!isSuccess) return [];

    this.cache = items;
    return items;
  }
}

const FIBONACCI = `
def fibonacci(n):
    if (n <= 1):
        return n
    return fibonacci(n-1) + fibonacci(n-2)
print(fibonacci(5))
`;

const FACTORIAL = `
def factorial(n):
    if (n == 0 or n == 1):
        return 1
    return n * factorial(n-1)
print(factorial(8))
`;
const FIZZ_BUZZ = `
# Fizz Buzz - print integers from 1 to N
# but, print "Fizz" if an integer is divisible by 3,
# print “Buzz” if an integer is divisible by 5,
# print "FizzBuzz" if an integer is divisible by both 3 and 5
N=20
for i in range(1, N+1):
    if (i % 3 == 0 and i % 5 == 0):
        print("FizzBuzz")
    elif (i % 3 == 0):
        print("Fizz")
    elif (i % 5 == 0):
        print("Buzz")
    else:
        print(i)
`;

const MAP_N_FILTER = `
# Given a list, get the square of all odd numbers in the list
lst = [1, 4, 3, 7, 8, 9]
odd_numbers = list(filter(lambda x: x % 2 == 1, lst))
squared = list(map(lambda x: x ** 2, odd_numbers))
print(odd_numbers)
print(squared)
`;

const HELLO_WORLD = `print('Hello World!')`;

const GCD = `
def get_gcd(a, b):
    while (b > 0):
        modulo = a % b
        a = b
        b = modulo
    return a
print(get_gcd(10, 3))
print(get_gcd(10, 25))
`;

const MIN_LIST = `
def get_minimum(lst):
    minimum = float('inf')
    for i in range(len(lst)):
        if (lst[i] < minimum):
            minimum = lst[i]
    return minimum
lst = [7, 14, 3, 0, 8, 2]
print(get_minimum(lst))
`;

const SUM_LIST = `
def get_total(lst):
    total = 0
    for i in range(len(lst)):
        total += lst[i]
    return total
lst = [4, 1, 3, 9]
print(get_total(lst))
`;

const TWO_SUM = `
# Find two numbers in the list that add up to a target number.
# O(n^2) solution, where n is the length of the list.
def two_sum(lst, target):
    for i in range(len(lst)):
        for j in range(i):
            if (lst[i] + lst[j] == target):
                return [lst[i], lst[j]]
    return -1
lst = [4, -10, 7, 1, 2]
print(two_sum(lst, 11))
`;

const PYRAMID_PRINT = `
# Challenge: print the numbers as below
# 1
# 2 3
# 4 5 6
# 7 8 9 10
# etc
N = 4
curr = 1
for i in range(1, N+1):
    for j in range(1, i+1):
        print(curr, end = ' ')
        curr += 1
    print()
# There is a trailing whitespace at the end of each line.
# How would you fix it?`;

export const allExampleItems = [
  new CodeExample('Hello World', HELLO_WORLD),
  new CodeExample('Fibonacci', FIBONACCI),
  new CodeExample('Factorial', FACTORIAL),
  new CodeExample('Fizz Buzz', FIZZ_BUZZ),
  new CodeExample('Map & Filter', MAP_N_FILTER),
  new CodeExample('Greatest Common Denominator', GCD),
  new CodeExample('Find Minimum number in a list', MIN_LIST),
  new CodeExample('Find Sum of a list', SUM_LIST),
  new CodeExample('Find 2 numbers that add up to a target', TWO_SUM),
  new CodeExample('Print numbers in a pyramid', PYRAMID_PRINT),
];

export const TOUR_EXAMPLE = 0;

/**
 * Loads the example at the given index.
 * @param {Number} index - the index of the example.
 * @returns {[Array, Number]} [items, max_index]  of the items in the example.
 */
export const loadFromExample = (index) => {
  /// Load example out of range
  if (index >= allExampleItems.length || index < 0) {
    return [[], DEFAULT_I];
  }
  const codeExample = allExampleItems[index];
  const items = codeExample.getItems();
  return [items, DEFAULT_I];
};
