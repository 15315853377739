// List of colors for the borders
export const BLACK = 'black';
export const possibleColors = [
  'red',
  'blue',
  'green',
  'gray',
  BLACK,
  'darkblue',
  'darkcyan',
  'darkgreen',
  'darkmagenta',
  'darkred',
  'darkyellow',
];
