import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const CAST_STRING = 54;

// define block regex for parsing
const CAST_STRING_REGEX = /(.*) *= *str *\((.*)\) *()()/;
const regexArr = [CAST_STRING_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'variable',
  input2: 'input',
}

// define functional component for helpBox
function CastStringHelpbox() {
  const lines = [
    `x = str("s1") # x will be 's1'`,
    `y = str(2)    # y will be '2'`,
    `z = str(3.0)  # z will be '3.0'`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `constructs a string from a wide variety of data types, including strings, integer literals and float literals`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const CAST_STRING_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE} = str({TWO_VW_SIZE})
    </code>
  </Button>
);

// define input block
const CastStringInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {getString('str')}
      {getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1} = str(${input2})`;
};

export const CastStringBlock = new BlockAPI(CAST_STRING,
  regexArr,
  PLACEHOLDERS,
  <CastStringHelpbox />,
  CAST_STRING_SELECTION_BLOCK,
  CastStringInputBlock,
  parsingFunction);