import { Introduction } from './tutorials/Introduction';
import { Usage } from './tutorials/UsageTutorial';
import { Chapter1Program } from './tutorials/1.Your First Program.js';
import { Chapter2Syntax } from './tutorials/2.Syntax';
import { Chapter3Keywords } from './tutorials/3.Keywords';
import { Chapter4Variables } from './tutorials/4.Variables';
import { Chapter5Identifiers } from './tutorials/5.Identifiers';
import { Chapter6DataTypes } from './tutorials/6.Data Types';
import { Chapter7Operators } from './tutorials/7.Operators';
import { Chapter8Builtins } from './tutorials/8.Builtin Functions';
import { Chapter9Conditionals } from './tutorials/9.Conditionals';
import { Chapter10Loops } from './tutorials/10.Loops';
import { Chapter11Functions } from './tutorials/11.Functions';
import { Chapter12Modules } from './tutorials/12.Modules';
import { Chapter13Exceptions } from './tutorials/13.Exceptions';
import { Chapter14OOP } from './tutorials/14.OOP';

export const allTutorials = [
  Introduction,
  Usage,
  Chapter1Program,
  Chapter2Syntax,
  Chapter3Keywords,
  Chapter4Variables,
  Chapter5Identifiers,
  Chapter6DataTypes,
  Chapter7Operators,
  Chapter8Builtins,
  Chapter9Conditionals,
  Chapter10Loops,
  Chapter11Functions,
  Chapter12Modules,
  Chapter13Exceptions,
  Chapter14OOP,
];

/**
 * Registers all tutorials for a particular tutorial manager.
 * @param {TutorialManager} tutMgr - The tutorial manager to register all tutorials to.
 * @returns {void}
 */
export const registerAllTutorials = (tutMgr) => {
  tutMgr.reset();
  allTutorials.forEach((tutorial) => tutMgr.registerTutorial(tutorial));
};
