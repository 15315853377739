import BlockAPI from '../BlockAPI';
import {
  md,
} from '../BlockAPI';
import {
  HALF_VW_SIZE,
  ONE_VW_SIZE,
  THREE_VW_SIZE,
  TWO_VW_SIZE,
  code,
  getAssignmentStatements,
  getString,
  getTextInBox,
  markdown
} from "../../components/ComponentUtils";
import { Button } from '@material-ui/core';

// define id number
const CLASS_INIT = 14;

// define block regex for parsing
const CLASS_INIT_REGEX = /def +__init__ *\( *self *, *(.*)\) *:()()()/;
const regexArr = [CLASS_INIT_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'param',
};

// define functional component for helpBox
function ClassInitHelpbox() {
  const lines = [
    `# Sample class with init method`,
    `class Person:`,
    `    # init method or constructor`,
    `    def __init__(self, name)`,
    `        self.name = name`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Constructors are used to initialize the object’s state. Like methods, a constructor also contains a collection of statements(i.e. instructions) that are executed at the time of Object creation. It runs as soon as an object of a class is instantiated. The method is useful to do any initialization you want to do with your object.`,
      )}
      {markdown(
        `Class methods must have an extra first parameter in the method definition. We do not give a value for this parameter when we call the method, Python provides it.`,
      )}
      {markdown(
        `When we call a method of this object as myobject.method(arg1, arg2), this is automatically converted by Python into MyClass.method(myobject, arg1, arg2) – this is all the special self is about.`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const CLASS_INIT_SELECTION_BLOCK = (
  <div>
    <Button
      style={{ textTransform: 'lowercase' }}
      variant="contained"
      size="small"
    >
      <code>def&nbsp;__init__(self, {THREE_VW_SIZE}):</code>
    </Button>
  </div>
);

// define input block
const classInitInputBlock = ({ input1_div }) => {
  return (
    <div>
      {getTextInBox('def')}&nbsp;
      {getString('__init__(self,')} {input1_div}
      {getString(')')}
      {getString(':')}
    </div>
  );
};

function parsingFunction(input1) {
  return `def __init__(self, ${input1}):`;
}

export const ClassInitBlock = new BlockAPI(
  CLASS_INIT,
  regexArr,
  PLACEHOLDERS,
  <ClassInitHelpbox />,
  CLASS_INIT_SELECTION_BLOCK,
  classInitInputBlock,
  parsingFunction,
);
