import BlockAPI from "../BlockAPI";
import { THREE_VW_SIZE, code, getAssignmentStatements, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const DIVIDE = 7;

// define block regex for parsing
const DIVIDE_REGEX = /(.*)\/=(.*)()()/;
const regexArr = [ DIVIDE_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'i',
  input2: '2',
  operator: '/=',
}

// define functional component for helpBox
function DivideHelpbox() {
  const lines = [
    `a = 10 # initializes variable a to the value 10`,
    `a /= 5 # divides variable a by 5`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Divide left operand with right operand and then assign to left operand`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const DIVIDE_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {THREE_VW_SIZE} /= {THREE_VW_SIZE}
    </code>
  </Button>
);

function parsingFunction(input1, input2) {
  return `${input1} /= ${input2}`;
}

export const DivideBlock = new BlockAPI(DIVIDE, regexArr, PLACEHOLDERS, <DivideHelpbox />, DIVIDE_SELECTION_BLOCK, getAssignmentStatements('/='), parsingFunction);