import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const PRINT_STATEMENT = 38;

// define block regex for parsing
const PRINT_STATEMENT_REGEX = /print *\((.*)\)()()()/;
const regexArr = [ PRINT_STATEMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'x',
}

// define functional component for helpBox
function PrintStatementHelpbox() {
  const lines = [
    `print("Show this message") # This command will output "Show this message"`,
    ``,
    `x = 1 # Initializes a variable x to 1`,
    `print(x) # The object x will be printed out and the screen will reflect the value 1`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The **print()** function prints the specified message to the screen, or other standard output device. The message can be a string, or any other object, the object will be converted into a string before written to the screen.`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const PRINT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>print({TWO_VW_SIZE})</code>
  </Button>
);
// define input block
const printInputBlock = ({ input1_div }) => (
  <div>
    {getTextInBox('print')}
    {getString('(')}
    {input1_div}
    {getString(')')}
  </div>
);

function parsingFunction(input1) {
  return `print(${input1})`;
}

export const PrintBlock = new BlockAPI(PRINT_STATEMENT,
  regexArr,
  PLACEHOLDERS,
  <PrintStatementHelpbox />,
  PRINT_SELECTION_BLOCK,
  printInputBlock,
  parsingFunction);