import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_IS_FIRST_TIME } from '../constants/default-values';

export const temporaryInitialState = {
  isFirstTime: DEFAULT_IS_FIRST_TIME, // is first time visiting the website
  showMenu: false,
  anchorPoint: { x: 0, y: 0 },
};

export const temporaryStateSlice = createSlice({
  name: 'Temporary States',
  initialState: {
    ...temporaryInitialState,
  },
  reducers: {
    setIsFirstTime: (state, action) => {
      return {
        ...state,
        isFirstTime: action.payload,
      };
    },

    closeMenu: (state) => {
      return {
        ...state,
        showMenu: false,
      };
    },
    openMenu: (state) => {
      return {
        ...state,
        showMenu: true,
      };
    },
    setAnchorPoint: (state, action) => {
      return {
        ...state,
        anchorPoint: action.payload,
      };
    },
  },
});

export const { setIsFirstTime, closeMenu, openMenu, setAnchorPoint } =
  temporaryStateSlice.actions;
export default temporaryStateSlice.reducer;
