import { useState, useEffect } from 'react';
import Tour from 'reactour';
import { tutorialManager } from './TutorialManager';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { closeModal } from '../redux/modalState';
import {
  setCurrentStep,
  setCurrTutorialNo,
  setCodeExecutionSatisfied,
  setRunOnStep,
} from '../redux/tutorialState';
import { showSnackBarMessage } from '../redux/messageState';
import { TUTORIAL } from '../constants/modal-type';
import astParser from '../utils/AstParser';
import {
  setItems,
  setPythonCodeBoxContentFromItems,
} from '../redux/itemsState';
import { Button, Fab, IconButton } from '@material-ui/core';
import KeyboardMap from '../utils/KeyboardMaps';
import { trackPromise } from 'react-promise-tracker';
import { handleCodeExecution } from './utils/HandleCodeExecution';
import { getFileOutput, parseTree } from '../utils/Parsing';
import { defaultCondition, defaultFunction, defaultOnStep } from './Tutorial';
import { EMPTY_STEP } from './utils/CreateStep';
import { DEFAULT_TUTORIAL_NO } from '../constants/default-values';

const defaultRestorePage = -1;

/**
 * A Tutorial Block for rendering any tutorial based on current configuration
 * in the redux state.
 * @param {KeyboardMap} keyboardMap - Keyboard map for the tutorial
 * @returns {JSX.Element} TutorialBlock
 */
export const TutorialBlock = ({ keyboardMap }) => {
  const store = useStore();
  const dispatch = useDispatch();
  const isModalOpen =
    useSelector((state) => state.openedModal.openedModal) === TUTORIAL;
  const hovering = useSelector((state) => state.itemStates.hovering);
  const currTutStep = useSelector((state) => state.tutorialState.currentStep);
  const items = useSelector((state) => state.itemStates.items);
  const hasRunOnStep = useSelector((state) => state.tutorialState.onStepHasRun);
  const isCodeExecutionSatisfied = useSelector(
    (state) => state.tutorialState.isCodeExecutionSatisfied,
  );
  const isPythonTutorOn = useSelector(
    (state) => state.itemStates.isPythonTutorOn,
  );
  const currTutorialNo = useSelector(
    (state) => state.tutorialState.currTutorialNo,
  );

  const [nextStep, setNextStep] = useState(undefined);

  // Also used to store the page to restore to
  const [isMinimized, setMinimized] = useState(false);
  const [restorePage, setRestorePage] = useState(defaultRestorePage);

  const currTutorial = tutorialManager.getTutorialByNo(currTutorialNo);
  const codeString =
    getFileOutput(
      parseTree({
        items: items,
      }),
    ) ?? '';

  const submitBtn = (
    <Button
      onClick={() => {
        trackPromise(handleCodeExecution(codeString, currStep.testCases)).then(
          (result) => {
            const [hasPassed, errorMsg] = result;
            dispatch(setCodeExecutionSatisfied(hasPassed));
            if (!hasPassed) dispatch(showSnackBarMessage(errorMsg));
          },
        );
      }}
      style={{
        backgroundColor: `rgb(28, 56, 121)`,
        color: 'white',
        marginLeft: '2px',
      }}
    >
      Submit
    </Button>
  );

  const rawSteps = currTutorial?.getSteps() ?? [];
  const steps = rawSteps.map((step, step_no) => {
    const code = step.resetCode;
    const isCodeStep = code !== undefined || step.testCases.length !== 0;
    const [isSuccess, items, errMsg] = astParser(code ?? '');
    if (!isSuccess) throw new Error(`Error resetting to resetCode: ${errMsg}`);
    const resetBtn = (
      <Button
        onClick={() => {
          dispatch(setItems(items));
          dispatch(setPythonCodeBoxContentFromItems());
          if (step.testCases.length > 0)
            dispatch(setCodeExecutionSatisfied(false));
          dispatch(showSnackBarMessage('Reset to initial state.'));
        }}
        style={{
          backgroundColor: `rgb(28, 56, 121)`,
          color: 'white',
        }}
      >
        Reset
      </Button>
    );
    return {
      ...step,
      style: {
        // Background is replaceable by other styles
        background: isCodeStep ? 'rgba(255, 255, 255, 0.3)' : 'white',
        ...step.style,
      },
      content: () => (
        <div>
          <IconButton
            title="Minimize"
            data-testid="minimize-btn"
            onClick={() => {
              setRestorePage(step_no);
              setMinimized(true);
            }}
            style={{
              padding: '0px',
              fontSize: '1em',
              position: 'absolute',
              top: '20px',
              right: '40px',
            }}
            size="small"
            className="fa fa-sharp fa-minus"
          />
          {step.content()}
          {isCodeStep ? resetBtn : <></>}
          {step.testCases.length > 0 ? submitBtn : <></>}
        </div>
      ),
    };
  });

  if (isModalOpen && currTutStep >= steps.length && !isMinimized) {
    dispatch(setCurrentStep(0));
    dispatch(setCurrTutorialNo(DEFAULT_TUTORIAL_NO));
    dispatch(closeModal());
  }

  // Pre tutorial call on tutorial change
  useEffect(() => {
    if (currTutorialNo === DEFAULT_TUTORIAL_NO || currTutorialNo === undefined)
      return;

    setNextStep(0);
    setRestorePage(defaultRestorePage);
    setMinimized(false);
    dispatch(setCurrentStep(0));

    store.dispatch(currTutorial.preTutorialCall());
  }, [currTutorialNo]);

  const currStep = steps?.at(currTutStep) ?? EMPTY_STEP;
  const position = currStep.position;
  const [hasReset, setReset] = useState(false);

  // Step changes
  useEffect(() => {
    dispatch(setRunOnStep(false));
  }, [currTutStep]);

  // On Maximize
  useEffect(() => {
    if (isMinimized) return;
    setNextStep(restorePage);
    setRestorePage(defaultRestorePage);
  }, [isMinimized]);

  // Run onStep
  useEffect(() => {
    if (currStep === undefined || currStep.onStep === undefined || hasRunOnStep)
      return;
    const onStepFunc = currStep.onStep ?? defaultOnStep;
    const dispatchFunc = onStepFunc();
    if (dispatchFunc !== defaultFunction) store.dispatch(dispatchFunc);
    dispatch(setCodeExecutionSatisfied(false));
    dispatch(setRunOnStep(true));
    setNextStep(undefined);
  }, [hasRunOnStep, currStep]);

  // Reset tutorial when modal is closed or tutorial is changed.
  useEffect(() => {
    setReset(true);
    setMinimized(false);
    keyboardMap?.setDisabled(false);
  }, [isModalOpen, currTutorialNo]);

  if (isModalOpen && hasReset) setReset(false);

  // Modify this if other conditions are required to complete the task
  const satisfyFirstCondition = currStep.condition({
    items: items,
    hovering: hovering,
    isPythonTutorOn: isPythonTutorOn,
  });

  const conditionCheck =
    currStep.testCases === undefined || currStep.testCases.length <= 0;

  const canProceed =
    (isCodeExecutionSatisfied || conditionCheck) && satisfyFirstCondition;

  useEffect(() => {
    if (isModalOpen && canProceed && currStep.jumpOnComplete !== undefined)
      setNextStep(currStep.jumpOnComplete);
  }, [isModalOpen, canProceed, currStep]);

  useEffect(() => {
    if (isModalOpen && keyboardMap !== null && keyboardMap !== undefined)
      keyboardMap.setDisabled(canProceed);
  }, [keyboardMap, isModalOpen, canProceed]);

  useEffect(() => {
    if (
      currStep.condition === defaultCondition &&
      currStep.testCases.length <= 0
    )
      return;

    if (isCodeExecutionSatisfied && !satisfyFirstCondition) {
      dispatch(showSnackBarMessage('Condition is not satisfied!'));
      return;
    }

    if (!canProceed) return;

    dispatch(showSnackBarMessage("You've completed this step!"));
  }, [currStep, canProceed, isCodeExecutionSatisfied, satisfyFirstCondition]);

  return (
    <div className="vertical-flex-container">
      <div
        // show only when tutorial is open and minimized
        hidden={!isModalOpen || !isMinimized}
      >
        <Fab
          color="primary"
          data-testid="maximize-btn"
          aria-label="maximize"
          style={{
            margin: 0,
            top: 'auto',
            right: 20,
            bottom: 20,
            left: 'auto',
            position: 'fixed',
          }}
          onClick={() => setMinimized(false)}
        >
          <i className="fa fa-plus" />
        </Fab>
      </div>
      <Tour
        onRequestClose={() => {
          if (!confirm('Are you sure you want to exit this tutorial?')) return;
          dispatch(closeModal());
          dispatch(setCurrTutorialNo(-1));
          dispatch(setCurrentStep(0));
          setNextStep(0);
          setMinimized(false);

          // execute postTutorialCall() after closing if function opens any other modal
          store.dispatch(currTutorial.postTutorialCall());
        }}
        steps={steps}
        getCurrentStep={(currStep) => dispatch(setCurrentStep(currStep))}
        startAt={0}
        goToStep={nextStep}
        isOpen={isModalOpen && !isMinimized}
        disableKeyboardNavigation={canProceed ? [] : ['right']}
        disableDotsNavigation={!canProceed || !currTutorial.tabNavigation}
        disableFocusLock={!canProceed}
        nextButton={canProceed ? undefined : <div />}
        className="tour-css"
        position={position}
      />
    </div>
  );
};
