import BlockAPI from '../BlockAPI';
import {
  code,
  getString,
  getTextInBox,
  markdown,
  THREE_VW_SIZE
} from "../../components/ComponentUtils";
import { Button } from '@material-ui/core';

// define id number
const INPUT_STATEMENT = 62;

// define block regex for parsing
const INPUT_STATEMENT_REGEX = /(.*)\s*=\s*input *\((.*)\) *()()/;
const regexArr = [INPUT_STATEMENT_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'x',
  input2: 'prompt',
};

const lines = [
  `# Assigns user input to variable x`,
  `x = input(prompt)`,
]

// define functional component for helpBox
function InputStatementHelpbox() {
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `To receive input from users, you make use of the **input** function.`,
      )}
      {markdown(`The syntax of the **input** function is as follows:`)}
      {code(lines)}
      {markdown(
        `In this syntax, the *input* function will display the *prompt* to the user and wait for them to enter a value. \n The value entered by the user will be returned by the *input* function.`,
      )}
      {markdown(
        `It will always return a string, even if the user enters a number. \n You can make use of typecasting functions to convert the string to other types.`,
      )}
    </div>
  );
}

// define selection block
const INPUT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code style={{ textAlign: 'left' }}>
      {THREE_VW_SIZE}&nbsp;=&nbsp;input({THREE_VW_SIZE})
    </code>
  </Button>
);

// define input block
const inputInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div}
      {getString('=')}
      {getTextInBox('input')}
      {getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1}=input(${input2})`;
}

export const InputBlock = new BlockAPI(
  INPUT_STATEMENT,
  regexArr,
  PLACEHOLDERS,
  <InputStatementHelpbox />,
  INPUT_SELECTION_BLOCK,
  inputInputBlock,
  parsingFunction,
);
