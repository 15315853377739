/**
 * Returns the statement number of the block based on the block id.
 * @param {Array<ItemBlock>} flatten An array of items to be processed.
 * @param {Number} block_id The id of the block to be found.
 * @returns {Number}
 */
const getStatementNumber = (flatten, block_id) => {
  for (let i = 0; i < flatten.length; i++) {
    if (flatten[i].blockId === block_id) return i;
  }
  return -1;
};

export { getStatementNumber };
