import { removeLine } from '../itemsState';
import { closeMenu } from '../temporaryStates';

/**
 * Deletes the selected line.
 * @returns {Function}
 */
export const onDeleteThunk = () => (dispatch, getState) => {
  const state = getState();
  const { selected } = state.itemStates;
  dispatch(removeLine(selected));
  dispatch(closeMenu());
};
