import { createSlice } from '@reduxjs/toolkit';

export const authInitialState = {
  loggedIn: false,
  displayName: '',
};

export const authSlice = createSlice({
  name: 'Auth State',
  initialState: {
    ...authInitialState,
  },
  reducers: {
    setDisplayName: (state, action) => {
      return {
        ...state,
        displayName: action.payload,
      };
    },
    setLoggedIn: (state, action) => {
      return {
        ...state,
        loggedIn: action.payload,
      };
    },
  },
});

export const { setDisplayName, setLoggedIn } = authSlice.actions;
export default authSlice.reducer;
