import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const FOR_LOOP_ELEMENT = 21;

// define block regex for parsing
const FOR_LOOP_ELEMENT_REGEX = /for (.*) in +(.*) *:()()/;
const regexArr = [ FOR_LOOP_ELEMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'item',
  input2: 'lst',
}

// define functional component for helpBox
function ForLoopElementHelpbox() {
  const lines = [
    `# Program to find the sum of all numbers stored in a list`,
    ``,
    `# List of numbers`,
    `numbers = [6, 5, 3, 8, 4, 2, 5, 4, 11]`,
    ``,
    `# variable to store the sum`,
    'sum = 0',
    ``,
    `# iterate over the list`,
    `for val in numbers:`,
    `    sum = sum+val`,
    ``,
    `print("The sum is", sum) # The sum is 48`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The for loop in Python is used to iterate over a sequence (list, tuple, string) or other iterable objects.
        `,
      )}
      {markdown(`**Example: Python for Loop**`)}
      {code(lines)}
      {markdown(
        `Here, **val** is the variable that takes the value of the item inside the sequence on each iteration. The Loop continues until we reach the last item in the sequence.`,
      )}
    </div>
  );
}

// define selection block
const FOR_LOOP_ELEMENT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      for {ONE_VW_SIZE} in {TWO_VW_SIZE}:
    </code>
  </Button>
);

// define input block
const ForLoopElementInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      <div style={{ display: 'inline-block' }}>
        {getTextInBox('for')}&nbsp;{input1_div}&nbsp;
      </div>
      {getString('in')}&nbsp;
      {input2_div}
      {getString(':')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `for ${input1} in ${input2}:`;
}

export const ForLoopElementBlock = new BlockAPI(FOR_LOOP_ELEMENT,
  regexArr,
  PLACEHOLDERS,
  <ForLoopElementHelpbox />,
  FOR_LOOP_ELEMENT_SELECTION_BLOCK,
  ForLoopElementInputBlock,
  parsingFunction);