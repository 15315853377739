import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { createTestCase } from '../utils/HandleCodeExecution';
import { parseTree } from '../../utils/Parsing';
import { WhileBlock } from '../../blocks/for_and_while_loop/While';
import { ForLoopBlock } from '../../blocks/for_and_while_loop/ForLoop';
import { ForLoopElementBlock } from '../../blocks/for_and_while_loop/ForLoopElement';
import { onClearThunk } from '../../redux/thunks/onClear';
import TutorialRating from '../components/TutorialRating';
import astParser from '../../utils/AstParser';
import { showSnackBarMessage } from '../../redux/messageState';
import {
  closePythonTutor,
  setItems,
  setPythonCodeBoxContentFromItems,
} from '../../redux/itemsState';
import { blockModeThunk } from '../../redux/thunks/BlockMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 10: Loops</h1>
      <h2>At the end of this chapter, you will be able to:</h2>
      <ol>
        <li>Write a while loop</li>
        <li>Write a for loop</li>
        <li>Know when to use loops</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
});

const WhileLoop = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Writing a while loop</h1>
      <h2>A While loop will continue running while a condition is true.</h2>
      {code([
        'x = input("Guess a number I picked")',
        "while (x != '1'):",
        "\tx=input(x + ' is not correct: Enter another guess: ')",
        'print("Congrats, you guessed it")',
      ])}
      <p>
        A while loop is similar to the one above.
        <br />
        This statement will prompt the user to guess a number and will continue
        to do so until the user guesses the correct number.
        <br />
      </p>
      <p>
        With logic that will repeat itself, it is better to make use of loops
        instead of typing each individual section of the loop.
      </p>
    </div>
  ),
});

const WhileLoopQuestion = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Collatz Conjecture</h1>
      <p>Let's try doing with question which makes use of a while loop.</p>
      <p>
        Given a positive integer, <b>print</b> the number of steps it takes for
        the number to reach 1.
        <br />
        <br />
        The inputs will be a positive integer from stdin.
      </p>
      <ol>
        <li>While the number is not 1</li>
        <li>If the number is even, divide it by 2.</li>
        <li>If the number is odd, multiply it by 3 and add 1.</li>
      </ol>
      <p>
        This is actually the Collatz Conjecture, you can find out more{' '}
        <a href="https://en.wikipedia.org/wiki/Collatz_conjecture">here</a>
      </p>
    </div>
  ),
  resetCode: '',
  testCases: [
    createTestCase('1', '0\n'),
    createTestCase('2', '1\n'),
    createTestCase('3', '7\n'),
    createTestCase('4', '2\n'),
    createTestCase('5', '5\n'),
    createTestCase('6', '8\n'),
    createTestCase('7', '16\n'),
    createTestCase('8', '3\n'),
  ],
  position: 'bottom',

  // At least 1 while loop was used.
  condition: ({ items }) => {
    const flattenItems = parseTree({ items: items });
    return (
      flattenItems.filter((item) => item.blockType === WhileBlock.getType())
        .length > 0
    );
  },
  onStep: blockModeThunk,
});

const ForLoopGeneral = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Writing a for loop</h1>
      <p>A for loop will continue running for a certain number of times.</p>
      {code(['for i in range(5):', '\tprint(i)'])}
      <p>
        A for loop is similar to the one above.
        <br />
        This statement will print the numbers 0 to 4.
        <br />
      </p>
      <p>
        With logic that will repeat itself, it is better to make use of loops
        instead of typing each individual section of the loop.
      </p>
      <p>
        For loops are used when we have a specific number of times we want to
        run the loop. Rather than a specific condition.
      </p>
    </div>
  ),
});

const ForLoopArray = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Writing a for loop (continued)</h1>
      <p>In python, for loops can also be used to iterate through arrays</p>
      {code(['for i in [1, 2, 3]:', '\tprint(i)'])}
      <p>The for loop above will print the numbers 1, 2, and 3.</p>
      <p>
        This can be used when there are some elements in an array we have to go
        through to do something.
      </p>
    </div>
  ),
});

// Exported for testing
export const forLoopTemplateCode =
  '#Do not touch this section\nx = input()\n# EG: array = [1, 2, 3, 4]\narray = list(map(int, x.split(",")))\n# Do not touch this section\n';

const ForLoopQuestion = CreateStep({
  selector: CODING_SELECTOR,
  onStep: () => (dispatch, _) => {
    dispatch(closePythonTutor());
    const [isSuccess, items, errorMsg] = astParser(forLoopTemplateCode);
    if (!isSuccess) {
      return dispatch(showSnackBarMessage(`Unexpected Error: ${errorMsg}`));
    }
    dispatch(setItems(items));
    dispatch(setPythonCodeBoxContentFromItems());
  },
  content: (
    <div style={tourDivStyle}>
      <h1>Sum Array</h1>
      <p>Given a list of arrays, add up the sum of each array.</p>
      <ol>
        <li>Print the sum of the array followed by the length of the array</li>
      </ol>
      <p>
        For example, with the input above, you will have to print the output
        below
      </p>
      {code(['15 5'])}
      <p>
        <b>Note: At least 1 for loop must be used.</b>
      </p>
    </div>
  ),
  resetCode: forLoopTemplateCode,
  testCases: [
    createTestCase('1,2,3,4,5', '15 5\n'),
    createTestCase('1,2,3,4,5,6,7,8,9,10', '55 10\n'),
    createTestCase('1,2,3,4,5,6,7,8,9,10,11,12,13,14,15', '120 15\n'),
    createTestCase('1', '1 1\n'),
    createTestCase('1,2', '3 2\n'),
    createTestCase('-1,-2,-3', '-6 3\n'),
  ],
  position: 'bottom',

  // At least 1 for loop was used.
  condition: ({ items }) => {
    const flattenItem = parseTree({ items: items });
    return (
      flattenItem.filter(
        (item) =>
          item.blockType === ForLoopBlock.getType() ||
          item.blockType === ForLoopElementBlock.getType(),
      ).length > 0
    );
  },
});

const Conclusion = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Conclusion</h1>
      <p>Congratulations! You now know about loops.</p>
      <p>
        Loops are a very important part of programming. They allow us to repeat
        a certain section of code without having to type them out again.
      </p>
      <p>Join us in the next chapter to learn more cool stuff about python!</p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  WhileLoop,
  WhileLoopQuestion,
  ForLoopGeneral,
  ForLoopArray,
  ForLoopQuestion,
  Conclusion,
];

export const Chapter10Loops = new Tutorial({
  name: 'Chapter 10: Loops',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});

Chapter10Loops.addSteps(steps);
