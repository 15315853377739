/**
 * Returns true if the text is valid and not empty.
 * @param {String} text The text to be validated.
 * @returns {Boolean}
 */
export const validateFilled = (text) =>
  typeof text === 'string' && text.trim() !== '';

/**
 * Returns true if the email provided is valid.
 * @param {String} email The email string to be validated.
 * @returns {Boolean}
 */
export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
