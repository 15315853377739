import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const LIST_INITIALIZATION = 30;

// define block regex for parsing
const LIST_INITIALIZATION_REGEX = /(.*) *= *\[(.*)\]()()/;
const regexArr = [ LIST_INITIALIZATION_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'lst',
  input2: 'lst_item1, lst_item2',
}

// define functional component for helpBox
function ListInitializationHelpbox() {
  const lines = [
    `lst = [] # create a new empty list`,
    `lst = [1, 4, 3] # create a new list with three integers`,
    ``,
    `# create a new list with elements of different datatypes`,
    `lst = [1, "hello", 4.5, False, None]`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`This operation is used to **create** a new list.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const LIST_INITIALIZATION_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE} = [{HALF_VW_SIZE}]
    </code>
  </Button>
);

// define input block
const listInitializationInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {getString('[')}
      {input2_div}
      {getString(']')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1} = [${input2}]`;
}

export const ListInitBlock = new BlockAPI(LIST_INITIALIZATION,
  regexArr,
  PLACEHOLDERS,
  <ListInitializationHelpbox />,
  LIST_INITIALIZATION_SELECTION_BLOCK,
  listInitializationInputBlock,
  parsingFunction);