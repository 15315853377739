export const tourStyle = {
  maxWidth: '30vw',
  padding: '5px',
};
export const tourDivStyle = {
  maxWidth: '50vw',
  maxHeight: '80vh',
  padding: '5px',
  overflow: 'scroll',
};
