export const CLOSED = 0;
export const CONDITIONALS = 1;
export const IO = 2;
export const LOOPS = 3;
export const ASSIGNMENTS = 4;
export const LIST_OPERATIONS = 5;
export const MAP_AND_FILTER = 6;
export const DICTIONARY_OPERATIONS = 7;
export const OTHERS = 8;
export const FUNCTIONS = 9;
export const CLASS_OPERATIONS = 10;
export const TUPLE_OPERATIONS = 11;
export const SET_OPERATIONS = 12;
export const CASTING_OPERATIONS = 13;
export const EXCEPTION_HANDLING = 14;

export const statements = [
  { name: 'Assignments', type: ASSIGNMENTS },
  { name: 'Typecast', type: CASTING_OPERATIONS },
  { name: 'IO/Print', type: IO },
  { name: 'Conditionals', type: CONDITIONALS },
  { name: 'Functions', type: FUNCTIONS },
  { name: 'Loops', type: LOOPS },
  { name: 'List', type: LIST_OPERATIONS },
  { name: 'Tuples', type: TUPLE_OPERATIONS },
  { name: 'Dictionary', type: DICTIONARY_OPERATIONS },
  { name: 'Errors', type: EXCEPTION_HANDLING },
  { name: 'Others', type: OTHERS },
  { name: 'Sets', type: SET_OPERATIONS },
  { name: 'Classes', type: CLASS_OPERATIONS },
  { name: 'Map & Filter', type: MAP_AND_FILTER },
];

export const ALL_STATES = [
  CLOSED,
  CONDITIONALS,
  LOOPS,
  ASSIGNMENTS,
  LIST_OPERATIONS,
  MAP_AND_FILTER,
  DICTIONARY_OPERATIONS,
  OTHERS,
  FUNCTIONS,
  CLASS_OPERATIONS,
  TUPLE_OPERATIONS,
  SET_OPERATIONS,
  CASTING_OPERATIONS,
  EXCEPTION_HANDLING,
];
