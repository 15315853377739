import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const SET_DIFFERENCE = 45;

// define block regex for parsing
const SET_DIFFERENCE_REGEX = /(.*) *= *(.*) *\. *difference *\((.*)\)()/;;
const regexArr = [ SET_DIFFERENCE_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'new set_name',
  input2: 'set1',
  input3: 'set2'
}

// define functional component for helpBox
function SetDifferenceHelpbox() {
  const lines = [
    `x = {"apple", "banana", "cherry"} # set 1`,
    `y = {"google", "microsoft", "apple"} # set 2`,
    ``,
    `z = x.difference(y)`,
    ``,
    `print(z) # {'banana', 'cherry'}`
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`The **difference()** method returns a set that contains the difference between two sets.`)}
      {markdown(`Meaning: The returned set contains items that exist only in the first set, and not in both sets.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const SET_DIFFERENCE_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {ONE_VW_SIZE} = {TWO_VW_SIZE}.difference({ONE_VW_SIZE})
    </code>
  </Button>
);

// define input block
const SetDifferenceInputBlock = ({ input1_div, input2_div, input3_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {input2_div}
      {getString('.')}difference{getString('(')}
      {input3_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2, input3) {
  return `${input1} = ${input2}.difference(${input3})`;
}

export const SetDifferenceBlock = new BlockAPI(SET_DIFFERENCE,
  regexArr,
  PLACEHOLDERS,
  <SetDifferenceHelpbox />,
  SET_DIFFERENCE_SELECTION_BLOCK,
  SetDifferenceInputBlock,
  parsingFunction);