/**
 * Choose callbacks to use based on whether there is an error or not.
 * @param {Boolean} isError Whether there is an error or not
 * @param {Function} errCallback The call back to be used if there is an error
 * @param {Function} noErrCallback The callback to be used if there is no error.
 * @returns {Boolean} Whether there is an error or not.
 */
export const setEmptyIfError = (isError, errCallback, noErrCallback) => {
  isError ? errCallback() : noErrCallback();
  return isError;
};

/**
 * Sets all the setters to be empty strings.
 * @param  {...any} setters The setters to be called.
 */
export const clearAllSetter = (...setters) => {
  for (const setter of setters) setter('');
};
