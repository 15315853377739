class BlockManager {
  /**
   * A block manager for the blocks in python.
   * @type {Object.<string, Block[]>}
   */
  constructor() {
    this.typeToBlock = {};
    this.IdToBlock = {};
  }

  /**
   * Returns the block api of the block based on the type number.
   * @param {Number} typeID The type of the block
   * @returns {BlockAPI}
   */
  getBlockFromTypeID(typeID) {
    return this.IdToBlock[typeID];
  }

  /**
   * Registers the block type of the type number of the block.
   * @param {BlockAPI} block The block api of the block
   * @param {Number} type The type of the block
   * @returns {void}
   */
  registerTypeToBlock(block, type) {
    this.IdToBlock[block.getType()] = block;
    if (this.isTypeSelectable(type)) {
      this.typeToBlock[type].push(block);
      return;
    }
    this.typeToBlock[type] = [block];
  }

  /**
   * Returns the block api for the block based on the type given.
   * @param {Number} type The type of the block
   * @returns {BlockAPI} the block api for the particular block.
   */
  getBlocksFromType(type) {
    return this.typeToBlock[type];
  }

  /**
   * Checks if the block type is selectable or not.
   * @param {Number} type The type of the block
   * @returns {Boolean} whether the type is selectable or not.
   */
  isTypeSelectable(type) {
    return type in this.typeToBlock;
  }
}

export const blockManager = new BlockManager();
