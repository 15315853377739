import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const FILTER_STATEMENT = 33;

// define block regex for parsing
const FILTER_STATEMENT_REGEX =
  /(.*) *= *list *\( *filter *\( *lambda +(.*): *(.*), *(.*) *\) *\)/;
const regexArr = [ FILTER_STATEMENT_REGEX ];


// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'param',
  input2: 'param == 3',
  input3: 'lst',
  input4: 'filtered_lst',
}

// define functional component for helpBox
function FilterStatementHelpbox() {
  const lines = [
    `lst = [2, 10, 7, 8, 9]`,
    `odd_numbers = list(filter(lambda num: num % 2 == 1, lst))`,
    `print(odd_numbers) # [7, 9]`,
    ``,
    `# we can also save the result to the same input list`,
    `lst = list(filter(lambda x: x % 3 == 0, lst))`,
    `print(lst) # [9]`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The **filter** function takes in a sequence* (e.g. Python list) and returns the elements that satisfy the given condition.`,
      )}
      {code(lines)}
      {markdown(
        `*The formal term for sequence is called "iterable" in Python. lists, tuples, strings, sets, dictionaries are possible iterables, i.e. things that you can loop over.`,
      )}
    </div>
  );
}

// define selection block
const FILTER_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {HALF_VW_SIZE} = list(filter{HALF_VW_SIZE}:{HALF_VW_SIZE},{HALF_VW_SIZE})
    </code>
  </Button>
);

// define input block
const filterInputBlock = ({ input1_div, input2_div, input3_div, input4_div }) => {
  return (
    <div>
      {input4_div} = {getString('list')}
      {getString('(')}
      {getString('filter')}
      {getString('(')}
      {getString('lambda')} {input1_div}: {input2_div}, {input3_div}
      {getString(')')}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2, input3, input4) {
  return `${input4} = list(filter(lambda ${input1}: ${input2}, ${input3}))`;
}

export const FilterBlock = new BlockAPI(FILTER_STATEMENT,
  regexArr,
  PLACEHOLDERS,
  <FilterStatementHelpbox />,
  FILTER_SELECTION_BLOCK,
  filterInputBlock,
  parsingFunction);