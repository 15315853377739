import { showSnackBarMessage } from '../messageState';
import save from '../../utils/FileStorage';

const FilenamePrompt = 'Enter File Name';

/// Exported for testing
export const InvalidFilenameError = 'Invalid File Name';

/**
 * Prompts the user for a file name and downloads the file.
 * @returns {Function}
 */
export const saveFileThunk = () => (dispatch, getState) => {
  const state = getState();
  const name = prompt(FilenamePrompt);
  if (
    name === undefined ||
    name === null ||
    name.length === 0 ||
    name.endsWith('.') ||
    name.includes('/')
  )
    return dispatch(showSnackBarMessage(InvalidFilenameError));
  save(state.itemStates.items, name);
};
