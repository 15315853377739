import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const TUPLE_INITIALIZATION = 40;

// define block regex for parsing
const TUPLE_INITIALIZATION_REGEX = /(.*) *= *\((.*)\)()()/;
const regexArr = [ TUPLE_INITIALIZATION_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'tuple',
  input2: 'item1, item2',
}

// define functional component for helpBox
function TupleInitializationHelpbox() {
  const lines = [
    `thistuple = (1, 2, 3) # create a new list with three integers`,
    ``,
    `thistuple = ("apple",) # create a tuple with one element`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`This operation is used to **create** a new tuple. Tuple items are ordered, unchangeable, and allow duplicate values. To create a tuple with only one item, you have to add a comma after the item, otherwise Python will not recognize it as a tuple.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const TUPLE_INITIALIZATION_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE} = ({HALF_VW_SIZE})
    </code>
  </Button>
);

// define input block
const tupleInitializationInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1} = (${input2})`;
}


export const TupleInitBlock = new BlockAPI(TUPLE_INITIALIZATION,
  regexArr,
  PLACEHOLDERS,
  <TupleInitializationHelpbox />,
  TUPLE_INITIALIZATION_SELECTION_BLOCK,
  tupleInitializationInputBlock,
  parsingFunction);