import { faCode } from '@fortawesome/free-solid-svg-icons';
import { HELPBOX_BLOCK } from '../components/helpbox';
import { DEFAULT_HOVER_VALUE } from '../constants/default-values';

export const defaultIcon = faCode;
export const defaultFunction = () => {};
export const defaultThunk = () => defaultFunction;
export const defaultCondition = () => true;
export const defaultOnStep = () => defaultFunction;
export const defaultHelpbox = HELPBOX_BLOCK(DEFAULT_HOVER_VALUE);

export class Tutorial {
  /**
   * A Tutorial for Visual Python.
   * @param {string} name - The name of the tutorial
   * @param {function} preTutorialCall - A function to call before the tutorial starts
   * @param {array} tutorialSteps - An array of tutorial steps (Using createStep method)
   * @param {object} icon - The icon to display for the tutorial
   * @param {boolean} tabNavigation - Whether or not to allow tab navigation
   * @param {boolean} requireAuth - Whether or not to require authentication
   *
   */
  constructor({
    name = undefined,
    preTutorialCall = defaultThunk,
    postTutorialCall = defaultThunk,
    icon = defaultIcon,
    tabNavigation = false,
    tutorialSteps = [],
    requireAuth = false,
  }) {
    this.tutorialName = name;
    this.icon = icon;
    this.tabNavigation = tabNavigation;
    this.requireAuth = requireAuth;

    this.preTutorialCall = preTutorialCall;
    this.postTutorialCall = postTutorialCall;
    this.tutorialSteps = tutorialSteps;
  }

  /**
   * Gets the name of the tutorial.
   * @returns {string} The name of the tutorial
   */
  getName() {
    return this.tutorialName;
  }

  /**
   * Gets the icon of the tutorial.
   * @returns {object} The icon for the tutorial
   */
  getIcon() {
    return this.icon;
  }

  /**
   * Gets the preTutorialCall of the tutorial.
   * @returns {function} The preTutorialCall for the tutorial
   */
  getPreCall() {
    return this.preTutorialCall;
  }

  /**
   * Gets the steps of the tutorial.
   * @returns {array} The tutorial steps
   */
  getSteps() {
    return this.tutorialSteps;
  }

  /**
   * Gets the helpbox item based on step number
   * @param {int} step - The step for the tutorial
   * @returns {object} Item to display in the helpbox
   */
  getHelpBox(step) {
    const tutStep = this.tutorialSteps[step];
    if (tutStep != undefined && tutStep.helpbox != undefined)
      return tutStep.helpbox;
    return defaultHelpbox;
  }

  /**
   * Adds a step to the tutorial.
   * @param {object} step - The step to add to the tutorial
   */
  addStep(step) {
    this.tutorialSteps.push(step);
  }

  /**
   * Add an array of steps to the tutorial.
   * @param {array} steps - The steps to add to the tutorial
   */
  addSteps(steps) {
    this.tutorialSteps = this.tutorialSteps.concat(steps);
  }

  /**
   * Gets if the tutorial requires authentication.
   * @returns {boolean} Whether or not the tutorial requires authentication
   */
  getRequireAuth() {
    return this.requireAuth;
  }
}

export const EMPTY_TUTORIAL = new Tutorial({
  name: 'EMPTY Tutorial',
});
