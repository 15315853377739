import React from 'react';
import { getBlockFromType } from '../constants/block-types';
import { PYTHON_EDITOR } from '../constants/block-types';
import { markdown } from './ComponentUtils';

/**
 * The Disclaimer component for the PythonEditor.
 * @returns {JSX.Element} - The JSX element for the PythonEditorDisclaimer component.
 */
function PythonEditorDisclaimer() {
  return (
    <div>
      {markdown(
        '**Comments will be ignored on each line after an operation!**',
      )}
    </div>
  );
}

/**
 * The Message on the default helpbox.
 * @returns {JSX.Element} - The JSX element for the Default helpbox.
 */
function Default() {
  return (
    <div>
      {markdown('**Get started with our website!**')}
      {markdown('1. **Click/Drag code blocks** to start building your code!')}
      {markdown('2. **Run your code** and walkthrough its steps!')}
      {markdown('3. Type out your code in our **python editor mode**!')}
      {markdown('4. Please **create an account** to try our tutorials!')}
      {markdown('5. **Visit the guide** under Tutorial  for more help!')}
      {markdown('---')}
      {markdown('**Website Tips**')}
      {markdown('- Click on a block to select it')}
      {markdown('- Check out our Toolbar to help you with editing elements')}
      {markdown(
        '- Examples give you template programs that you can run and learn from!',
      )}
      {markdown('- Pasting elements will be below the selected block')}
    </div>
  );
}

/**
 * Returns the helpbox based on type.
 * @param {Number} type The type of Helpbox.
 * @returns
 */
export const HELPBOX_BLOCK = (type) => {
  if (type == PYTHON_EDITOR) {
    return <PythonEditorDisclaimer />;
  }
  try {
    return getBlockFromType(type).getHelpboxFunction();
  } catch (error) {
    return <Default />;
  }
};
