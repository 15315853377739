import { copyJSON } from './JSON';
import { findPath } from './Path';

/**
 * Indents the selected code block by 1 level.
 *
 * @param {object} items The blockItems from the python block editor.
 * @param {number} selected id of the selected item.
 *
 * @returns {object} the new blockItems with the selected block indented.
 */
export const indentItems = (items, selected) => {
  const path = findPath(items, selected);

  // If there is no previous items or we can't find the path, we cannot indent the item.
  if (path.length === 0 || path.at(-1) == 0) return items;
  const newItems = copyJSON(items);

  // Reach the selected block
  let parent = { children: newItems };
  for (let i = 0; i < path.length - 1; ++i) {
    parent = parent.children[path[i]];
  }

  // Set the current block as the children of the previous block.
  const currIndex = path.at(-1);
  const prevItem = parent.children[currIndex - 1];
  const currItem = parent.children[currIndex];
  prevItem.children.push({ ...currItem, children: [] });
  prevItem.children.push(...currItem.children);

  // Remove the current block
  parent.children.splice(currIndex, 1);
  return newItems;
};

/**
 * Unindents the selected code block by 1 level.
 *
 * @param {object} items The blockItems from the python block editor.
 * @param {number} selected id of the selected item.
 *
 * @returns {object} the new blockItems with the selected block unindented.
 */
export const unindentItems = (items, selected) => {
  const path = findPath(items, selected);

  // If it is top level or we can't find the path, we cannot unindent the item.
  if (path.length <= 1) return items;
  const newItems = copyJSON(items);

  // Reach the selected block
  let grandParent = { children: newItems };
  for (let i = 0; i < path.length - 2; ++i) {
    grandParent = grandParent.children[path[i]];
  }

  const parentIdx = path.at(-2);
  const parent = grandParent.children[parentIdx];
  const currIdx = path.at(-1);
  const currParentChildren = parent.children;
  const currItem = currParentChildren[currIdx];

  // If the current item has children, we return the items as is.
  if (currItem.children.length > 0) return items;

  // Add the selected block after the parent
  grandParent.children.splice(parentIdx + 1, 0, currItem);

  // Keep only parent and children before selected block.
  parent.children = currParentChildren.slice(0, currIdx);
  currItem.children = currParentChildren.slice(currIdx + 1);
  return newItems;
};
