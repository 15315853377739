import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const PASS = 61;

// define block regex for parsing
const PASS_REGEX = /pass()()()()/;
const regexArr = [ PASS_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {

}

// define functional component for helpBox
function PassHelpbox() {
    const lines = [
        `# having an empty for loop like this, would raise an error without the pass statement`,
        `for x in [0, 1, 2]:`,
        `    pass`,
    ];
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(`The **pass** statement is used as a placeholder for future code.
            \nWhen the **pass** statement is executed, nothing happens, but you avoid getting an error when empty code is not allowed.
            \nEmpty code is not allowed in loops, function definitions, class definitions, or in if statements.`)}
            {code(lines)}
        </div>
    );
}
// define selection block
const PASS_SELECTION_BLOCK = (
    <Button
        style={{ textTransform: 'lowercase ' }}
        variant="contained"
        size="small"
    >
        <code style={{ display: 'inline-block' }}>pass</code>
    </Button>
);

// define input block
const passInputBlock = () => {
    return (
        <div>{getTextInBox('pass')}</div>
    )
};

function parsingFunction() {
    return `pass`;
}

export const PassBlock = new BlockAPI(PASS,
    regexArr,
    PLACEHOLDERS,
    <PassHelpbox />,
    PASS_SELECTION_BLOCK,
    passInputBlock,
    parsingFunction);