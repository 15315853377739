import BlockAPI from "../BlockAPI";
import { THREE_VW_SIZE, code, getAssignmentStatements, markdown } from "../../components/ComponentUtils";
import Button from '@material-ui/core/Button';

const ASSIGNMENT = 0;

const ASSIGNMENT_REGEX = /(.*)=(.*)()()/;
const regexArr = [ ASSIGNMENT_REGEX ];

const PLACEHOLDERS = {
  input1: 'x',
  input2: 'x + 1',
};

// from helpBox, makes use of helper functions from BlockAPI
function AssignmentHelpbox() {
  const lines = [
    `# assign the value 1 to variable count`,
    `count = 1`,
    `# assign the value of the variable y to variable count`,
    `count = y`,
    `# assign (the value of x) + 1 to variable x, i.e. increment x by 1`,
    `x = x + 1`,
    `# shorthand version`,
    `x += 1`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The assignment operation **"="** takes the value on the right hand side and assigns it to the variable on the left hand side.`,
      )}
      {code(lines)}
    </div>
  );
}

const ASSIGNMENT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {THREE_VW_SIZE}
      &nbsp;=&nbsp;
      {THREE_VW_SIZE}
    </code>
  </Button>
);

function parsingFunction(input1, input2) {
  return `${input1} = ${input2}`;
}

export const AssignmentBlock = new BlockAPI(ASSIGNMENT, regexArr, PLACEHOLDERS, <AssignmentHelpbox />, ASSIGNMENT_SELECTION_BLOCK, getAssignmentStatements('='), parsingFunction);
