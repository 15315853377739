import BlockAPI from '../BlockAPI';
import {
  THREE_VW_SIZE,
  code,
  getAssignmentStatements,
  markdown,
} from '../../components/ComponentUtils';
import { Button } from '@material-ui/core';

// define id number
const INCREMENT = 10;

// define block regex for parsing
const INCREMENT_REGEX = /(.*)\+=(.*)()()/;
const regexArr = [INCREMENT_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'i',
  input2: '1',
  operator: '+=',
};

// define functional component for helpBox
function IncrementHelpbox() {
  const lines = [
    `a = 10 # initializes variable a to the value 10`,
    `a += 5 # adds 5 to variable a`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The Addition Assignment operator **"+="** adds a value and the variable and assigns the result to that variable`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const INCREMENT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {THREE_VW_SIZE} += {THREE_VW_SIZE}
    </code>
  </Button>
);

function parsingFunction(input1, input2) {
  return `${input1} += ${input2}`;
}

export const IncrementBlock = new BlockAPI(
  INCREMENT,
  regexArr,
  PLACEHOLDERS,
  <IncrementHelpbox />,
  INCREMENT_SELECTION_BLOCK,
  getAssignmentStatements('+='),
  parsingFunction,
);
