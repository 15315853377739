import { blockManager } from '../blocks/BlockManager';
import { AssignmentBlock } from '../blocks/assignment_statements/Assignment';
import { BitwiseAndBlock } from '../blocks/assignment_statements/BitwiseAnd';
import { BitwiseLeftShiftBlock } from '../blocks/assignment_statements/BitwiseLeftShift';
import { BitwiseOrBlock } from '../blocks/assignment_statements/BitwiseOr';
import { BitwiseRightShiftBlock } from '../blocks/assignment_statements/BitwiseRightShift';
import { BitwiseXorBlock } from '../blocks/assignment_statements/BitwiseXor';
import { DecrementBlock } from '../blocks/assignment_statements/Decrement';
import { DivideBlock } from '../blocks/assignment_statements/Divide';
import { DivideFloorBlock } from '../blocks/assignment_statements/DivideFloor';
import { ExponentBlock } from '../blocks/assignment_statements/Exponent';
import { IncrementBlock } from '../blocks/assignment_statements/Increment';
import { ModulusBlock } from '../blocks/assignment_statements/Modulus';
import { MultiplyBlock } from '../blocks/assignment_statements/Multiply';
import { ClassHeaderBlock } from '../blocks/class_operations/ClassHeader';
import { ClassInitBlock } from '../blocks/class_operations/ClassInit';
import { DictAddBlock } from '../blocks/dictionary_operations/DictAdd';
import { DictInitBlock } from '../blocks/dictionary_operations/DictInit';
import { DictPopBlock } from '../blocks/dictionary_operations/DictPop';
import { BreakBlock } from '../blocks/for_and_while_loop/Break';
import { ContinueBlock } from '../blocks/for_and_while_loop/Continue';
import { ForLoopBlock } from '../blocks/for_and_while_loop/ForLoop';
import { ForLoopElementBlock } from '../blocks/for_and_while_loop/ForLoopElement';
import { WhileBlock } from '../blocks/for_and_while_loop/While';
import { FunctionDeclarationBlock } from '../blocks/functions/FunctionDeclaration';
import { ReturnBlock } from '../blocks/functions/Return';
import { ElifBlock } from '../blocks/if_statements/Elif';
import { ElseBlock } from '../blocks/if_statements/Else';
import { IfBlock } from '../blocks/if_statements/If';
import { ListAppendBlock } from '../blocks/list_operations/ListAppend';
import { ListCopyBlock } from '../blocks/list_operations/ListCopy';
import { ListInitBlock } from '../blocks/list_operations/ListInit';
import { ListLengthBlock } from '../blocks/list_operations/ListLength';
import { ListPopBlock } from '../blocks/list_operations/ListPop';
import { FilterBlock } from '../blocks/map_and_filter_operations/Filter';
import { MapBlock } from '../blocks/map_and_filter_operations/Map';
import { CommentBlock } from '../blocks/others/Comment';
import { FreeTextBlock } from '../blocks/others/FreeText';
import { ImportBlock } from '../blocks/others/Import';
import { PrintBlock } from '../blocks/io_types/Print';
import { PrintSameLineBlock } from '../blocks/io_types/PrintSameLine';
import { TupleInitBlock } from '../blocks/tuples/TupleInit';
import { TupleIndexBlock } from '../blocks/tuples/TupleIndex';
import { TupleCountBlock } from '../blocks/tuples/TupleCount';
import { SetAddBlock } from '../blocks/set_operations/SetAdd';
import { SetClearBlock } from '../blocks/set_operations/SetClear';
import { SetDifferenceBlock } from '../blocks/set_operations/SetDifference';
import { SetIntersectionBlock } from '../blocks/set_operations/SetIntersection';
import { SetPopBlock } from '../blocks/set_operations/SetPop';
import { SetRemoveBlock } from '../blocks/set_operations/SetRemove';
import { SetUnionBlock } from '../blocks/set_operations/SetUnion';
import { SetUpdateBlock } from '../blocks/set_operations/SetUpdate';
import { CastFloatBlock } from '../blocks/type_casting/CastFloat';
import { CastIntBlock } from '../blocks/type_casting/CastInt';
import { CastStringBlock } from '../blocks/type_casting/CastString';
import { TryBlock } from '../blocks/Exception_handling/try';
import { ExceptBlock } from '../blocks/Exception_handling/Except';
import { ExceptionElseBlock } from '../blocks/Exception_handling/ExceptionElse';
import { FinallyBlock } from '../blocks/Exception_handling/finally';
import { ListComprehensionBlock } from '../blocks/list_operations/ListComprehension';
import { SetInitBuiltinBlock } from '../blocks/set_operations/SetInitBuiltIn';
import { PassBlock } from '../blocks/others/Pass';
import { LambdaBlock } from '../blocks/functions/Lambda';

import {
  CONDITIONALS,
  LOOPS,
  ASSIGNMENTS,
  LIST_OPERATIONS,
  MAP_AND_FILTER,
  DICTIONARY_OPERATIONS,
  OTHERS,
  FUNCTIONS,
  CLASS_OPERATIONS,
  TUPLE_OPERATIONS,
  SET_OPERATIONS,
  CASTING_OPERATIONS,
  EXCEPTION_HANDLING,
  IO,
} from '../constants/block-fly-out-state';
import { InputBlock } from '../blocks/io_types/input';

const startID = -2;
const lastID = 62;
export const NonBlocksID = 2; //For testing
export const INVALID_BLOCK_ID = -999; // For testing
export const NO_TYPE = -1;
export const PYTHON_EDITOR = -2; // defined for helpbox

blockManager.registerTypeToBlock(IfBlock, CONDITIONALS);
blockManager.registerTypeToBlock(ElifBlock, CONDITIONALS);
blockManager.registerTypeToBlock(ElseBlock, CONDITIONALS);

blockManager.registerTypeToBlock(PrintBlock, IO);
blockManager.registerTypeToBlock(InputBlock, IO);
blockManager.registerTypeToBlock(PrintSameLineBlock, IO);

blockManager.registerTypeToBlock(ForLoopElementBlock, LOOPS);
blockManager.registerTypeToBlock(ForLoopBlock, LOOPS);
blockManager.registerTypeToBlock(WhileBlock, LOOPS);
blockManager.registerTypeToBlock(BreakBlock, LOOPS);
blockManager.registerTypeToBlock(ContinueBlock, LOOPS);

blockManager.registerTypeToBlock(AssignmentBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(IncrementBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(DecrementBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(MultiplyBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(DivideBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(DivideFloorBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(ExponentBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(ModulusBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(BitwiseXorBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(BitwiseAndBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(BitwiseOrBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(BitwiseLeftShiftBlock, ASSIGNMENTS);
blockManager.registerTypeToBlock(BitwiseRightShiftBlock, ASSIGNMENTS);

blockManager.registerTypeToBlock(ListInitBlock, LIST_OPERATIONS);
blockManager.registerTypeToBlock(ListLengthBlock, LIST_OPERATIONS);
blockManager.registerTypeToBlock(ListAppendBlock, LIST_OPERATIONS);
blockManager.registerTypeToBlock(ListCopyBlock, LIST_OPERATIONS);
blockManager.registerTypeToBlock(ListPopBlock, LIST_OPERATIONS);
blockManager.registerTypeToBlock(ListComprehensionBlock, LIST_OPERATIONS);

blockManager.registerTypeToBlock(MapBlock, MAP_AND_FILTER);
blockManager.registerTypeToBlock(FilterBlock, MAP_AND_FILTER);

blockManager.registerTypeToBlock(DictInitBlock, DICTIONARY_OPERATIONS);
blockManager.registerTypeToBlock(DictAddBlock, DICTIONARY_OPERATIONS);
blockManager.registerTypeToBlock(DictPopBlock, DICTIONARY_OPERATIONS);

blockManager.registerTypeToBlock(FunctionDeclarationBlock, FUNCTIONS);
blockManager.registerTypeToBlock(ReturnBlock, FUNCTIONS);
blockManager.registerTypeToBlock(LambdaBlock, FUNCTIONS);

blockManager.registerTypeToBlock(ClassHeaderBlock, CLASS_OPERATIONS);
blockManager.registerTypeToBlock(ClassInitBlock, CLASS_OPERATIONS);

blockManager.registerTypeToBlock(CommentBlock, OTHERS);
blockManager.registerTypeToBlock(ImportBlock, OTHERS);
blockManager.registerTypeToBlock(FreeTextBlock, OTHERS);
blockManager.registerTypeToBlock(PassBlock, OTHERS);

blockManager.registerTypeToBlock(TupleInitBlock, TUPLE_OPERATIONS);
blockManager.registerTypeToBlock(TupleIndexBlock, TUPLE_OPERATIONS);
blockManager.registerTypeToBlock(TupleCountBlock, TUPLE_OPERATIONS);

blockManager.registerTypeToBlock(SetInitBuiltinBlock, SET_OPERATIONS);
blockManager.registerTypeToBlock(SetAddBlock, SET_OPERATIONS);
blockManager.registerTypeToBlock(SetRemoveBlock, SET_OPERATIONS);
blockManager.registerTypeToBlock(SetPopBlock, SET_OPERATIONS);
blockManager.registerTypeToBlock(SetClearBlock, SET_OPERATIONS);
blockManager.registerTypeToBlock(SetIntersectionBlock, SET_OPERATIONS);
blockManager.registerTypeToBlock(SetUnionBlock, SET_OPERATIONS);
blockManager.registerTypeToBlock(SetDifferenceBlock, SET_OPERATIONS);
blockManager.registerTypeToBlock(SetUpdateBlock, SET_OPERATIONS);

blockManager.registerTypeToBlock(CastIntBlock, CASTING_OPERATIONS);
blockManager.registerTypeToBlock(CastStringBlock, CASTING_OPERATIONS);
blockManager.registerTypeToBlock(CastFloatBlock, CASTING_OPERATIONS);

blockManager.registerTypeToBlock(TryBlock, EXCEPTION_HANDLING);
blockManager.registerTypeToBlock(ExceptBlock, EXCEPTION_HANDLING);
blockManager.registerTypeToBlock(ExceptionElseBlock, EXCEPTION_HANDLING);
blockManager.registerTypeToBlock(FinallyBlock, EXCEPTION_HANDLING);

// for testing
export const VALID_BLOCKS = {
  InputBlock: InputBlock,
  AssignmentBlock: AssignmentBlock, //0
  BitwiseAndBlock: BitwiseAndBlock,
  BitwiseLeftShiftBlock: BitwiseLeftShiftBlock,
  BitwiseOrBlock: BitwiseOrBlock,
  BitwiseRightShiftBlock: BitwiseRightShiftBlock,
  BitwiseXorBlock: BitwiseXorBlock,
  DecrementBlock: DecrementBlock,
  DivideBlock: DivideBlock,
  DivideFloorBlock: DivideFloorBlock,
  ExponentBlock: ExponentBlock,
  IncrementBlock: IncrementBlock,
  ModulusBlock: ModulusBlock,
  MultiplyBlock: MultiplyBlock,
  ClassHeaderBlock: ClassHeaderBlock,
  ClassInitBlock: ClassInitBlock,
  DictAddBlock: DictAddBlock,
  DictInitBlock: DictInitBlock,
  DictPopBlock: DictPopBlock,
  BreakBlock: BreakBlock,
  ContinueBlock: ContinueBlock,
  ForLoopBlock: ForLoopBlock,
  ForLoopElementBlock: ForLoopElementBlock,
  WhileBlock: WhileBlock,
  FunctionDeclarationBlock: FunctionDeclarationBlock,
  ReturnBlock: ReturnBlock,
  ElifBlock: ElifBlock,
  ElseBlock: ElseBlock,
  IfBlock: IfBlock,
  ListAppendBlock: ListAppendBlock,
  ListCopyBlock: ListCopyBlock,
  ListInitBlock: ListInitBlock,
  ListLengthBlock: ListLengthBlock,
  ListPopBlock: ListPopBlock,
  FilterBlock: FilterBlock,
  MapBlock: MapBlock,
  CommentBlock: CommentBlock,
  FreeTextBlock: FreeTextBlock,
  ImportBlock: ImportBlock,
  PrintBlock: PrintBlock,
  PrintSameLineBlock: PrintSameLineBlock, //39
  TupleInitBlock: TupleInitBlock,
  TupleIndexBlock: TupleIndexBlock,
  TupleCountBlock: TupleCountBlock,
  SetAddBlock: SetAddBlock,
  SetClearBlock: SetClearBlock,
  SetDifferenceBlock: SetDifferenceBlock,
  SetInitBuiltinBlock,
  SetInitBuiltinBlock,
  SetIntersectionBlock: SetIntersectionBlock,
  SetPopBlock: SetPopBlock,
  SetRemoveBlock: SetRemoveBlock,
  SetUnionBlock: SetUnionBlock,
  SetUpdateBlock: SetUpdateBlock,
  CastFloatBlock: CastFloatBlock,
  CastIntBlock: CastIntBlock,
  CastStringBlock: CastStringBlock,
  TryBlock: TryBlock,
  ExceptBlock: ExceptBlock,
  ExceptionElseBlock: ExceptionElseBlock,
  FinallyBlock: FinallyBlock,
  ListComprehensionBlock: ListComprehensionBlock,
  PassBlock: PassBlock,
  LambdaBlock: LambdaBlock,
};

//for testing
export const ALL_VALID_ID = new Set([]);
for (let i = startID; i < lastID + 1; i++) {
  ALL_VALID_ID.add(i);
}

export const getBlockFromType = (type) => {
  return blockManager.getBlockFromTypeID(type) ?? NO_TYPE;
};

// ordering matters since increment statements (higher priority for matching) can be matched with assignments statements too
export const BLOCK_TYPE_REGEX = new Map();

const priorityRegexArr = [
  // CLASS_INIT before normal FUNCTION_DECLARATION
  ClassHeaderBlock,
  ClassInitBlock,
  FunctionDeclarationBlock,
  ReturnBlock,
  ForLoopBlock,
  ForLoopElementBlock,

  // ELIF_STATEMENT must be before IF_STATEMENT
  // with brackets checked before no brackets
  ElifBlock,
  IfBlock,
  ElseBlock,

  // PRINT_SAME_LINE must be before PRINT_STATEMENT & INCREMENT/ASSIGNMENT
  PrintSameLineBlock,
  PrintBlock,
  WhileBlock,
  WhileBlock,
  MapBlock,
  FilterBlock,
  LambdaBlock, //MAP and FILTER before LAMBDA
  BreakBlock,
  ContinueBlock,
  DictPopBlock, //dict pop regex will encapsulate list pop
  ListAppendBlock,
  ListCopyBlock,
  ListLengthBlock,
  ListComprehensionBlock, // ListComprehension before ListInit
  ListInitBlock,
  DictInitBlock,
  DictAddBlock,

  //TUPLE
  TupleInitBlock,
  TupleCountBlock,
  TupleIndexBlock,

  //SET
  SetInitBuiltinBlock,
  SetAddBlock,
  SetClearBlock,
  SetDifferenceBlock,
  SetIntersectionBlock,
  SetPopBlock,
  SetRemoveBlock,
  SetUnionBlock,
  SetUpdateBlock,

  //type cast
  CastIntBlock,
  CastFloatBlock,
  CastStringBlock,

  //exception handling
  TryBlock,
  ExceptBlock,
  FinallyBlock,

  //IO before assignment
  InputBlock,

  //lowest priority
  //ASSIGNMENT must be before all other assignments
  //EXPONENT must be before MULTIPLY
  //DIVIDE_FLOOR before DIVIDE
  ExponentBlock,
  BitwiseXorBlock,
  BitwiseAndBlock,
  BitwiseOrBlock,
  BitwiseLeftShiftBlock,
  BitwiseRightShiftBlock,
  DivideFloorBlock,
  IncrementBlock,
  DecrementBlock,
  MultiplyBlock,
  DivideBlock,
  ModulusBlock,
  AssignmentBlock,
  ImportBlock,
  PassBlock,
  CommentBlock,
  FreeTextBlock,
];

priorityRegexArr.forEach((block) => {
  const regexArr = block.getRegex();
  for (let i = 0; i < regexArr.length; i++) {
    BLOCK_TYPE_REGEX.set(regexArr[i], block.getType());
  }
});
