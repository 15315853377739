import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const LIST_COMPREHENSION = 59;

// define block regex for parsing
const LIST_COMPREHENSION_REGEX = /(.*) *= *\[(.*?) for (.*?) in (.*)\]/;
const regexArr = [ LIST_COMPREHENSION_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'newList',
  input2: 'expression',
  input3: 'item',
  input4: 'iterable',
}

// define functional component for helpBox
function ListComprehensionHelpbox() {
  const lines = [
    `fruits = ["apple", "banana", "cherry", "kiwi", "mango"]`,
    ``,
    `newlist = [x for x in fruits if "a" in x]`,
    ``,
    `print(newlist) # ['apple', 'banana', 'mango']`,
    ``,
    `newlist = ['hi' for x in fruits] # Set all values in the new list to 'hi'`,
    ``,
    `print(newlist) # ['hi', 'hi', 'hi', 'hi', 'hi']`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`List comprehension offers a shorter syntax when you want to create a new list based on the values of an existing list.`)}
      {markdown(`Example:\nBased on a list of fruits, you want a new list, containing only the fruits with the letter "a" in the name.\nWithout list comprehension you will have to write a for statement with a conditional test inside:`)}
      {markdown(`With list comprehension you can do all that with only one line of code:`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const LIST_COMPREHENSION_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE} = [{ONE_VW_SIZE} for {ONE_VW_SIZE} in {ONE_VW_SIZE}]
    </code>
  </Button>
);

// define input block
const listComprehensionInputBlock = ({ input1_div, input2_div, input3_div, input4_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {getString('[')}
      {input2_div} {getTextInBox('for')} {input3_div} {getString('in')} {input4_div}
      {getString(']')}
    </div>
  );
};

function parsingFunction(input1, input2, input3, input4) {
  return `${input1} = [${input2} for ${input3} in ${input4}]`;
}

export const ListComprehensionBlock = new BlockAPI(LIST_COMPREHENSION,
  regexArr,
  PLACEHOLDERS,
  <ListComprehensionHelpbox />,
  LIST_COMPREHENSION_SELECTION_BLOCK,
  listComprehensionInputBlock,
  parsingFunction);