import { CODING_SELECTOR, NOTHING_SELECTOR } from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { closePythonTutor, setItems } from '../../redux/itemsState';
import { code, markdown } from '../../components/ComponentUtils';
import { astParser } from '../../utils/AstParser';
import { showSnackBarMessage } from '../../redux/messageState';
import { createTestCase } from '../utils/HandleCodeExecution';
import { onClearThunk } from '../../redux/thunks/onClear';
import TutorialRating from '../components/TutorialRating';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Chapter 11: Functions</h1>
      <h2>At the end of this chapter, you will be able to:</h2>
      <ol>
        <li>Know what is a function</li>
        <li>Know why we want functions</li>
        <li>Write a function</li>
        <li>Call a function</li>
        <li>Know when to use functions</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
});

const WhatFunctions = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>What are Functions?</h1>
      <p>Functions are a block of code which runs when it is called.</p>
      <p>There are 3 different components of a function</p>
      {code([
        '# Function Declaration',
        'def function_name(param1, param2):',
        '\treturn return_value',
      ])}
      <ol>
        <li>
          The Function name which names the function shown by{' '}
          {markdown('`function_name`')}
        </li>
        <li>
          The Parameters which indicate the values required by the functions
          shown by {markdown('`param1` and `param2`')}
        </li>
        <li>
          The Return value which shows what is returned by the function this is
          shown by {markdown('`return_value`')}
        </li>
      </ol>
    </div>
  ),
});

/// Exported for testing
export const FTestCode =
  '# Write your code below this line\n# Do not touch the code below.\nin1 = int(input())\nin2 = int(input())\nprint(add(in1,in2))';

const FunctionsTest = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>Let's try creating a function</h1>
      <p>
        Some Skeleton code has been provided. Feel free to add other blocks.
      </p>
      <p>Make a function with the following</p>
      <ol>
        <li>Function Name called `add`</li>
        <li>Takes in 2 parameters</li>
        <li>Returns the sum of the 2 parameters</li>
      </ol>
    </div>
  ),
  onStep: () => (dispatch, getState) => {
    const [isSuccess, items, _] = astParser(FTestCode);
    if (!isSuccess) {
      return dispatch(
        showSnackBarMessage('Error rendering code. Please try again.'),
      );
    }
    dispatch(closePythonTutor());
    dispatch(setItems(items));
  },
  resetCode: FTestCode,
  testCases: [
    createTestCase('1\n1\n', '2\n'),
    createTestCase('2\n2\n', '4\n'),
    createTestCase('3\n3\n', '6\n'),
    createTestCase('4\n5\n', '9\n'),
    createTestCase('5\n-1\n', '4\n'),
  ],
});

const Congrats1 = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Congratulations</h1>
      <p>You have just written your first function.</p>
      <p>
        Writing functions can allow us to create simple things that can add up
        to complicated login, which we will see in the next example
      </p>
    </div>
  ),
});

/// Exported for testing
export const FTest2Code = `def add(a, b):\n    return a+b\n# Do not touch the code below.\nin1 = [int(input()), int(input())]\nin2 = [int(input()), int(input())]\nprint(add(in1,in2))`;

const FunctionTest2 = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div>
      <h1>John needs your help</h1>
      <p>
        John wants to convert his 1D point coordinate code into multidimensional
        code.Originally, this code was designed for 1 dimensional points with 1
        number. IE: `1`, `2`, etc, However, John wants to change it to 2D
        points. Modify the function snippet to help him complete the job
      </p>
      <ol>
        <li>
          Make the add function add 2D points instead (IE: Each number is [0, 2]
          instead of just 0 or 2)
        </li>
        <li>Use square brackets instead of round brackets</li>
      </ol>
    </div>
  ),
  onStep: () => (dispatch, getState) => {
    const [isSuccess, items, _] = astParser(FTest2Code);
    if (!isSuccess) {
      return dispatch(
        showSnackBarMessage('Error rendering code. Please try again.'),
      );
    }
    dispatch(closePythonTutor());
    dispatch(setItems(items));
  },
  testCases: [
    createTestCase('1\n1\n1\n1\n', '[2, 2]\n'),
    createTestCase('2\n2\n2\n2\n', '[4, 4]\n'),
    createTestCase('3\n3\n3\n3\n', '[6, 6]\n'),
    createTestCase('1\n2\n3\n4\n', '[4, 6]\n'),
    createTestCase('-1\n-2\n1\n2\n', '[0, 0]\n'),
  ],
  resetCode: FTest2Code,
});

const WhyFunctions = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Why functions</h1>
      <p>
        Some of you guys might be wondering why we need to learn about
        functions.
      </p>
      <p>
        Functions are a very important part of programming. They allow us to
        reuse code and make our code more readable.
      </p>
      <p>
        They also allow for us to modify our code in one place and have it work
        everywhere else as seen in the example above with John's code.
      </p>
      <p>
        That is all for functions in this tutorial. Hope you learnt more about
        it and see you in the next one.
      </p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  WhatFunctions,
  FunctionsTest,
  Congrats1,
  FunctionTest2,
  WhyFunctions,
];

export const Chapter11Functions = new Tutorial({
  name: 'Chapter 11: Functions',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});

Chapter11Functions.addSteps(steps);
