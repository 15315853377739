import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown, FOUR_SPACES } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const ELIF_STATEMENT = 25;

// define block regex for parsing
const ELIF_STATEMENT_REGEX = /elif *\((.*)\) *:()()()/;
const ELIF_STATEMENT_NO_BRACKET_REGEX = /elif +(.*) *:()()()/;
const regexArr = [ ELIF_STATEMENT_REGEX, ELIF_STATEMENT_NO_BRACKET_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'condition',
}

// define functional component for helpBox
function ElifStatementHelpbox() {
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `If you want to check multiple conditions and perform an action accordingly, you can use the **if...elif...else** statement. The **elif** stands for else if.`,
      )}
      {markdown(
        `The syntax of the **if...elif...else** statement is as follows:`,
      )}
      {code([
        `if condition:`,
        `    if-block`,
        `elif elif-condition1:`,
        `    elif-block1`,
        `...`,
        `else:`,
        `    else-block;`,
      ])}
      {markdown(
        `The *if...elif...else* statement checks each condition (*if-condition*, *elif-condition1*, *elif-condition2*, …) in the order that they appear in the statement until it finds the one that evaluates to *True*.
        \n When the *if...elif...else* statement finds one, it executes the statement that follows the condition and skips testing the remaining conditions.
        \n If no condition evaluates to *True*, the *if...elif...else* statement executes the statement in the *else* branch.
        \n Note that the *else* block is optional. If you omit it and no condition is *True*, the statement does nothing.`,
      )}
    </div>
  );
}

// define selection block
const ELIF_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code style={{ textAlign: 'left' }}>
      elif&nbsp;({THREE_VW_SIZE}):<br></br>
      {FOUR_SPACES + '[Code here]'}
      <br></br>
    </code>
  </Button>
);

// define input block
const elifInputBlock = ({ input1_div }) => {
  return (
    <div>
      {getTextInBox('elif')}&nbsp;{getString('(')}
      {input1_div}
      {getString(')')}
      {getString(':')}
    </div>
  );
};

function parsingFunction(input1) {
  return `elif (${input1}):`;
}


export const ElifBlock = new BlockAPI(ELIF_STATEMENT,
  regexArr,
  PLACEHOLDERS,
  <ElifStatementHelpbox />,
  ELIF_SELECTION_BLOCK,
  elifInputBlock,
  parsingFunction);