import {
  CODING_SELECTOR,
  NOTHING_SELECTOR,
  PYTHON_TUTOR_SELECTOR,
  RUN_CODE_SELECTOR,
  EDIT_CODE_SELECTOR,
} from '../constants';
import { Tutorial } from '../Tutorial';
import { CreateStep } from '../utils/CreateStep';
import { tourDivStyle } from '../styles';
import { code } from '../../components/ComponentUtils';
import { onClearThunk } from '../../redux/thunks/onClear';
import { PrintBlock } from '../../blocks/io_types/Print';
import { createTestCase } from '../utils/HandleCodeExecution';
import TutorialRating from '../components/TutorialRating';
import { blockModeThunk } from '../../redux/thunks/BlockMode';
import { pythonTutorModeThunk } from '../../redux/thunks/PythonTutorMode';

const Introduction = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Chapter 6: Python Data Types</h1>
      <h2>At the end of this chapter, you will learn more about:</h2>
      <ol>
        <li>Different kinds of Data Types in Python</li>
        <li>How to obtain the data type</li>
        <li>How to typecast into different data types</li>
      </ol>
      <p>
        <b>Note:</b> Clicking on examples / tutorials will reset the progress of
        this tutorial.
      </p>
    </div>
  ),
  onStep: blockModeThunk,
});

const IntroToDataTypes = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Built-in Data Types</h2>
      <p>In programming, data type is an important concept.</p>
      <p>
        Variables can store data of different types, and different types can do
        different things.
      </p>
      <p>
        Python has the following data types built-in by default, in these
        categories:
      </p>
      <table>
        <tbody>
          <tr>
            <th>Text Type:</th>
            <td>
              <code>str</code>
            </td>
          </tr>
          <tr>
            <th>Numeric Types:</th>
            <td>
              <code>int</code>, <code>float</code>, <code>complex</code>
            </td>
          </tr>
          <tr>
            <th>Sequence Type:</th>
            <td>
              <code>list</code>, <code>tuple</code>, <code>range</code>
            </td>
          </tr>
          <tr>
            <th>Mapping Type:</th>
            <td>
              <code>dict</code>
            </td>
          </tr>
          <tr>
            <th>Set Type:</th>
            <td>
              <code>set</code>, <code>frozenset</code>
            </td>
          </tr>
          <tr>
            <th>Boolean Type:</th>
            <td>
              <code>bool</code>
            </td>
          </tr>
          <tr>
            <th>Binary Type:</th>
            <td>
              <code>bytes</code>, <code>bytearray</code>,{' '}
              <code>memoryview</code>
            </td>
          </tr>
          <tr>
            <th>None Type:</th>
            <td>
              <code>NoneType</code>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const GettingTheDataType = CreateStep({
  selector: CODING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Getting the Data Type</h2>
      <p>
        You can get the data type of any object by using the <code>type()</code>{' '}
        function:
      </p>
      <p>Let's try to print the data type of the variable x:</p>
      {code(['x = 5\nprint(type(x))'])}
      <p>Steps:</p>
      <ol>
        <li>Hover over the assignments selection menu</li>
        <li>
          Click on the <code>=</code> statement
        </li>
        <li>Hover over the IO selection menu</li>
        <li>Add a print block below the assignment block</li>
        <li>Type in the required fields following the example.</li>
      </ol>
      <p>
        <b>
          Note: You would have to print out the type of x to pass this test
          case.
        </b>
      </p>
    </div>
  ),
  condition: ({ items }) =>
    items.some((item) => {
      const inputField1 = item.input1 ?? '';
      return (
        item.type === PrintBlock.getType() && inputField1.trim() === 'type(x)'
      );
    }),
  onStep: blockModeThunk,
  testCases: [createTestCase('', "<class 'int'>\n")],
  resetCode: '',
});

const RunCode = CreateStep({
  selector: RUN_CODE_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Let's run our code!</h1>
      <p>If we run our code, we can see the outputs of our program.</p>
      <p>Click on the Run Code button in the navigation bar to run the code!</p>
    </div>
  ),
  onStep: blockModeThunk,
  condition: ({ isPythonTutorOn }) => isPythonTutorOn,
  jumpOnComplete: 4,
});

const StepThroughCode = CreateStep({
  selector: PYTHON_TUTOR_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h1>Using Python Tutor</h1>
      <p>
        If we step through our code, we can see that it prints out the type of
        variable x.
      </p>
    </div>
  ),
  onStep: pythonTutorModeThunk,
  position: 'bottom',
});

const GoBackToCode = CreateStep({
  selector: EDIT_CODE_SELECTOR,
  content: (
    <div>
      <h1>Go back to code</h1>
      <p>
        To go back to editing your code, click on the edit code button in the
        navigation bar!
      </p>
    </div>
  ),
  position: 'bottom',
  condition: ({ isPythonTutorOn }) => !isPythonTutorOn,
  onStep: pythonTutorModeThunk,
  jumpOnComplete: 7,
});

const SettingTheDataType = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Setting the Data Type</h2>
      <p>
        In Python, the data type is set when you assign a value to a variable:
      </p>
      <table>
        <tbody>
          <tr>
            <th>Example</th>
            <th>Data Type</th>
          </tr>
          <tr>
            <td>x = "Hello World"</td>
            <td>str</td>
          </tr>
          <tr>
            <td>x = 20</td>
            <td>int</td>
          </tr>
          <tr>
            <td>x = 20.5</td>
            <td>float</td>
          </tr>
          <tr>
            <td>x = ["apple", "banana", "cherry"]</td>
            <td>list</td>
          </tr>
          <tr>
            <td>x = ("apple", "banana", "cherry")</td>
            <td>tuple</td>
          </tr>
          <tr>
            <td>x = range(6)</td>
            <td>range</td>
          </tr>

          <tr>
            <td>x = &#123;"name" &#58; "John", "age" &#58; 36&#125;</td>
            <td>dict</td>
          </tr>
          <tr>
            <td>x = &#123;"apple", "banana", "cherry"&#125;</td>
            <td>set</td>
          </tr>
          <tr>
            <td>x = True</td>
            <td>bool</td>
          </tr>
        </tbody>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const SettingTheSpecificDataType = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div style={tourDivStyle}>
      <h2>Setting the Specific Data Type</h2>
      <p>
        If you want to specify the data type, you can use the following
        constructor functions:
      </p>
      <table>
        <tr>
          <th>Example</th>
          <th>Data Type</th>
        </tr>
        <tr>
          <td>x = str("Hello World")</td>
          <td>str</td>
        </tr>
        <tr>
          <td>x = int(20)</td>
          <td>int</td>
        </tr>
        <tr>
          <td>x = float(20.5)</td>
          <td>float</td>
        </tr>
        <tr>
          <td>x = list(("apple", "banana", "cherry"))</td>
          <td>list</td>
        </tr>
        <tr>
          <td>x = tuple(("apple", "banana", "cherry"))</td>
          <td>tuple</td>
        </tr>
        <tr>
          <td>x = range(6)</td>
          <td>range</td>
        </tr>

        <tr>
          <td>x = dict(name="John", age=36)</td>
          <td>dict</td>
        </tr>
        <tr>
          <td>x = set(("apple", "banana", "cherry"))</td>
          <td>set</td>
        </tr>
        <tr>
          <td>x = bool(5)</td>
          <td>bool</td>
        </tr>
      </table>
    </div>
  ),
  onStep: blockModeThunk,
});

const CongratulationsMessage = CreateStep({
  selector: NOTHING_SELECTOR,
  content: (
    <div>
      <h1>Congratulations!</h1>
      <p>Congratulations! You have completed this tutorial!</p>
      <p>
        Join us in the next tutorial to learn how to do more cool things in
        python!
      </p>
      <div>
        <TutorialRating />
      </div>
    </div>
  ),
});

const steps = [
  Introduction,
  IntroToDataTypes,
  GettingTheDataType,
  RunCode,
  StepThroughCode,
  GoBackToCode,
  SettingTheDataType,
  SettingTheSpecificDataType,
  CongratulationsMessage,
];

export const Chapter6DataTypes = new Tutorial({
  name: 'Chapter 6: Data Types',
  preTutorialCall: onClearThunk,
  requireAuth: true,
});
Chapter6DataTypes.addSteps(steps);
