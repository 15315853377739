import { sendCodeExecution } from '../../api/send';
import { ALL_TEST_CASE_PASS_MESSAGE } from '../constants';

/**
 * Creates a test case object.
 * @param {String} code Code to be executed
 * @param {String} stdin Input for stdin
 * @param {String} expected_output Expected output for the code
 * @param {String} expected_error Expected error for the code
 * @returns {Object}
 */
export const createTestCase = (
  stdin = '',
  expected_output = '',
  expected_error = null,
) => {
  return {
    stdin: stdin,
    expected_output: expected_output,
    expected_error: expected_error,
  };
};

/**
 * Returns true if all test cases passed.
 * If any test case fails, returns false with error message
 * @param {String} code Code to be executed.
 * @param {Array<TestCase>} cases an array of test cases to be tested.
 * @returns {[Boolean, String]} [isSuccess, message]
 */
export const handleCodeExecution = async (code, cases) => {
  for (let i = 0; i < cases.length; ++i) {
    try {
      const [isSuccess, message] = await onSuccessHandler(
        cases[i],
        code,
        i + 1,
      );
      if (!isSuccess) return [isSuccess, message];
    } catch (err) {
      return onErrorHandler(err);
    }
  }

  // All test cases passed.
  return ALL_TEST_CASE_PASS_MESSAGE;
};

const onSuccessHandler = async (curr_case, code, case_no) => {
  const { expected_error, expected_output, stdin } = curr_case;
  const res = await sendCodeExecution(code, stdin);
  const { stdout, stderr } = res.data;

  // Continue if correct
  if (stderr === expected_error && stdout === expected_output)
    return [true, ''];

  // If Error is not the expected error
  if (
    expected_error != null &&
    expected_error.length > 0 &&
    stderr !== expected_error
  )
    return [
      false,
      `Test Case ${case_no}:\nExpected:\n${expected_error}\nbut received:\n${stderr}`,
    ];

  // If Error is not the expected error
  if (stderr && stderr.length > 0)
    return [false, `Test Case ${case_no}:\n${stderr}`];

  // Otherwise it means that the output is not the expected output
  return [
    false,
    `Test Case ${case_no}:\nExpected:\n${expected_output}\nbut received:\n${stdout}`,
  ];
};

const onErrorHandler = (err) => {
  const res = err.response;
  if (res.status === 500) return [false, 'Internal Server Error'];
  return [
    false,
    `Error ${res.status}: ${res.data ?? 'An Unexpected Error Occurred, try again later.'
    }`,
  ];
};
