import { removeBlock } from '../itemsState';
import { closeMenu } from '../temporaryStates';

/**
 * Returns a thunk that will remove the selected block
 * @returns {function} - The thunk
 */
export const onDeleteBlockThunk = () => (dispatch, getState) => {
  const { selected } = getState().itemStates;
  dispatch(removeBlock(selected));
  dispatch(closeMenu());
};
