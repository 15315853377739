export const TAB = 'Tab';
export const ENTER = 'Enter';
export const UP = 'ArrowUp';
export const DOWN = 'ArrowDown';
export const DELETE = 'Delete';
export const BACKSPACE = 'Backspace';

export const A = 'KeyA';
export const B = 'KeyB';
export const C = 'KeyC';
export const F = 'KeyF';
export const G = 'KeyG';
export const H = 'KeyH';
export const I = 'KeyI';
export const O = 'KeyO';
export const P = 'KeyP';
export const S = 'KeyS';
export const T = 'KeyT';
export const V = 'KeyV';
export const Y = 'KeyY';
export const Z = 'KeyZ';

export const InputTagName = 'input';
export const TextAreaTagName = 'textarea';
export const KeysIgnoredInInput = new Set([C, V, Z, Y, DELETE, BACKSPACE]);
export const KeysIgnoredInTextArea = new Set([...KeysIgnoredInInput, TAB]);

/// These are all used for testing

/// A List of all the keys that are used in the application.
export const ALL_USED_CODES = [
  TAB,
  ENTER,
  UP,
  DOWN,
  DELETE,
  BACKSPACE,
  A,
  B,
  C,
  F,
  G,
  H,
  I,
  O,
  P,
  S,
  T,
  V,
  Y,
  Z,
];

/// All possible alphabet keys
export const ALL_ALPHABET_KEYS = 'abcdefghijklmnopqrstuvwxyz'
  .split('')
  .map((c) => `Key${c.toUpperCase()}`);
