import {
  faEdit,
  faCode,
  faPlay,
  faInfoCircle,
  faFile,
  faMagnifyingGlass,
  faUser,
  faDoorOpen,
  faPlus,
  faChalkboardTeacher,
  faUserEdit,
  faInbox,
} from '@fortawesome/free-solid-svg-icons';
import ReactTooltip from 'react-tooltip';
import Navbar from 'reactjs-navbar';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { editorThunk } from '../redux/thunks/EditorMode';
import { allExampleItems } from '../constants/examples';
import {
  closeModal,
  openAbout,
  openFeedback,
  openLogin,
  openRegister,
  openEditProfile,
} from '../redux/modalState';
import { setLoggedIn } from '../redux/authState';
import { openTutorial } from '../redux/modalState';
import { togglePythonTutor, loadExample } from '../redux/itemsState';
import { showSnackBarMessage } from '../redux/messageState';
import { logoutFromServer } from '../api/ServerInterface';
import { sendClicks } from '../api/send';
import { tutorialManager } from '../tutorials/TutorialManager';
import { onSubmitCode } from '../redux/thunks/SubmitThunk';
import { setCurrTutorialNo } from '../redux/tutorialState';
import { Toolbar } from './Toolbar';

/**
 * Creates a menu item object for the navbar.
 * @param {string} title - The name of the menu item.
 * @param {function} onClick - The function to call when the menu item is clicked.
 * @param {array} subItems - An array of subitems to display.
 * @param {object} icon - The icon to display for the menu item.
 * @param {int} id - The id of the menu item.
 * @param {boolean} isAuth - Whether or not the menu requires authentication.
 * @returns {object} A menu item object.
 */
export const createMenuItem = ({
  title,
  onClick = () => { },
  subItems = null,
  icon = null,
  id = null,
  isAuth = true,
}) => {
  return {
    title: title,
    isAuth: isAuth,
    onClick: () => {
      onClick();
      sendClicks(title);
    },
    subItems: subItems,
    icon: icon,
    id: id,
  };
};

/**
 * Creates a subitem for tutorial menu items.
 * @param {string} name - The name of the tutorial.
 * @param {function} onClick - The function to call when the tutorial is clicked.
 * @param {object} icon - The icon to display for the tutorial.
 * @param {boolean} auth - Whether or not the tutorial can be viewed.
 * @returns {object} A tutorial item.
 */
export const createTutorialItem = (
  name,
  onClick,
  icon = faCode,
  auth = true,
) => {
  return {
    title: name,
    isAuth: auth,
    icon: icon,
    onClick: () => {
      onClick();
      sendClicks(`Tutorial: ${name}`);
    },
  };
};

/**
 *
 * @param {string} name - The name of the example.
 * @param {function} onClick - The function to call when the example is clicked.
 * @param {object} icon - The icon to display for the example.
 * @param {boolean} isAuth - Whether or not the user can view the example.
 * @returns {object} A example item.
 */
export const createExampleItem = (
  name,
  onClick,
  icon = faCode,
  isAuth = true,
) => {
  return {
    title: name,
    isAuth: isAuth,
    icon: icon,
    onClick: onClick,
  };
};

const style = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
};

/**
 * The navbar component.
 * @param {function} onSubmit - The function to call when the user submits their code.
 * @param {function} onSaveFile - The function to call when the user saves a file.
 * @param {function} onImport - The function to call when the user imports a file.
 * @param {function} onClipBoard - The function to call when the user copies the code to the clipboard.
 * @returns {object} A navbar component.
 */
export const BlockPythonNavbar = () => {
  const store = useStore();
  const isPythonTutor = useSelector(
    (state) => state.itemStates.isPythonTutorOn,
  );
  const isLoggedIn = useSelector((state) => state.authState.loggedIn);
  const showPythonInterface = useSelector(
    (state) => state.itemStates.showPythonInterface,
  );
  const dispatch = useDispatch();

  const aboutTab = createMenuItem({
    title: 'About',
    onClick: () => dispatch(openAbout()),
    icon: faMagnifyingGlass,
  });

  const emailFeedbackTab = createMenuItem({
    title: 'Write in to us!',
    onClick: () => dispatch(openFeedback()),
    icon: faInbox,
  });

  const surveyFormTab = createMenuItem({
    title: 'Submit Feedback',
    onClick: () => window.open('https://forms.gle/Y8DkuoX9grcodi7i7'),
    icon: faFile,
  });

  const loginTab = createMenuItem({
    title: 'Login',
    onClick: () => dispatch(openLogin()),
    icon: faUser,
    isAuth: !isLoggedIn,
  });

  const registerTab = createMenuItem({
    title: 'Register',
    onClick: () => dispatch(openRegister()),
    icon: faPlus,
    isAuth: !isLoggedIn,
  });

  const logoutTab = createMenuItem({
    title: 'Logout',
    onClick: () => {
      dispatch(setLoggedIn(false));
      logoutFromServer()
        .then((response) => {
          if (response.status === 200)
            dispatch(showSnackBarMessage('Logged out!'));
        })
        .catch(() => {
          dispatch(showSnackBarMessage('Session Error. Please login again!'));
        });
    },
    icon: faDoorOpen,
    isAuth: isLoggedIn,
  });

  const editTab = createMenuItem({
    title: 'Edit Profile',
    onClick: () => dispatch(openEditProfile()),
    icon: faUserEdit,
    isAuth: isLoggedIn,
  });

  const examplesTab = createMenuItem({
    title: 'Examples',
    subItems: allExampleItems.map((example, index) => {
      return createExampleItem(example.getName(), () => {
        dispatch(loadExample(index));
        if (isPythonTutor) dispatch(togglePythonTutor());
        dispatch(closeModal());
        sendClicks(`Example: ${example.getName()}`);
      });
    }),
    icon: faCode,
  });

  const tutorialItems = tutorialManager
    .getAllTutorials()
    .map((tutorial, index) =>
      createTutorialItem(
        tutorial.getName(),
        () => {
          dispatch(setCurrTutorialNo(index));
          dispatch(openTutorial());
          if (showPythonInterface) store.dispatch(editorThunk());
        },
        tutorial.getIcon(),
        tutorial.getRequireAuth() ? isLoggedIn : true,
      ),
    );

  if (!isLoggedIn) {
    tutorialItems.push(
      createTutorialItem(
        'Login to learn about Syntax, Variables, Conditionals, Functions and more! (14 chapters available)',
        () => dispatch(openLogin()),
        faUser,
      ),
    );
  }

  const tutorialTab = createMenuItem({
    title: 'Tutorial',
    subItems: tutorialItems,
    icon: faChalkboardTeacher,
  });

  const infoTab = createMenuItem({
    title: 'Info',
    subItems: [aboutTab, surveyFormTab, emailFeedbackTab],
    icon: faInfoCircle,
  });
  const authTab = createMenuItem({
    title: isLoggedIn ? 'Profile' : 'Account',
    subItems: [loginTab, registerTab, editTab, logoutTab],
    icon: faUser,
  });

  const runCodeTab = createMenuItem({
    title: isPythonTutor ? 'Edit code' : 'Run code',
    onClick: () => store.dispatch(onSubmitCode()),
    icon: isPythonTutor ? faEdit : faPlay,
  });

  return (
    <div style={style}>
      <div data-tut="IDEUtils">
        <Navbar
          menuItems={[runCodeTab, examplesTab, tutorialTab, authTab, infoTab]}
        />
        <Toolbar />
        <ReactTooltip
          backgroundColor={'00BBFF'}
          textColor={'white'}
          delayShow={300}
        />
      </div>
    </div>
  );
};
