import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import ReactMarkdown from 'react-markdown';

/**
 * Highlights the code in the given lines.
 * @param {Array<String>} lines - the lines of code to be highlighted
 * @returns {JSX.Element} - the highlighted code
 */

export const code = (lines) => {
  return (
    <div>
      <SyntaxHighlighter language="python">
        {lines.join('\n')}
      </SyntaxHighlighter>
    </div>
  );
};
/**
 * Converts the text from markdown to HTML.
 * @param {String} text
 * @returns {JSX.Element} - The parsed markdown in html.
 */

export const markdown = (text) => {
  return (
    <div style={{ paddingLeft: '1em', color: 'black', fontWeight: 'thin' }}>
      <ReactMarkdown>{text}</ReactMarkdown>
    </div>
  );
};
const stringMapping = {
  '=': 'equal',
  '+=': 'equal',
  '(': 'bracket',
  ')': 'bracket',
  '[': 'bracket',
  ']': 'bracket',
  ':': 'colon',
  ',': 'comma',
  '.': 'dot',
  '{': 'curly',
  '}': 'curly',
  "'": 'apostrophe',
};
const fontSize = '12px';
const fontFamily = 'Courier New';
export const FOUR_SPACES = String.fromCharCode(160).repeat(4);
export const OPEN_CURLY = '{';
export const CLOSE_CURLY = '}';
export const THREE_VW_SIZE = (
  <input
    style={{
      width: '3vw',
      height: fontSize,
      fontFamily: fontFamily,
      fontSize: fontSize,
    }}
    disabled={true}
  />
);

export const ONE_VW_SIZE = (
  <input
    disabled={true}
    style={{
      width: '1vw',
      height: fontSize,
      fontFamily: fontFamily,
      fontSize: fontSize,
    }}
  />
);

export const HALF_VW_SIZE = (
  <input
    disabled={true}
    style={{
      width: '0.5vw',
      height: fontSize,
      fontFamily: fontFamily,
      fontSize: fontSize,
    }}
  />
);

export const TWO_VW_SIZE = (
  <input
    disabled={true}
    style={{
      width: '2vw',
      height: fontSize,
      fontFamily: fontFamily,
      fontSize: fontSize,
    }}
  />
);
const mapping = { '=': 'equal', '#': 'comment' };
/**
 * Wraps the text within an input box.
 * @param {String} text The text to be wrapped in a box.
 * @returns {JSX.Element} The text wrapped in a box.
 */
export const getTextInBox = (text) => {
  const inputClassName = mapping.hasOwnProperty(text) ? mapping[text] : text;
  return (
    <div
      style={{
        display: 'inline-block',
        width: '1vw !important',
        userSelect: 'none',
      }}
    >
      <input
        role="contentinfo"
        className={`noselect ${inputClassName}`}
        size={text.length - 1}
        onFocus={(e) => e.target.blur()}
        readOnly={true}
        value={text}
        style={{
          fontFamily: 'monospace',
          fontSize: '14px',
          width: `${text.length}ch`,
          margin: '0px',
          padding: '0px',
          userSelect: 'none',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'default',
          caretColor: 'transparent',
        }}
      />
    </div>
  );
};
/**
 * Wraps a String in a box.
 * @param {String} text The string to be wrapped
 * @returns {JSX.Element} The string wrapped in a div with the appropriate class name.
 */

export const getString = (text) => {
  const className = text in stringMapping ? stringMapping[text] : text;
  return (
    <div
      style={{
        display: 'inline-block',
        width: '1vw !important',
        userSelect: 'none',
      }}
      className={`${className} noselect`}
    >
      {text}
    </div>
  );
};
/**
 * Gets a function that generates a JSX element for an assignment statement.
 * @param {String} symbol The operator for the assignment.
 * @returns {Function} A function that generates the JSX for the assignment statement.
 */

export const getAssignmentStatements =
  (symbol) =>
  ({ input1_div, input2_div }) =>
    (
      <div>
        {input1_div}
        &nbsp;
        {getString(symbol)}
        &nbsp;
        {input2_div}
      </div>
    );
