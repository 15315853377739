import BlockAPI from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown, FOUR_SPACES } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const ELSE_STATEMENT = 26;

// define block regex for parsing
const ELSE_STATEMENT_REGEX = /else *:()()()()/;
const regexArr = [ ELSE_STATEMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {

}

// define functional component for helpBox
function ElseStatementHelpbox() {
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Typically, you want to perform an action when a condition is True and another action when the condition is False.
      \n To do so, you use the **if...else** statement.`,
      )}
      {markdown(`The syntax of the **if...else** statement is as follows:`)}
      {code([`if condition:`, `    if-block`, `else:`, `    else-block;`])}
      {markdown(
        `In this syntax, the *if...else* will execute the *if-block* if the condition evaluates to *True*. \n Otherwise, it’ll execute the *else-block*.`,
      )}
    </div>
  );
}

// define selection block
const ELSE_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code style={{ textAlign: 'left' }}>
      else:<br></br>
      {FOUR_SPACES + '[Code here]'}
      <br></br>
    </code>
  </Button>
);


// define input block
const elseInputBlock = () => {
  return (
    <div>
      <div style={{ display: 'inline-block' }}>{getTextInBox('else')}</div>
      {getString(':')}
    </div>
  );
};

function parsingFunction() {
  return `else:`;
}

export const ElseBlock = new BlockAPI(ELSE_STATEMENT,
  regexArr,
  PLACEHOLDERS,
  <ElseStatementHelpbox />,
  ELSE_SELECTION_BLOCK,
  elseInputBlock,
  parsingFunction);