import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const SET_UPDATE = 51;

// define block regex for parsing
const SET_UPDATE_REGEX = /(.*)\. *update *\((.*)\)()()/;;
const regexArr = [ SET_UPDATE_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'set1',
  input2: 'set2',
}

// define functional component for helpBox
function SetUpdateHelpbox() {
  const lines = [
    `x = {"apple", "banana", "cherry"}`,
    `y = {"google", "microsoft", "apple"}`,
    ``,
    `x.update(y)`,
    ``,
    `print(x) # {'google', 'microsoft', 'apple', 'cherry', 'banana'}`
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`The **update()** method updates the current set, by adding items from another set (or any other iterable).`)}
      {markdown(`If an item is present in both sets, only one appearance of this item will be present in the updated set.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const SET_UPDATE_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE}.update({TWO_VW_SIZE})
    </code>
  </Button>
);

// define input block
const SetUpdateInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div}.update{getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1}.update(${input2})`;
}

export const SetUpdateBlock = new BlockAPI(SET_UPDATE,
  regexArr,
  PLACEHOLDERS,
  <SetUpdateHelpbox />,
  SET_UPDATE_SELECTION_BLOCK,
  SetUpdateInputBlock,
  parsingFunction);