import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const SET_REMOVE = 49;

// define block regex for parsing
const SET_REMOVE_REGEX = /(.*)\. *remove *\((.*)\)()()/;;
const regexArr = [ SET_REMOVE_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'set_name',
  input2: 'item_to_remove',
}

// define functional component for helpBox
function SetRemoveHelpbox() {
  const lines = [
    `fruits = {"apple", "banana", "cherry"} # create a new set`,
    ``,
    `fruits.remove("banana") # remove a specified element`,
    ``,
    `print(fruits) # {'apple', 'cherry'}`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(`The **remove()** method removes the specified element from the set.`)}
      {code(lines)}
    </div>
  );
}

// define selection block
const SET_REMOVE_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE}.remove({TWO_VW_SIZE})
    </code>
  </Button>
);

// define input block
const SetRemoveInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div}.remove{getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1}.remove(${input2})`;
}

export const SetRemoveBlock = new BlockAPI(SET_REMOVE,
  regexArr,
  PLACEHOLDERS,
  <SetRemoveHelpbox />,
  SET_REMOVE_SELECTION_BLOCK,
  SetRemoveInputBlock,
  parsingFunction);