import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const CAST_FLOAT = 52;

// define block regex for parsing
const CAST_FLOAT_REGEX = /(.*) *= *float *\((.*)\) *()()/;
const regexArr = [CAST_FLOAT_REGEX];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'variable',
  input2: 'input',
}

// define functional component for helpBox
function CastFloatHelpbox() {
  const lines = [
    `x = float(1)     # x will be 1.0`,
    `y = float(2.8)   # y will be 2.8`,
    `z = float("3")   # z will be 3.0`,
    `w = float("4.2") # w will be 4.2`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `constructs a float number from an integer literal, a float literal or a string literal (providing the string represents a float or an integer)`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const CAST_FLOAT_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE} = float({TWO_VW_SIZE})
    </code>
  </Button>
);

// define input block
const CastFloatInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {getString('float')}
      {getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1} = float(${input2})`;
}


export const CastFloatBlock = new BlockAPI(CAST_FLOAT,
  regexArr,
  PLACEHOLDERS,
  <CastFloatHelpbox />,
  CAST_FLOAT_SELECTION_BLOCK,
  CastFloatInputBlock,
  parsingFunction);