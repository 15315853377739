import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const SET_INIT_BUILTIN = 60;

// define block regex for parsing
const SET_INIT_BUILTIN_REGEX = /(.*) *= *set *\((.*)\)()()/;
const regexArr = [ SET_INIT_BUILTIN_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'variable',
  input2: 'collection',
}

// define functional component for helpBox
function SetInitBuiltinHelpbox() {
  const lines = [
    `thisset = set() # create an empty set`,
    `print(thisset) # {}`,
    ``,
    `thisset = set(("apple", "banana", "cherry")) # note the double round-brackets`,
    `print(thisset) # {'apple', 'banana', 'cherry'}`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `Th **set()** constructor is used to create a new Set. It takes in a collection as an optional argument. Sets are used to store multiple items in a single variable. Set items are unordered, unchangeable, and **do not** allow duplicate values.`,
      )}
      {code(lines)}
    </div>
  );
}

// define selection block
const SET_INIT_BUILTIN_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {TWO_VW_SIZE} = set({TWO_VW_SIZE})
    </code>
  </Button>
);

// define input block
const SetInitBuiltinInputBlock = ({ input1_div, input2_div }) => {
  return (
    <div>
      {input1_div} {getString('=')} {getString('set')}
      {getString('(')}
      {input2_div}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2) {
  return `${input1} = set(${input2})`;
}

export const SetInitBuiltinBlock = new BlockAPI(SET_INIT_BUILTIN,
  regexArr,
  PLACEHOLDERS,
  <SetInitBuiltinHelpbox />,
  SET_INIT_BUILTIN_SELECTION_BLOCK,
  SetInitBuiltinInputBlock,
  parsingFunction);