import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const FREE_TEXT = 36;

// define block regex for parsing
const FREE_TEXT_REGEX = /(.*)()()()/;
const regexArr = [ FREE_TEXT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
    input1: 'print("I can type what I want inside")',
}

// define functional component for helpBox
function FreeTextHelpbox() {
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(
                `This coding block can be anything. Type your own code statement here!`,
            )}
        </div>
    );
}

// define selection block
const FREE_TEXT_SELECTION_BLOCK = (
    <Button
        style={{ textTransform: 'lowercase ' }}
        variant="contained"
        size="small"
    >
        <code style={{ textTransform: 'capitalize', display: 'inline-block' }}>
            T
        </code>
        <code style={{ display: 'inline-block' }}>ype your own code </code>
    </Button>
);

// define input block
const freeTextInputBlock = ({ input1_div }) => <div>{input1_div}</div>

function parsingFunction(input1) {
    return `${input1}`;
}

export const FreeTextBlock = new BlockAPI(FREE_TEXT,
    regexArr,
    PLACEHOLDERS,
    <FreeTextHelpbox />,
    FREE_TEXT_SELECTION_BLOCK,
    freeTextInputBlock,
    parsingFunction);