import BlockAPI from "../BlockAPI";
import { md } from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const MAP_STATEMENT = 34;

// define block regex for parsing
const MAP_STATEMENT_REGEX =
  /(.*) *= *list *\( *map *\( *lambda +(.*): *(.*), *(.*) *\) *\)/;
const regexArr = [ MAP_STATEMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
  input1: 'param',
  input2: 'param ** 2',
  input3: 'lst',
  input4: 'mapped_lst',
}

// define functional component for helpBox
function MapStatementHelpbox() {
  const lines = [
    `lst = [1, 4, 3]`,
    `squared = list(map(lambda num: num**2, lst))`,
    `# remember to use list() to convert the map object to a list`,
    `print(squared) # [1, 16, 9]`,
    ``,
    `# we can also save the result to the same input list`,
    `lst = list(map(lambda num: num + 1, lst))`,
    `print(lst) # [2, 5, 4]`,
  ];
  return (
    <div style={{ maxWidth: '100%' }}>
      {markdown(
        `The **map** function takes in a sequence* (e.g. Python list) and applies the specified function to each element in the sequence.`,
      )}
      {code(lines)}
      {markdown(
        `*The formal term for sequence is called "iterable" in Python. lists, tuples, strings, sets, dictionaries are possible iterables, i.e. things that you can loop over.`,
      )}
    </div>
  );
}

// define selection block
const MAP_SELECTION_BLOCK = (
  <Button
    style={{ textTransform: 'lowercase' }}
    variant="contained"
    size="small"
  >
    <code>
      {HALF_VW_SIZE} = list(map {HALF_VW_SIZE}: {HALF_VW_SIZE},{HALF_VW_SIZE})
    </code>
  </Button>
);

// define input block
const mapInputBlock = ({ input1_div, input2_div, input3_div, input4_div }) => {
  return (
    <div>
      {input4_div} = {getString('list')}
      {getString('(')}
      {getString('map')}
      {getString('(')}
      {getString('lambda')} {input1_div}: {input2_div}, {input3_div}
      {getString(')')}
      {getString(')')}
    </div>
  );
};

function parsingFunction(input1, input2, input3, input4) {
  return `${input4} = list(map(lambda ${input1}: ${input2}, ${input3}))`;
}

export const MapBlock = new BlockAPI(MAP_STATEMENT,
  regexArr,
  PLACEHOLDERS,
  <MapStatementHelpbox />,
  MAP_SELECTION_BLOCK,
  mapInputBlock,
  parsingFunction);