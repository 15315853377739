import BlockAPI from "../BlockAPI";
import { HALF_VW_SIZE, ONE_VW_SIZE, THREE_VW_SIZE, TWO_VW_SIZE, code, getAssignmentStatements, getString, getTextInBox, markdown, FOUR_SPACES } from "../../components/ComponentUtils";
import { Button } from "@material-ui/core";

// define id number
const EXCEPT_STATEMENT = 56;

// define block regex for parsing
const EXCEPT_STATEMENT_REGEX = /except *(.*) *:()()()/;
const regexArr = [ EXCEPT_STATEMENT_REGEX ];

// define placeholders when adding blocks
const PLACEHOLDERS = {
    input1: 'exception',
  }

// define functional component for helpBox
function ExceptStatementHelpbox() {
    return (
        <div style={{ maxWidth: '100%' }}>
            {markdown(
                `You can define as many exception blocks as you want, e.g. if you want to execute a special block of code for a special kind of error.
      \n Print one message if the try block raises a NameError and another for other errors:`,
            )}
            {code([`try:`,
                `    print(x) # generate a NameError`,
                `except NameError:`,
                `    print("Variable x is not defined") # prints output`,
                `except:`,
                `    print("Something else went wrong")`,
                ])}
        </div>
    );
}

// define selection block
const EXCEPT_SELECTION_BLOCK = (
    <Button
        style={{ textTransform: 'lowercase' }}
        variant="contained"
        size="small"
    >
        <code>
            except&nbsp;
            {THREE_VW_SIZE}:<br></br>
            {FOUR_SPACES + '[Code here]'}
        </code>
    </Button>
);


// define input block
const exceptInputBlock = ({ input1_div }) => {
    return (
        <div>
            {getTextInBox('except')}&nbsp;
            {input1_div}
            {getString(':')}
        </div>
    );
};

function parsingFunction(input1) {
    return `except ${input1}:`;
}

export const ExceptBlock = new BlockAPI(EXCEPT_STATEMENT,
    regexArr,
    PLACEHOLDERS,
    <ExceptStatementHelpbox />,
    EXCEPT_SELECTION_BLOCK,
    exceptInputBlock,
    parsingFunction);