const PYTHON_TUTOR_URL = `https://pythontutor.com/iframe-embed.html#code={replace_code}&codeDivHeight=400&codeDivWidth=350&cumulative=false&curInstr=0&heapPrimitives=nevernest&origin=opt-frontend.js&py=3&rawInputLstJSON=%5B%5D&textReferences=false`;

const style = {
  border: 'none',
};

/**
 * Returns a Python Tutor div with the code rendered in it.
 * @param {String} code - The code to be displayed in Python Tutor
 * @returns {iFrame} - The Python Tutor div
 */
export const PythonTutorDiv = ({ code }) => {
  const encodedCode = encodeURIComponent(code);
  const url = PYTHON_TUTOR_URL.replace('{replace_code}', encodedCode);
  return (
    <iframe src={url} style={style} title="Python Tutor" id="pythonTutor" />
  );
};
